@import '../../../style/styles.scss';

.ChooseRegistrationType {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: $gutter * 4;

    .TermsText {
        text-align: center;
        font-size: 14px;
        padding: 10px 30px 0px 30px;
        font-style: italic;
    }

    .ButtonsWrapper {
        margin-top: $gutter * 2;
        max-width: 320px;
        button {
            &:last-child {
                width: 100%;
                max-width: 311px;
                max-height: 80px;
                color: #2F2F37;
                border: 2px solid #dee7ec !important;
            }
            span {
                @media (max-width: $tablet) {
                    white-space: nowrap;
                    padding: 0 16px;
                }
            }
            &:first-child {
                @media (min-width: $desktop) {
                    padding: 24px 0 44px 0;
                    border: 2px solid var(--color-mystic);
                    border-radius: 16px;
                    cursor: pointer;
                }
            }
            span {
                padding: 12px 36px 12px 26px !important;

                @media (min-width: $desktop) {
                    white-space: nowrap;
                }
            }
            &:first-child {
                @media (min-width: $desktop) {
                    // padding: 14px 10px 14px 0 !important;
                }
                border: 2px solid #db4437;

                &:focus-visible {
                    border-color: transparent;
                }
            }
        }
    }

    button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 70px !important;
        margin: 10px 0;
        padding: 28px !important;
        cursor: pointer;
        border-radius: 16px !important;
        color: #2F2F37 !important;
    }

    .ButtonLink {
        cursor: pointer;
        border: none;
        background-color: transparent;
        color: $primaryColor;
        text-decoration: underline;
        margin: 0;
        padding: 0;
        font-size: inherit;
    }

    .Divider {
        display: flex;
        width: 100%;
        margin-top: 22px;
        margin-bottom: 14px;
        @media (min-width: 376px) {
            max-width: 311px;
        }

        hr {
            width: 100%;
            color: #dee7ec;
            height: 1px;
            background: #dee7ec;
            border: 0px;
        }

        .DividerText {
            margin-right: 8px;
            margin-left: 8px;
            color: #717179;
            font-size: 14px;
        }
    }
}

@media (max-width: $tablet) {
    .Steps {
        display: none;
    }

    .ChooseRegistrationType {
        margin-top: 10px;
    }

    .ButtonsWrapper {
        min-width: 100%;
        padding: 32px 36px 100% 32px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 32px 32px 0 0;
        background-color: $white;
        button span {
            padding: 0 !important;
        }
    }
}
