@import '../../../style/styles.scss';

.CreateAccountConfirmation {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .Message {
        margin-top: 124px;

        @media (min-width: $tablet) {
            margin-top: 186px;
        }

        h1 {
            font-size: 2rem;
            line-height: 2rem;
            font-weight: 600;
        }

        p {
            margin: 16px 8px;
            line-height: 1.5rem;
            text-align: center;
        }
    }

    .Actions {
        margin-top: 40px;
    }

    .Message,
    .Actions {
        width: 100%;
        text-align: center;
    }
}

