@import '../../../style/styles.scss';

.CompanySignupForm {
    --color-form-label: #1b1c1d;
    --color-form-input-bg: #ffffff;
    --color-form-input-border: #dee7ec;
    --color-form-input-border-error: #f48e75;
    --color-form-input-border-active: #2b4a64;
    --color-form-hint: #2F2F37;
    --color-form-error: #c43f17;
    --color-link: #2b4a64;
    --color-terms: #717179;
    --radius-form-input: 8px;
}

.Circle {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    padding: 3px 11px 4px 11px;
    background: var(--color-black20);
    color: white;      
    text-align: center;
    justify-content: center;
    font-size: 20px;
}

.CompanySignupForm {    
    margin-bottom: 24px;    

    .LeftContainer{
        display: flex;

        @media (max-width: 1047px) {
            flex-direction: column;
            width: 100%;            

            .HeaderContainerMobile{
                display: flex;

                h2{
                    font-size: 20px;
                    line-height: 30px;
                    margin-left: 24px;
                }
            }
        }
    }

    .CollapsedContainer{
        border: 2px solid var(--color-brown85);
        border-radius: 16px;
        padding: 44px 56px 40px 32px;      
        display: flex;
        justify-content: space-between;
        
        @media (max-width: 1047px) {
            margin-left: 16px;
            margin-right: 16px;
            padding: 32px 24px 24px 24px;

            .LeftLeftContainer{            
                display: flex;
                h2 {
                    font-size: 20px;
                    line-height: 30px;
                    margin-left: 24px;
                }
            }                   
        }        

        .LeftMiddleContainer{
            display: flex;
            flex-direction: column;
            margin-left: 24px;
            h2{                
                font-weight: bold;                
                line-height: 2rem;
                font-size: 24px;
                margin-bottom: 0px;
            }
            .UserInfoContainer{
                .Name{
                }
                .Email{
                    margin-top: 4px;
                }
                .Phone{
                    margin-top: 4px;
                }
            }
        }

        .RightContainer{
            margin-left: 270px;
            margin-top: 6px;
        }
    }

    .ExpandedContainer{
        display: flex;
        background-color: white;
        border: 2px solid var(--color-black20);
        border-radius: 16px;
        padding: 44px 88px 56px 32px;      
        justify-content: space-between;        
       
        @media (max-width: 1047px) {
            padding: 24px 40px 32px 40px;
            width: 100%;
        }
       
        .LeftLeftContainer{
            @media (max-width: 1047px) {
            display: flex;
                h2 {
                    font-size: 20px;
                    line-height: 30px;
                    margin-left: 24px;
                }
            }
                     
        }
        
        .LeftMiddleContainer{
            display: flex;
            flex-direction: column;
            margin-left: 24px;
            
            @media (max-width: 1047px) {
                margin-left: 0px;
                .SubscriptionForm{
                    padding: 0px;                
                    width: 100%;
                }
            }

            h2{            
                font-size: 24px;
                font-weight: bold;                
                line-height: 2rem;
                margin-bottom: 0px;
            }

            .LoginContainer{
                display: flex;            
                margin-bottom: 40px;
                margin-top: 40px;

                .GoogleRegisterButton button{                
                    max-width: 311px;
                    width: 226px;
                    max-height: 80px;
                    border-radius: 8px;
                    color: #2F2F37;
                    border: 2px solid #dee7ec !important;
                    padding: 14px 24px 14px 15px !important;                                

                    span {
                        padding: 1px 1px 1px 13.5px !important;
                        margin: 0px;
                        font-size: 14px!important;
                    
                        @media (min-width: $desktop) {
                            white-space: nowrap;
                        }
                    }
                }

                .HasAccount{                
                    align-self: center;
                    font-size: 14px;
                    margin-left: 24px;
                }
            }
            
            .InputContainer{
                div div input{
                    width: 464px;
                }
                @media (max-width: 1047px) {                    
                    min-width: unset;

                    div div {
                        width: 100%;
                    }
                    div div input{
                        width: 100%;
                        min-width: unset;
                        margin: 0px;
                    }
                }    
            }

            .PaymentHeader{                
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 8px;
                margin-top: 40px;
            }
            
            .PaymentDescription{
                margin-bottom: 24px;
            }

            .InputHelpText{
                margin-top: -15px;
                margin-left: 4px;
                color:var(--color-black40);
                font-size: 13px;
            }
            
            .AccessHeader{                
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 8px;
                margin-top: 40px;
            }

            
            .AccessDescriptionSecond{
                margin-top: 8px;
                margin-bottom: 24px;
                font-style: italic;
                color: var(--color-black40);
                font-size: 14px;
            }         

            .MicrosoftHeader{                
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 8px;
                margin-top: 48px;
            }
        
            .YesNoButtonContainer{
                display: flex;                
                .YesButton, .NoButton{
                    border-radius: 8px;
                    border:2px solid var(--color-blue30);
                    border: 2px solid #dee7ec !important;
                    width: 224px;
                    height: 48px;
                    padding: 14px 16px;
                    font-size: 14px;
                    margin-top: 24px;     
                    font-weight: 600;
                    font-size: 16px;                          
                    display: flex;                                        
                    align-items: center;                                                           

                    input[type='radio'] {
                        -webkit-appearance:none;
                        width:18px;
                        height:18px;
                        border:2px solid var(--color-blue30);
                        border-radius:50%;
                        outline:none;
                        margin-right: 16px;  
                        padding: 1px;      
                        margin-top: 0px;                
                    }
                  
                    input[type='radio']:before {
                        content:'';
                        display:block;
                        width:100%;
                        height:100%;                        
                        border-radius:50%;    
                    }
                    input[type='radio']:checked:before {
                        background:var(--color-blue30);                        
                    }                
                }
                .NoButton{
                    margin-left: 16px;                    
                }
            }

            .TenantIDContainer{
                div div input{
                    width: 464px;                    
                }

                @media (max-width: 1047px) {
                    div div, div div input{
                        width: 100%;    
                        min-width: unset;                    
                    }
                }

                .TenantIDHeader{
                    font-size: 14px;
                    margin-bottom: 8px;
                    margin-top: 24px;
                    display: flex;
                    align-items: center;                    

                    button.HelpIcon{                        
                        padding: 0px!important;
                        border: none!important;  
                        min-width: 8px!important;
                        min-height: 8px!important;   
                        font-size: 14px!important;

                        svg {
                            width: 12px!important;  
                            height: 12px!important;  
                        }                                               
                    }        
                }
                .TenantIDDescription{
                    font-size: 13px;
                }

                .TenantIDHelpText{
                    margin-top: -15px;
                    margin-left: 4px;
                    color: var(--color-black40);
                    font-size: 13px;                    
                }

                .BubbleText{
                    background: var(--color-black20);   
                    color: white; 
                    font-size: 12px;                                     
                    padding:7px 15px; 
                    border-radius:3px;
                    width:289px;   
                    height: 119px;
                    position: absolute;
                    z-index: 2;      
                    margin-left: -56px; 
                    padding: 12px 16px;     
                    @media (max-width: 1047px) {
                        margin-left: -24px;
                    }     
                }
                
                .BubbleText::before{
                    content:""; 
                    border-left:20px solid transparent;
                    border-right:20px solid transparent;
                    border-bottom: 20px solid var(--color-black20);
                    margin-top: -22px;
                    margin-left: 55px;
                    position: absolute;
                }   
            }        
       

            .TenantIDDescription{
                margin-bottom: 24px;
            }
                        

            .LogoHeader{                
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 8px;
                margin-top: 48px;
            }

            .LogoHelpText{
                color: var(--color-black40);
                font-size: 14px;
                font-style: italic;
                margin-top: 8px;
            }

            .RemoveImageButton{
                margin-left: 16px;
                div, span{
                    color: #BD2C0F;
                }
            }

            .ImageErrorText{
                color: var(--color-red40);                                                
                letter-spacing: 0;
                font-size: 14px;   
            }

            .ImageUploadContainer{
                @media (max-width: 1047px) {
                    width: 100%;
                    
                    .LogoButtonContainer{
                        display: flex;
                        justify-content: space-between;
                    }

                    div button{
                        width: fit-content;
                    }

                    .ImageContainer{
                        width: 100%;                        
                    }

                    .ImageErrorText{
                        color: var(--color-red40);                                                
                        letter-spacing: 0;
                        font-size: 14px;   
                        margin-top: 8px;                     
                    }
                }
            }

            .UpdateImageButton, .UploadImageButton, .RemoveImageButton {                
                    border-radius: 8px;
                    color: var(--color-blue30);
                    border: 2px solid #dee7ec !important;
                    width: 226px;
                
                    padding: 15px 16px 13px  16px;
                    font-size: 14px;
                    margin-top: 24px;          
                    height: 48px;      
                    min-height: 48px;
                    font-size: 16px;          
                    margin-bottom: 4px;                                 
                    span{
                        padding-right: 16px;
                        padding-left: 0px;    
                        margin-right: 0px!important;                    
                        margin-left: 0px!important;                        
                    }
                    div{
                        justify-content: start;
                        margin-top: -2px!important;                        
                    }         
                    span, svg {
                        height: 20px;
                    }
               

            }

            .UpdateImageButton, .RemoveImageButton {                
                @media (max-width: 1047px) { 
                    width: 100%!important;
                    div{
                        margin-left: 8px!important;
                    }   
                }

            }

            .UploadImageButton{
                span{
                    padding-right: 16px;
                    // padding-left: -8px;
                }
                div{
                    justify-self: start;
                    text-align: start;
                    display: flex;
                    align-self: start;
                    margin-top: -2px!important;
                    // margin-left: -24px;
                }
                @media (max-width: 1047px) { 
                    // width: 100%!important;
                    div{
                        margin-left: 8px;
                    }   
                }
            }

            .ImageContainer{
                margin-top: 16px;
                border: 2px solid #dee7ec !important;
                border-radius: 8px;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                // padding: 8px;
                width: 472px;
                

                .Image{
                    height: inherit;
                    width: inherit;
                    
                    padding: 8px;
                }
            }                   
        }

        .PriceOuterContainer{    
            margin-top: 40px;        

            .YourPriceHeader{
                font-size: 16px;
                font-weight: bold;
            }

            .NumberOfUsersInputLabel{
                margin-top: 24px;
                font-weight: 600;
                font-size: 14px;
                margin-bottom: 4px;
            }

            .NumberOfUsersError{
                border: 2px solid var(--color-red40);
            }
           
            input{
                width: 100%;
                height: 48px;
                border: 2px solid var(--color-blue90);
                border-radius: 4px;
                padding: 9px 16px;
                font-size: 20px; 
                @media (max-width: 1047px) {    
                    border-radius: 8px;
                }
            }

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
            input[type=number] {
                -moz-appearance: textfield;
            }
            
            .DiscountText{
                margin-top: -42px;
                width: 100%;                
                text-align: end;                                        
                padding: 6px 16px;
                font-style: italic;
                font-size: 16px;
            }
            
            .PriceBorder{                            
                margin-top: 40px;
            }

            .PriceContainer{                
                width: 100%;                                
                margin-top: 16px;
                .PriceCurrencyContainer{
                    display: flex;
                    flex-direction: row;
                    margin-top: 32px;
                
                    .Price{
                        font-weight: bold;
                        font-size: 32px;
                        margin-left: 8px;
                        margin-right: 4px;
                    }
                    .PriceCurrency{
                        margin-top: 10px;
                        font-size: 20px;
                    }
                }
                .DiscountPriceText{                    
                    margin: 4px 0px 16px 32px;
                    font-size: 14px;
                    color: #BD2C0F;
                    text-decoration:line-through;
                    display: flex;
                    flex-direction: row;
                    font-style: italic;
                    .FullPrice{
                        margin-right: 8px;
                    }
                }
            }         
        }
        
        a {
            text-decoration: underline;
            &:hover,
            &:focus,
            &:active,
            &:visited {
                color: var(--color-link);
            }
        }

        form {
            min-width: 100px!important;
            display: flex;
            justify-content: center;
            flex-direction: column;

            .createAccountButton {            
                min-height: 56px;
                width: 254px;
                margin-top: 56px;

                @media (max-width: 1047px) {                
                    margin-bottom: 48px;
                    align-self: start;                    
                }
            }
        }
    }
}

.Form__Info {
    color: var(--color-terms);
    text-align: center;
    font-size: 0.875rem;
    margin: 48px 0;
}

.Form__Terms {
    text-align: start;
}

.Form__Actions {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}

// Form controls
.Form__Field {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
    position: relative;

    .inputField input {
        background: var(--color-form-input-bg) 0% 0% no-repeat padding-box;
        padding: 12px;
        margin-bottom: 2px;        
        
    }

    & > span {
        height: 15px;
        margin-bottom: 4px;
        letter-spacing: 0;
        font-size: 0.688rem;
        opacity: 0.7;
    }
}

.Form__Label {
    text-align: left;
    letter-spacing: 0.34px;
    color: var(--color-form-label);
    font-size: 0.875rem;
    font-weight: bold;
    margin-bottom: 2px;
}

.Form__Input {
    border: 2px solid var(--color-form-input-border);
    border-radius: var(--radius-form-input);
    min-width: 100px!important;
    min-height: 48px;

    &:hover,
    &:active,
    &:focus {
        outline: none;
        border: 2px dashed var(--color-form-input-border-active);
    }
}

.Form__Input__Error {
    border: 2px solid var(--color-form-input-border-error);
    border-radius: var(--radius-form-input);
    min-height: 48px;

    &:hover,
    &:active,
    &:focus {
        outline: none;
        border: 2px dashed var(--color-form-input-border-error);
    }
}

.Form__Placeholder {
    color: var(--color-form-hint);
}

// Error styles
.Form__Error__Show {
    color: var(--color-form-error);
}

.Form__Error__Hidden {
    visibility: hidden;
}

.Form__PassToggle__Hidden {
    position: absolute;
    top: 36px;
    right: 12px;
    background-color: transparent;
    border: none;
    outline: none;
    margin: 0;
    padding: 0;
}

.Form__PassToggle__Reveal {
    position: absolute;
    top: 32px;
    right: 11px;
    background-color: transparent;
    border: none;
    outline: none;
    margin: 0;
    padding: 0;
}

.ButtonLink {
    cursor: pointer;
    border: none;
    background-color: transparent;
    color: var(--color-link);
    text-decoration: underline;
    margin: 0;
    padding: 0;
    font-size: inherit;
}

