@import '../../style/styles.scss';

 $heightSmall: 40px;
 $heightNormal: 48px;
 $heightLarge: 56px;
 $paddingSmall: 0 24px;
 $paddingNormal: 0 32px;
 $paddingLarge: 0 40px;
 $bgColorCta: #f48e75;
 $bgColorCtaHover: #db8069;
 $bgColorPrimary: #ffffff;
 $bgColorPrimaryHover: rgba(43, 74, 100, 0.051);
 $bgColorPrimaryDarkHover: rgba(255, 255, 255, 0.1);
 $bgColorSecondary: #d1e4d0;
 $bgColorSecondaryHover: #bad1b9;
 $borderWidth: 2px;

 .button {
     font-family: $fontFamily;
     color: $primaryTextColor;
     box-sizing: border-box;
     border: 0;
     outline: none;
     background-color: unset;
     letter-spacing: 0px;
     max-height: 56px;

     &.normal {
         height: $heightNormal;
         border-radius: $heightNormal / 2;
         padding: $paddingNormal;
     }

     &.large {
         height: $heightLarge;
         border-radius: $heightLarge / 2;
         padding: $paddingLarge;
     }

     &.small {
         height: $heightSmall;
         border-radius: $heightSmall / 2;
         padding: $paddingSmall;
     }

     &.fullWidth {
         display: block;
         width: 100%;
     }

     &:focus {
         outline: none;
     }

     &.cta {
         font-size: $fontSizeSm;
         font-weight: 600;
         letter-spacing: 0.56px;
         background: $bgColorCta;
         text-transform: uppercase;
         box-shadow: 0 1px 2px hsla(207, 40%, 28%, 0.2);
         border: 0;

         &:hover {
             background: $bgColorCtaHover;
         }

         &:focus {
             &.normal {
                 @include dashedBorder($primaryColor, $heightNormal / 2, 4);
             }

             &.large {
                 @include dashedBorder($primaryColor, $heightLarge / 2, 4);
             }

             &.small {
                 @include dashedBorder($primaryColor, $heightSmall / 2, 4);
             }
         }
     }

     &.primary {
         font-size: $fontSize;
         font-weight: 600;
         color: $primaryColor;
         border: $borderWidth solid $primaryColor;

         &.dark {
             color: $white;
         }

         &:hover {
             &.light {
                 background: $bgColorPrimaryHover;
             }

             &.dark {
                 background: $bgColorPrimaryDarkHover;
             }
         }

         &:focus {
             border: none;

             &.dark {
                 &.normal {
                     @include dashedBorder($white, $heightNormal / 2, 4);
                 }

                 &.large {
                     @include dashedBorder($white, $heightLarge / 2, 4);
                 }

                 &.small {
                     @include dashedBorder($white, $heightSmall / 2, 4);
                 }
             }

             &.light {
                 &.normal {
                     @include dashedBorder($primaryColor, $heightNormal / 2, 4);
                 }

                 &.large {
                     @include dashedBorder($primaryColor, $heightLarge / 2, 4);
                 }

                 &.small {
                     @include dashedBorder($primaryColor, $heightSmall / 2, 4);
                 }
             }
         }
     }

     &.primary-squashed {
         font-size: $fontSize;
         font-weight: 600;
         letter-spacing: 0px;
         color: $primaryColor;
         border: $borderWidth solid $primaryColor;
         max-height: 40px;

         &.dark {
             color: $white;
         }

         &:hover {
             &.light {
                 background: $bgColorPrimaryHover;
             }

             &.dark {
                 background: $bgColorPrimaryDarkHover;
             }
         }

         &:focus {
             border: none;

             &.dark {
                 &.normal {
                     @include dashedBorder($white, $heightNormal / 2, 4);
                 }

                 &.large {
                     @include dashedBorder($white, $heightLarge / 2, 4);
                 }

                 &.small {
                     @include dashedBorder($white, $heightSmall / 2, 4);
                 }
             }

             &.light {
                 &.normal {
                     @include dashedBorder($primaryColor, $heightNormal / 2, 4);
                 }

                 &.large {
                     @include dashedBorder($primaryColor, $heightLarge / 2, 4);
                 }

                 &.small {
                     @include dashedBorder($primaryColor, $heightSmall / 2, 4);
                 }
             }
         }
     }

     &.secondary {
         font-size: $fontSizeSm;
         font-weight: 600;
         letter-spacing: 0.56px;
         background: $bgColorSecondary;

         &:hover {
             background: $bgColorSecondaryHover;
         }

         &:focus {
             &.normal {
                 @include dashedBorder($primaryColor, $heightNormal / 2, 4);
             }

             &.large {
                 @include dashedBorder($primaryColor, $heightLarge / 2, 4);
             }

             &.small {
                 @include dashedBorder($primaryColor, $heightSmall / 2, 4);
             }
         }
     }
 }