.navigationButton {
    --background-hover: var(--sanjuan-dark);
    --dot-color: var(--geyser);
    --dot-color-active: var(--sanjuan);
    --width-navigation-button: var(--nav-button-width);
    --color-navigation-button-focus: var(--sanjuan);
}

.navigationButton {
    width: var(--width-navigation-button);
    position: absolute;
    height: 100%;
    background: none;
    padding: 0;
    margin: 0 -16px;
    border: 2px solid transparent;
    border-radius: 8px;
    flex-shrink: 0;
    outline: none;
    box-shadow: none;

    &:hover {
        background: var(--background-hover);

        .dots {
            opacity: 1;
        }
    }
    &:focus {
        border: 2px dotted var(--color-navigation-button-focus);
        outline: none;

        .dots {
            opacity: 1;
        }
    }

    &.right {
        right: 0;
    }
    &.left {
        left: 0;
    }
}

.dots {
    position: absolute;
    bottom: 0;
    width: 100%;
    margin: 0 auto 8px auto;
    opacity: 0;
    transition: opacity 0.3s;
}

.dot {
    background: var(--dot-color);
    display: inline-block;
    border-radius: 50%;
    height: 4px;
    width: 4px;
    margin-left: 2px;
    margin-right: 2px;

    &.active {
        background: var(--dot-color-active);
    }
}
