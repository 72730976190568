@import '../../../style/styles.scss';

.ConfirmProPage {
    background-attachment: fixed;
    background-image: url('../../../images/bg-mobile-speechbubbles.svg');
    background-position-x: right;
    background-repeat: no-repeat;
    background-size: cover;
    @media (min-width: $tablet) {
        background-image: url('../../../images/bg-desktop-speechbubbles.svg');
    }
}

.container {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.header {
    font-size: 24px;
    font-weight: 700;
    margin-top: 40px;
    line-height: 2rem !important;
    @media (min-width: $tablet) {
        margin-top: 80px;
    }
}

.MobileHeader {
    margin-top: 148px;
}

.AppStoreBadgeContainer,
.GooglePlayStoreBadgeContainer {
    align-items: center;
    background-color: white;
    border-radius: 0px 8px 8px 0px;
    border: none;
    display: flex;
    height: 64px;
    justify-content: center;
    width: 240px;
    cursor: pointer;
}

.AppStoreBadgeContainer {
    margin-top: 55px;
}
.GooglePlayStoreBadgeContainer {
    margin-top: 24px;
}

.AppStoreBadge,
.GooglePlayStoreBadge {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 6px;
    background-position-x: left;
    margin-top: 2px;
    margin-left: 1px;
    min-height: 64px !important;
    min-width: 240px !important;
    z-index: 99;
}

.AppStoreBadge {
    background-image: url('../../../images/icons/app-store-badge.svg');
}
.GooglePlayStoreBadge {
    background-image: url('../../../images/icons/google-play-badge.svg');
}

.LoginLink {
    color: #2b4a64;
    font-family: $fontFamily;
    font-size: 16px;
    background: none !important;
    border: none;
    cursor: pointer;
    padding-top: 0px !important;
    padding-bottom: 32px !important;
    text-decoration: underline;
    flex-wrap: nowrap;
    display: flex;
    margin-top: 65px;
}

.InfoText {
    font-size: 18px;
    text-align: center;
    color: #2F2F37;
    margin-top: 14px;
    padding: 0px;
}

.infoFooter {
    font-size: 16px;
    color: #2F2F37;
    text-align: center;
    position: fixed;
    bottom: calc(env(safe-area-inset-bottom) + 24px);
}

.proCodeButtonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.MobileContinueButton {
    margin-top: 40px;
}

.DesktopContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 40px;
}

.BannerContainer {
    margin-top: 120px;
    display: flex;
    flex-direction: row;
    width: 1000px;
    height: 400px;

    background-color: #dee7ec;
    border-radius: 16px 16px 16px 16px;
}
.BannerLeft {
    width: 533px;
    height: 400px;
    background-image: url('../../../images/Banner.png');
    background-size: contain;
    background-repeat: no-repeat;
    align-content: center;
    border-radius: 16px 0px 0px 16px;
}
.BannerRight {
    // width: 50%;
    display: flex;
    flex-direction: column;
    vertical-align: middle;
    align-self: center;
}
.BannerHeader {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 8px;
}

.DesktopStoreButtonsContainer {
    margin-top: 48px;
    min-height: 50px;
    display: flex;
    flex-direction: row;
}

.BannerAppStoreBadgeContainer {
    margin-right: 16px;
}

.BannerAppStoreBadgeContainer,
.BannerGooglePlayStoreBadgeContainer {
    a.div {
        background-color: white;
        background-size: contain !important;
        border-radius: 0px 8px 8px 0px;
        border: none;
        display: flex;
        max-height: 48px !important;
        object-fit: contain;
        max-width: 180px !important;
        cursor: pointer;
    }
}

.BannerAppStoreBadge {
    background-image: url('../../../images/icons/app-store-badge.svg');
}
.BannerGooglePlayStoreBadge {
    background-image: url('../../../images/icons/google-play-badge.svg');
}

.BannerAppStoreBadge,
.BannerGooglePlayStoreBadge {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: contain;
    border-radius: 6px;
    background-position-x: left;
    margin-top: 2px;
    margin-left: 1px;
    min-height: 48px !important;
    min-width: 180px !important;
    z-index: 199;
}
