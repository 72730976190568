@import '../../../style/styles.scss';

.UserTable{
    

    .Toolbar{
        display: flex;
        justify-content: space-between; 
        padding: 20px 24px 20px 24px;
        border-bottom: 2px solid var(--color-blue90);

        .link{
            text-decoration: underline;
        }

        .SearchFieldContainer {
            background-color: white;
            border-color: #dee7ec !important;
            border-radius: 40px;
            border-width: 2px !important;
            border: solid;
            display: flex;
            flex-direction: row;
            max-height: 48px;
            padding: 2px;
            width: 100%;
            @media (min-width: $tablet) {
                // width: 500px;
                width: 240px;
                height: 48px;
                // margin-bottom: 48px;
            }
        }
        .SearchField {
            border-radius: 40px;
            border: none;
            color: #2F2F37;
            font-family: $fontFamily;
            font-weight: lighter;
            font-size: 16px;
            width: 100%;
            
        }

        .SearchIcon {
            margin: 9px 16px 8px 14px;
            width: 25.3px;            
            @media (max-width: $tablet) {
                margin: 11px 16px 8px 14px;
            }
            svg{
                height: 20px;
            }
        }
        
        .DeleteUsersButton{            
            min-height: 48px;
            border-color: var(--color-red40);
            color: var(--color-red40);            
            padding: 0px 24px;
            span, svg{
                width: 20px;
                height: 20px;
            }
        }

        .TableInfoText{
            font-size: 14px;
            font-style: italic;
            color: var(--color-black40);
            margin-top: 15px;
        }
    }

    thead{            
        height: 48px!important;
        border-bottom: 2px solid var(--color-blue90);
        tr{
            th{            
                font-size: 14px;
                color: var(--color-black40);
                    
                svg{
                    color: var(--color-blue80);  
                }    
                input[type="checkbox"]:checked + svg{
                    color: var(--color-blue30);
                }
                span{
                    margin: 0px 7px 0px 21px;                    
                }                
            }               
        }
    }

    .TableBody{              
        
        tr{                                         
            th{
                font-weight: 600;                
            }    
            td, th{
                font-size: 16px;
                
            }            
            td:nth-child(5){                
                .adminRole{
                    background-color: #FCE8B3;
                    width: fit-content;
                    padding: 6px 12px;
                    border-radius: 32px;
                }
            }            
            
            svg{
                color: var(--color-blue80);       
                
                :hover{

                }
            }
            input[type="checkbox"]:checked + svg{
                color: var(--color-blue30);
            }
            span{
                margin: 15px 7px 15px 21px;
            }
            td:not(:first-child) {
                padding-left: 36px;
            }
            th{
                padding-left: 21px;
            }
        }
        
        tr:nth-child(odd){
            background-color: var(--color-blue98);        
        }        
        tr:hover{
            background-color: var(--color-blue95)!important;
        }

        tr[aria-checked="true"]{
            background-color: var(--color-blue90);
        }
        
    }

    .TablePagination {
        border-top: 2px solid var(--color-blue90)!important;          
        div{
            div:nth-child(1):last-of-type{
                border: 2px solid var(--color-blue90);
                border-radius: 8px
            }
        }
        div, div p{
        font-size: 14px;
        color: var(--color-black40)
        }
    }
    
}
