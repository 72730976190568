@import '../../../style/styles.scss';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 64px;
    color: $textColorGray;
}

.text {
    font-style: italic;
}

.button {
    min-height: 56px;
    margin-top: 24px;
    background: $white;
}
