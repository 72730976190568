.inputForm {
    --font-family: var(--fontFamily);

    --font-size-xs: var(--fontSizeXs);
    --font-size-sm: var(--fontSizeSm);
    --font-size: var(--fontSize);

    --font-weight-light: var(--weightLight);
    --font-weight-medium: var(--weightMedium);

    --form-width: 311px;
    --form-height: 48px;
    --button-width: 96px;
    --content-height: 40px;

    --form-padding: 2px;
    --form-border-style: solid;
    --form-border-width: 2px;
    --form-border-radius: 999px;

    --primary-text-color: var(--primaryTextColor);
    --secondary-text-color: var(--primaryColor);
    --error-text-color: #c43f17;

    --form-border-color: var(--color-mystic);
    --form-highlight-color: #d95535;
    --hover-color: #d4dbe0;

    --input-padding-left: 18px;
    --input-padding-right: 18px;
    --input-border-radius: var(--form-border-radius) 0px 0px
        var(--form-border-radius);

    --error-padding: 5px;
}

.form {
    width: var(--form-width);
    height: var(--form-height);
    padding: var(--form-padding);

    display: flex;
    position: relative;

    border-style: var(--form-border-style);
    border-width: var(--form-border-width);
    border-color: var(--form-border-color);
    border-radius: var(--form-border-radius);

    &Error {
        @extend .form;
        border-color: var(--form-highlight-color);
    }

    > * {
        height: 100%;
    }

    * {
        border: none;
        outline: none;
        background: transparent;
    }
}

.input {
    width: 100%;
    border-radius: var(--input-border-radius);
    padding-left: var(--input-padding-left);
    padding-right: var(--input-padding-right);
    font-size: var(--font-size);
    font-family: var(--font-family);
    color: var(--primary-text-color);

    &Validated {
        @extend .input;
        color: var(--secondary-text-color);
    }
}

.error {
    width: 100%;
    padding: var(--error-padding);
    color: var(--error-text-color);
    font-family: var(--font-family);
    font-size: var(--font-size-xs);
    font-weight: var(--font-weight-light);
    text-align: center;
}

.background {
    width: var(--button-width);
    height: var(--content-height);

    position: absolute;
    right: var(--form-padding);
    z-index: -1;

    border-radius: var(--form-border-radius);
    background: var(--form-border-color) 0% 0% no-repeat padding-box;

    &Full {
        @extend .background;
        border-radius: var(--form-border-radius);
        width: inherit;
        height: inherit;
        margin-top: calc(var(--form-padding) * -2);
        margin-right: calc(var(--form-padding) * -2);
    }
}

.buttonBase {
    border-radius: var(--form-border-radius);

    &:hover {
        cursor: pointer;
    }

    & * {
        pointer-events: none;
    }
}

.buttonAdd {
    @extend .buttonBase;

    width: var(--button-width);
    min-width: var(--button-width);
    font-family: var(--font-family);
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-medium);
    color: var(--secondary-text-color);

    & > * {
        justify-content: center;
        display: flex;
        align-items: center;
    }

    &WithHover {
        @extend .buttonAdd;

        &:hover {
            background-color: var(--hover-color);
        }
    }
}

.buttonCross {
    @extend .buttonBase;

    min-width: var(--content-height);
    font-size: var(--font-size);
}
