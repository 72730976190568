@import '../../../../style/styles.scss';

.modal {
    display: block !important;

    .closeButton {
        left: 4px;
        padding: 8px;
        position: absolute;
        top: 4px;
        z-index: 20;
        
        @media (min-width: $tablet) {
            top: 8px;
            left: 8px;
        }
    }

    .header {
        z-index: 1;
        position: relative;
        display: flex;
        justify-content: space-between;
        margin-left: $gutter * 6;
        margin-right: $gutter * 6;
        padding-top: $gutter;
        padding-bottom: $gutter;
        letter-spacing: 0.03125em;
        margin-top: 13px;

        // max-width: 200px;

        .title {
            font-size: $fontSizeSm;
            text-transform: uppercase;
            text-align: center;
            width: 100%;                        
        }
        @media (max-width: $tablet) {
            margin-top: 3px;
        }
    }
    .BreakWords{
        word-break: break-all;
    }
}
