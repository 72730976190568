@import '../../../../style/styles.scss';

.link {
    margin-top: 16px!important;
    
}

.text {
    margin: 0;
    margin-top: $gutter;

    &:first-of-type {
        margin-top: 0;
    }
    &:last-of-type {
        margin-bottom: 16px;
    }
}

.DeleteMeLink, .UndoDeleteLink{    
    color: #BD2C0F!important;
    text-decoration-color: #BD2C0F!important;
    &:hover{
        cursor: pointer;
    }
}

.UndoDeleteLink{

}