@import '../../../../style/styles.scss';

$border: 1px solid #dee7ec;
$titleColorDesktop: #717179;

.container {
    display: flex;
    border-bottom: $border;
    justify-content: space-between;
    &:first-of-type {
        border-top: $border;
    }
    a {
        padding-top: $gutter;
        padding-bottom: $gutter;
        width: 100%;
    }
}

.title {
    flex-basis: 100%;
    font-size: $fontSizeLg;
    line-height: 1.3333;
    font-weight: normal;
}
.notification {
    white-space: nowrap;
    margin-right: 16px;
    color: $textColorGray;
    font-size: 12px;
    text-transform: uppercase;
    align-self: center;
}

.details {
    display: none;
    width: 65%;
}

@media (min-width: $tablet) {
    .container {
        padding-top: $gutter * 2;
        padding-bottom: $gutter * 2;
        justify-content: flex-start;
    }
    .title {
        flex-basis: 35%;
        font-size: $fontSizeXs;
        line-height: 22px;
        font-weight: normal;
        text-transform: uppercase;
        color: $titleColorDesktop;
    }
    .notification {
        display: none;
    }

    .details {
        display: block;
    }
}
