@import '../../style/styles.scss';

.formError {
    --color-form-error: #c43f17;
}

.formError {
    height: 24px;
    color: var(--color-form-error);
    margin: 0;
    margin-bottom: $gutter;
}