@import '../../../../style/styles.scss';

.link {
    margin-top: $gutter;
}

.text {
    margin: 0;
    margin-top: $gutter;

    &:first-of-type {
        margin-top: 0;
    }
}
