@import '../../style/styles.scss';

.CopyContent {
    max-width: 700px;
    margin: 0 auto;
    padding: 40px 32px 32px 32px;
    margin-bottom: 50px;
    @media (max-width: 768px) {
        margin-top: -16px !important;
        padding-top: 0px;
    }
}

.CopyContentIOSAPP {
    max-width: 700px;
    margin: 0 auto;
    padding: 0px 32px 32px 32px;
    margin-bottom: 50px;
}

.BackButtonContainer {
    width: 100%;
    margin: 0 auto;
    position: sticky;
    top: 0px;
    z-index: 100;
    background: rgba(255, 255, 255, 0.9);
}

.BackButton {
    padding: 8px 16px 8px 2px;
    color: #2b4a64;
    font-family: $fontFamily;
    // margin: 0 auto;
    margin-top: calc(env(safe-area-inset-top) + 8px);
    font-size: 16px;
    background: none !important;
    border: none;
    cursor: pointer;
    // margin-top: 40px;

    text-decoration: none;
    @media (min-width: $tablet) {
        margin-left: -12px;
        padding: 8px 16px 8px 16px;
    }
    @media (min-width: $desktop) {
        padding: 10px 15px;
        &:focus,
        &:hover {
            background: #eef5f5 !important;
            border-radius: 60px !important;
        }
    }
}

.LeftArrow {
    margin-right: 8px;
}
