@import '../../style/styles.scss';

.bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100%);
    pointer-events: none;
    z-index: -1;
    background-color: var(--pampas);
    background-size: cover;
}
.CreateConversationPageContainer{
    @media (min-width: $tablet) {
        align-items: center;
        display: flex;
        flex-direction: column;;
    }    
}

.Container{
    @media (min-width: $tablet) {        
        max-width: 750px;
        min-width: 750px;
        }
    @media (min-width: $desktop) {        
    max-width: 980px;
    min-width: 980px;
    }
}

.InnerContainer{
    width: fit-content;
    margin: auto;
}

.CreateConversationPage {
    margin: 0px 16px 0px 16px;
    display: inherit;    
    @media (min-width: $tablet) {
    align-items: center;
    
    }
}

.BackButtonContainer {
    margin-top: 40px;
    margin-left: 16px;
    width: 100%;
    margin: 0 auto;    
    top: 0px;
    z-index: 100;
    background: rgba(247, 243, 241, 0.9); 
}

.BackButtonContaineriOS{
    padding-top: 40px
}

.StickyBackButton{
    position: sticky;
}

.backButton {
    margin-left: 17px;
    margin-top: 40px;
    @media (min-width: $tablet) {        
        margin-left: 1px;
    }
}

.InvalidInput {
    border: #F48771 2px solid !important;
}

.title {
    font-size: 24px;
    font-weight: $weightBold;
    margin-bottom: 40px;
    margin-left:32px;
    align-self: flex-start;
    @media (min-width: $tablet) {
        margin-bottom: 80px;
        margin-left: 16px;
        }

}

.CreateConversationContainer {
    margin-left: 16px;
    margin-right: 16px;
    display: flex;
    flex-direction: column;
}

input,
textarea {
    border: #e3d4ce 1px solid;
    border-radius: 8px;
}

.TitleLabel {
    font-size: 14px;
    font-weight: $weightMedium;
    margin-bottom: 2px;
    margin-left: 4px;
}

.titleField,
.descriptionField {
    min-width: 296px;
    padding: 12px 16px;
    font-family: $fontFamily;
    font-size: 16px;
}

.titleField {
    min-height: 48px;
}

.TitleErrorAndCounter{
    display: flex;
    justify-content: space-between;
    
    .TitleErrorText{
        font-size: 11px;
        color: #BD2C0F;
        margin-top: 4px;
        font-size: 12px;
    }
}


.DescriptionLabel {
    margin-top: 8px;
    font-size: 14px;
    font-weight: $weightMedium;
    margin-bottom: 2px;
    margin-left: 4px;
}
.descriptionField {
    height: 86px;
    resize: none;
}
.descriptionField:focus-visible {
    outline: 0;
}

.Counter {
    color: #5E5E6E;
    display: flex;
    font-size: 12px;
    justify-content: flex-end;
    margin-right: 4px;
    margin-top: 2px;
    
}

.ColorLabel{
    margin-top: 32px;    
    font-size: 14px;
    font-weight: $weightMedium;
    margin-bottom: 8px;
    margin-left: 4px;
}

.customRadios {
    
    div {
        display: inline-block;
    }

    div:last-of-type button {
        margin-right: 0px;
    }
    div:last-of-type label button {
        margin-right: 0px !important;
    }

    input[type='radio'] {
        display: none !important;

        + label {
            color: #333;
            font-family: Arial, sans-serif;
            font-size: 14px;

            button {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 88px;
                height: 48px;
                margin: -1px 4px 0 0;
                vertical-align: middle;
                cursor: pointer;
                border-radius: 8px;
                border: 1px solid #99adbf;
                margin-right: 16px;
                background-repeat: no-repeat;
                background-position: center;
                text-align: center;
                line-height: 44px;
                .IconCheck {
                    opacity: 0;
                    transition: all 0.3s ease;
                    width: 23px;
                }
            }
        }

        &:checked + label button {
            border: 2px solid #2b4a64;
        }

        &.color1 + label button {
            background: linear-gradient(#fce8b3, #f8bbac) left top no-repeat;
        }

        &.color2 + label button {
            background: linear-gradient(#eef5f5, #d1e4d0) left top no-repeat;
        }

        &.color3 + label button {
            background: linear-gradient(#fdeae6, #ebdbeb) left top no-repeat;
        }

        &:checked + label button .IconCheck {
            opacity: 1;
        }
    }
}

.QuestionBoxContainer{
    @media (min-width: $tablet) {
    columns: 2 auto;
    }
}

.QuestionBox {
    width: 100%;
}

.AddQuestionLinkContainer {
    margin-top: 48px;
    align-self: center;
    
    .AddQuestionLink {
        background: none;
        border: none;
        border-radius: 60px;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
        display: flex;
        flex-direction: row;
        text-decoration: none !important;
        justify-content: center;
        align-items: center;
        padding: 13px 32px 11px;
        height: 56px;
        font-size: 16px;
        font-weight: $weightMedium;
        .AddIcon {
            width: 24px;
            height: 24px;
            padding: 5px;
        }
        &:hover{
            background: rgba(50, 77, 103, 0.05);            
        }
        &:focus{
            background-image: none!important;
        }
    }
}

.ButtonContainer {
    display: flex;
    justify-content: center;
    .ConfirmButton {
        margin-top: 32px;
        min-width: 216px;
        max-height: 56px;
    }
}
.SelectingQuestionsButtonContainer {
    background: linear-gradient(0deg, #f7f3f1 0, rgba(40, 40, 40, 0) 100%);
    position: sticky;
    bottom: 0px;
    height: 96px;
    .ConfirmButton {
        margin-bottom: calc(env(safe-area-inset-bottom) + 24px);
        margin-top: 16px;
    }
}
