@import '../../style/styles.scss';

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.spinner {
    --size: 20px;
    --border-radius: 50%;
    --border-width: 3px;
    --border-style: solid;
    --interval: 0.75s;
    --timing-function: linear;
    --primary-color: var(--primaryColor);
}

.spinner {
    width: var(--size)!important;
    height: var(--size)!important;
    border-radius: var(--border-radius);

    border-bottom: var(--border-width) var(--border-style) var(--primary-color);
    border-left: var(--border-width) var(--border-style) var(--primary-color);
    border-top: var(--border-width) var(--border-style) var(--primary-color);
    border-right: var(--border-width) var(--border-style) transparent;

    animation: spinner var(--interval) var(--timing-function) infinite;
}

.blackSpinner {
    border-bottom: var(--border-width) var(--border-style) var(--color-black20);
    border-left: var(--border-width) var(--border-style) var(--color-black20);
    border-top: var(--border-width) var(--border-style) var(--color-black20);
    border-right: var(--border-width) var(--border-style) transparent;
}
