@import '../../../style/styles.scss';

.link {
    margin-top: $gutter;
}

.bgNoSub {
    background-image: url('./images/bg-mobile-wind-turbine.svg');
    background-position-y: bottom;

    @media (min-width: $tablet) {
        background-image: none;
    }
}
