@import '../../style/styles.scss';

.StartConversation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    text-align: center;
    max-width: 460px;
    z-index: 15;
}

.BreakWords{
    word-break: break-all;
}

.Overview {
    h1 {
        margin: 0;
        font-weight: bold;
        font-size: 1.5rem;
        padding: 0px 32px 0px 32px;
        line-height: 1.8rem;
        max-width: 300px;
        @media (min-width: $tablet) {
            max-width: 800px;
        }        
    }

    p {        
        line-height: 1.8rem;
    }
    .PublicIcon, .PrivateIcon{        
        height: 12px;
        width: 12px;
        margin-bottom: -1px;
    }
    .WorkHomeIcon{
        height: 15px;
        width: 15px;
        margin-bottom: -2px;
    }
}

.description {
    margin-bottom: 40px;
    margin-top: 40px;
    max-width: 300px;
    
    @media (min-width: $tablet) {
        max-width: 700px;
    }
    p {
        font-size: 1rem;
        padding: 0 48px;
    }
}

.ShuffleIcon.Active {
    background-color: #2b4a64;
    @media (min-width: $tablet) {
        &:hover {
            background-color: #2F2F37;
        }
    }
}
.ShuffleIcon.Inactive {
    background-color: #e3d4ce;
    @media (min-width: $tablet) {
        &:hover {
            background-color: rgba(43, 74, 100, 0.05);
        }
    }
}

.ShuffleIcon {
    border-radius: 200px;
    -webkit-tap-highlight-color: transparent;
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 26px;
    padding: 8px;
    margin-top: 40px;
    right: 8px;
    height: 48px;
    max-height: 48px;
    width: 48px;
    z-index: 98;
    .ActiveShuffleIcon,
    .InactiveShuffleIcon {
        width: 17px !important;
        height: 17px !important;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
        z-index: 99;
    }
    .ActiveShuffleIcon {
        background-image: url('../../images/icons/shuffle-white.svg');
    }
    .InactiveShuffleIcon {
        background-image: url('../../images/icons/shuffle-blue.svg');
    }
    .CheckIconContainer {
        margin-top: -30px;
        margin-left: 30px;
        position: fixed;
        width: 16px !important;
        height: 16px !important;
    }
    .CheckIconContainer.IOS {
        margin-top: -16px;
        margin-left: 16px;
    }
    .CheckIcon {
        background-image: url('../../images/icons/check-blue-circle.svg');
        background-position: center;
        background-repeat: no-repeat;
        width: 16px !important;
        height: 16px !important;
    }
}

.ShuffleInfoText {
    font-size: 13px;
    font-style: italic;
    color: #717179;
    padding-left: 50px;
    padding-right: 50px;
}

@media (min-width: $tablet) {
    .StartConversation {
        max-width: 600px;
    }

    .description {
        p {
            font-size: 1.125rem;
        }
    }
}

.options{
    margin-top: 40px;
}

.option{
    z-index: 99;
    font-size: 16px;
    font-weight: var(--weightMedium);
    font-family: var(--fontFamily);
    color:  var(--sanjuan);
    display: flex;
    align-items: center;
    padding: 13px 24px 13px 16px;
    margin-bottom: 16px;
    cursor: pointer;
}

.icon {
    margin-right: 13px;
    margin-left: 1px;
    width: 16px;
    height: 16px;
}
.StartPollingButton{
    background-color: white!important;
}