@import './style/styles.scss';
@import './professional/styles/pro-styles.scss';

:global {
    *,
    *:before,
    *:after {
        box-sizing: inherit;
    }

    *::selection {
        color: $white;
        fill: $white;
        background: $primaryColor;
    }

    html {
        box-sizing: border-box;
        color: $primaryTextColor;
        font-size: $fontSize;
        font-weight: normal;
        line-height: 1.25;
    }

    html,
    body,
    #root {
        height: 100%;
    }

    body {
        margin: 0;
        font-family: $fontFamily;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
            monospace;
    }

    b {
        font-weight: 700;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    p {
        margin: 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0;
        font-weight: 700;

        @media (min-width: $desktop) {
            font-weight: normal;
        }
    }

    h1 {
        font-size: 1.5rem;
        line-height: 2rem;

        @media (min-width: $desktop) {
            font-size: 2.5rem;
            line-height: 3.5rem;
        }
    }

    h2 {
        font-size: 1.5rem;
        line-height: 2rem;
    }

    h3 {
        font-size: 1.125rem;
        line-height: 2rem;
    }

    h4 {
        font-size: 1rem;
        line-height: 1.5rem;
    }

    input[type='text'],
    input[type='email'],
    input[type='password'],
    textarea {
        -webkit-appearance: none;
    }

    #root {
        margin-top: env(safe-area-inset-top);
    }

    .swiper-wrapper {
        box-sizing: border-box;
    }

    a.withLine {
        text-decoration: underline;
    }

    .link {
        text-decoration: underline;
        color: #2b4a64;
    }
}
