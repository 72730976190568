.Dots {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-self: center;
  align-items: flex-start;
  .Dot {
    font-size: 4rem;
    animation-name: blink;
    animation-duration: 1.8s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    animation-timing-function: ease-out;
  }
}

.Dot:nth-child(2) {
  animation-delay: 0.4s;
}

.Dot:nth-child(3) {
  animation-delay: 1s;
}

@keyframes blink {
  95% {
    opacity: 0.2;
  }
  // 10% {
  //   opacity: 1;
  // }
  100% {
    opacity: 1;
  }
}
