@import '../../style/styles.scss';

$bgGradient: linear-gradient(180deg, #e3d4ce 0%, $white 100%);
$sectionSpacer: 120px;
$smallerSectionSpacer: 56px;



.PageLayout{
    padding-left: 9px;
    padding-right: 24px;
    width: 100%;
    @media (max-width:1047px) {
        padding-left: 0px;
        padding-right: 0px;
    }

    .UpperInfoContainer{
        padding-left: 16px;
        // padding-right: 80px;
        position: relative;
        padding-top: $sectionSpacer;
        padding-bottom: $smallerSectionSpacer;
        display: flex;
        margin: 0 auto;
        justify-content: space-between;    
        
        @media (max-width:1047px) {
            padding-top: 80px;
            padding-bottom: 80px;
            
        }

        .UpperLeftContainer{
            width: 50%;  
            margin-right: 11px;      
                      
            .title  h1{
                font-size: 40px;                               
                font-weight: 700;      
                min-width: 440px;          
            }

            .text{
                margin-top: 40px;
                line-height: 32px;
                font-size: 20px; 
                max-width: 415px;                        
            }

            @media (max-width:1047px) {
                width: auto!important;                   
                margin-right: 0px;
                padding-left: 24px;
                padding-right: 40px;
                .title h1{
                    font-size: 24px;
                    min-width: 50px;
                }

                .text{
                    font-size: 18px;
                    line-height: 24px;
                    margin-top: 16px;
                }
                .text:last-child{
                    margin-top: 40px;
                }
            }

        }

        .UpperRightContainerEnglish{
            background-image: url('./images/EN-samtaler-compressed.svg')!important;            
            @media (max-width:1047px) {
                background-image: url('./images/EN-mobil-samtaler-compressed.svg')!important;            
            }
        }
        .UpperRightContainer{
            // margin-right: 80px;
            width: 509px;
            height: 502px;
            background-image: url('./images/NO-samtaler-compressed.svg');
            background-size: contain;
            background-repeat: no-repeat;
            
            @media (max-width:1047px) {
                margin-top: 72px;
                width: 326px;
                height: 397px;
                margin-right: 0px;
                margin-left: 9px;
                margin-right: 24px;
                background-image: url('./images/NO-mobil-samtaler-compressed.svg');
            }
        }
        
        @media (max-width:1047px) {
            flex-direction: column;
            
            .UpperLeftContainer{
                width: 100%;  
            }
        }
    }

    .CalculatorContainer{
        background: var(--brun-85) 0% 0% no-repeat padding-box;
        background: #E4D5CD 0% 0% no-repeat padding-box;
        box-shadow: 0px 16px 40px #0000001A;
        border-radius: 16px;
        opacity: 1;
        // height: 212px;
        width: 800px;
        margin-bottom: -90px;
        z-index: 2;
        @media (min-width: $desktop) {
            width: 1092px;            
        }

        @media (max-width:1047px) {
            height: auto;            
            width: auto;
            text-align: center;
            margin-bottom: 0px;
            margin-left: 16px;
            margin-right: 16px;
            
        }
        
       
        .InnerCalculatorContainer{
            display: flex;
            padding: 56px;
            justify-content: space-between;
            
            @media (max-width:1047px) {
                display: flex;
                flex-direction: column;
                padding: 40px 56px;
                
            }

            .CalculatorTitleAndDescription{
              
                align-self: center;
                margin-right: 0px;
                min-width: 312px;
                h2{                    
                    font-weight: bold;
                }
                .Description{                    
                    &:first-of-type {
                        margin-top: 16px;;
                    }
                } 

                @media (max-width:1047px) {
                    display: flex;
                    flex-direction: column;
                    margin-right: 0px;

                    .Description{
                        line-height: 24px;
                    }
                }
            }

            .TitleInputAndPriceContainer{
                display: flex;
                justify-self: start;
                @media (max-width:1047px) {         
                    flex-direction: column;
                }
            }

            .InputContainer{
                align-self: center;                
                margin-top: 20px;
                margin-left: 40px;
                input{
                    width: 120px;
                    height: 56px;                
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    border-radius: 4px;      
                    padding: 12px 10px 13px 16px;      
                    font-size: 24px;                          
                }

                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
                input[type=number] {
                    -moz-appearance: textfield;
                }
                @media (max-width:1047px) {
                    margin-top: 48px;
                    margin-left: 0px;
                    input{
                        margin-top: 8px;
                        text-align: center;
                        width: 199px;                        
                    }
                }


            }

            .PriceContainer{
                margin-top: 20px;
                margin-left: 24px;
                .PriceCurrencyContainer{
                    display: flex;
                    flex-direction: row;
                    margin-top: 32px;
                
                    .Price{
                        font-weight: bold;
                        font-size: 32px;
                        margin-right: 8px;
                    }
                    .PriceCurrency{
                        margin-top: 10px;
                        font-size: 20px;
                    }
                }
                .DiscountText{
                    margin-top: 4px;
                    font-size: 14px;
                    color: #BD2C0F;
                    text-decoration:line-through;
                    display: flex;
                    flex-direction: row;
                    font-style: italic;
                    .FullPrice{
                        margin-right: 8px;
                    }
                }

                @media (max-width:1047px) {                        
                    width: 199px;        
                    align-self: center;
                    margin-top: 16px;                    

                    .PriceCurrencyContainer{
                        // width: 100px;
                        margin-top: 0px;
                    }
                    .DiscountText{
                        margin-top: 4px;
                        margin-left: 23px;
                    }
                }
            }         
        }

        .PurchaseButton{
            min-width: 224px;
            box-shadow: 0px 1px 1px #2B4A6433;
            border-radius: 80px;
            max-height: 56px;
            align-self: center;
            margin-top: 32px;

            @media (max-width:1047px) {     
                margin-top: 48px;
            }
        }
    }

    .FeatureList{
        font-size: 20px;
        margin-left: 100px;
        .FeatureItem{
            display: flex;
            .FeatureItemIcon{
                margin-right: 8.6px;    
                margin-top: 3px;
            }
            .FeatureItemText{
                margin-bottom: 16px;
            }
        }
        ul {
            list-style: none;
        }       
    }

    .FeatureTextContainer2{
        margin-top: 80px;
        line-height: 32px;
        font-size: 18px;
        h2{
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 16px;
        }
        .link{
            text-decoration: underline;
        }
    }

    .LowerInfoContainer{
        height: 928px;      
        width: 1280px;  
        border: 4px solid #EFF1F5;
        border-radius: 40px;
        opacity: 1;
        margin-bottom: 104px;
        z-index: 1;    

   

        .LowerInnerInfoContainer{
            margin-top: 241px;
            display: flex;                                   

            .OuterFeatureTextContainer{
                display: flex;
                flex-direction: column;
                max-width: 450px;
                margin-right: 100px;
                margin-left: 126px;
                
                .FeatureTextContainer1{
                    line-height: 32px;
                    font-size: 18px;
                    h2{
                        font-size: 24px;
                        font-weight: bold;
                        margin-bottom: 16px;
                    }                    
                }              
            }
        }

        .DesktopImageContainer{
            height: 348px;
            width: 676px;
            left: 0;
            bottom: 0;
            margin-bottom: -1px;
            margin-left: -1px;
            background-image: url('./images/desktop.svg');
            background-repeat: no-repeat;
            position: absolute;        
        }

        @media (max-width:1047px) { 
            width: auto;
            border: none;
            margin-top: 80px;
            margin-bottom: 40px;
            height: auto;

            .FeatureTextContainer2{
                padding: 40px;
                // margin-top: -29px;
                margin-top: 0px;
                width: 100%;
                // margin-right: -16px;
                // margin-left: -16px;
                background-color: var(--color-blue95);
                font-size: 16px;            
                line-height: 24px; 
                h2{
                    font-size: 20px;                                                        
                    line-height: 24px; 
                }
            }

            .LowerInnerInfoContainer{
                margin-top: 0px;
                // height: 880;                
                display: flex;
                flex-direction: column;                
                .OuterFeatureTextContainer{
                    display: flex;
                    flex-direction: column;
                    max-width: unset;
                    margin-right: 40px;
                    margin-left: 40px;
                             
                    .FeatureTextContainer1{                        
                        font-size: 16px;
                        line-height: 24px; 
                        h2{
                            font-size: 20px;                                                                                    
                            line-height: 24px;                        
                        }
    
                    }
                  
                }                

            }            
            .DesktopImageContainer{
                position: relative;
                background-image: url('./images/desktop_mobile.svg');
                margin-top: 40px;
                height: 264px;
                width: 100%;                     
                background-size:auto;
                right: 0!important;
                background-size: cover;
                background-position: center;
                background-size: 100% 100%;         
             }
            .FeatureList{
                margin-left: 0px;
                font-size: 16px;
                margin-top: 40px;
                // max-width: 283px;
                ul {
                    padding-left: 40px;
                    padding-right: 40px;
                }

                .FeatureItem{
                    .FeatureItemText{
                        max-width: 234px;
                    }
                    svg{
                        height: 16px;
                        width: 16px;
                    }
                }
            }        
        }
    }

    .SchoolContainer{

        .InnerSchoolContainer{
            background-color: #FCE8B3;
            border-radius: 16px;
            // width: 1092px;
            height: 374px;
            padding: 56px 0px 0px 56px;
            display: flex;            
            flex-direction: row;
            width: 100%;
            margin: 0px ;
            justify-content: space-between;
            .LeftSchoolContainer{
                max-width: 580px;
                .SchoolTitle{
                    font-size: 24px;
                    font-weight: bold;
                }
                .SchoolDescription1{
                    font-size: 18px;
                    line-height: 32px;
                    margin-top: 16px;
                }
                .SchoolDescription2{
                    font-size: 18px;
                    line-height: 32px;                    
                    margin-top: 16px;
                    .Link{
                        text-decoration: underline;
                    }
                }
            }
            .RightSchoolContainer{
                
                width: 400px;
                background-image: url('./images/school_image.svg');
                background-repeat: no-repeat;
                margin-left: -10px;
                padding-right: 50px;
                margin-top: -56px;
            }

            @media (max-width:1047px) { 
                flex-direction: column;
                width: 100%;
                padding: 32px 24px;
                height: auto;
                padding-bottom: 0px;
                .LeftSchoolContainer{
                    .SchoolTitle{
                        font-size: 20px;                        
                        line-height: 24px;
                    }
                    .SchoolDescription1, .SchoolDescription2{                        
                        line-height: 24px;           
                        font-size: 16px;             
                    }                    
                }
                
                .RightSchoolContainer{
                    background-image: url('./images/school_image_mobile.svg');
                    height: 319px;
                    width: 400px;
                    margin: 0px;
                    right: 0!important;
                    padding: 0px;                    
                    background-size: contain;
                    align-self: end;
                    margin-right: -24px;
                    margin-top: 40px;
                }
            }
        }
        margin-bottom: 160px;
        @media (max-width:1047px) { 
            margin-bottom: 80px;
            padding: 0px 16px 0px 16px;
        }
    }    
    .LowerPurchaseButtonContainer{                
        display: flex;                
        justify-content: center;
        // margin-top: 48px;                
        margin-bottom: 97px;
    }
}