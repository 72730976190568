.Callout {
  --padding-callout: 9.42px 20px;
  --bg-color: #f48e75;
  --text-color: #2F2F37;
}

.Callout {
  background-color: var(--bg-color);
  padding: var(--padding-callout);
  display: inline-block;
  border-radius: 30px;
}

.Callout:before {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  left: 40px;
  right: auto;
  top: auto;
  bottom: -40px;
}
.Callout:after {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  left: 44px;
  right: auto;
  top: auto;
  bottom: -8px;
  border: 5px solid;
  border-color: var(--bg-color) transparent transparent var(--bg-color);
}
