@import '../../../style/styles.scss';

.text {
    margin-top: 0;
    margin-bottom: $gutter * 1.5;
    font-size: 16px;
    line-height: 24px;
    @media (min-width: $desktop) {        
        text-align: center;
    }
    &:last-of-type{
        margin-bottom: 40px;
    }
}

.ConfirmRecoverButton {
    margin-top: $gutter;
    margin-bottom: $gutter * 2.5;
    align-self: center;
}

.PointerCursor{
    cursor: pointer;
}