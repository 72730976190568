@import '../../style/styles.scss';

.header {
    position: absolute;
    top: env(safe-area-inset-top);
    left: 0;
    z-index: 1000;
    width: 100%;
    text-align: center;

    @media (min-width: $tablet) {
        padding-top: $gutter * 1.5;
        padding-bottom: $gutter * 1.5;
    }
}

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    padding-left: $gutter * 1;
    padding-right: $gutter * 1;

    &.centered {
        width: 100%;
        justify-content: center;
    }
}

.Login {
    font-size: $fontSizeSm;
}

.iconBox {
    height: 48px;
    width: 48px;
    margin-right: -16px;

    .accountLink {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url('./images/user.svg');
    }
}
