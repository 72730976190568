@import '../../../style/styles.scss';

.questionContainer {
    width: unset;
    max-width: 460px;

    @media (min-width: $tablet) {
        max-width: 600px;
    }

    .question {
        color: $primaryColor;
        font-weight: 600;

        @media (min-width: $desktop) {
            font-weight: normal;
            font-size: 2.1rem;
            line-height: 2.8rem;
        }
    }
}
