@import '../../style/styles.scss';

.TermsConditions {
    padding: 0 0 8px 0;

    h3 {
        margin-top: 32px;
        margin-bottom: 16px;
    }

    p {
        line-height: 1.2rem;
        font-size: 1rem;
        display: block;
        margin-bottom: 1rem;
    }
    ul {
        list-style-type: disc;
        font-size: 1rem;
        display: block;
        margin-bottom: 1rem;
    }

    li {
        margin: 2px;
        font-size: 1rem;
    }

    a {
        text-decoration: underline;
        color: #2b4a64;
        font-size: 1rem;
    }
    address {
        font-size: 1rem;
        font-style: initial;
    }
}

.Confirmation {
    width: 100%;
    text-align: center;
    margin-top: 40px;
}

@media (min-width: $tablet) {
    .TermsConditions {
        h1,
        h2,
        h3,
        h4 {
            font-weight: bold;
        }
    }
}
