@import '../../style/styles.scss';

.container {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.SuccessIcon {
    margin-top: 46px;
    width: 60px;
    height: 60px;
    @media (min-width: $tablet) {
        margin-top: 150px;
    }
}

.confirmationHeader1 {
    margin-top: 40px;
}

.confirmationHeader1,
.confirmationHeader2 {
    max-width: 214px;
    text-align: center;

    @media (min-width: $tablet) {
        max-width: 500px;
    }
}

.confirmationInfoText1 {
    font-size: 16px;
    text-align: center;
    color: #2F2F37;
    margin-top: 24px;
    max-width: 295px;
    padding: 40px;
}
.confirmationInfoText2 {
    font-size: 16px;
    text-align: center;
    color: #2F2F37;
    margin-top: 24px;
    max-width: 350px;
    padding: 0px 40px 0px 40px;
}

.exploreButton {
    margin-top: 72px;
    height: 56px !important;
    max-height: 56px !important;
    min-width: 202px !important;
    padding: 16px 40px 16px 40px !important;
    font-weight: 600px !important;
    line-height: 23px !important;
}

.infoFooter {
    font-size: 16px;
    color: #2F2F37;
    text-align: center;
    position: fixed;
    bottom: calc(env(safe-area-inset-bottom) + 24px);
    font-style: italic;
}
