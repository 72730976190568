.card {
    --width-tablet: var(--card-width-tablet);
    --height-tablet: var(--width-tablet);
}

.card {
    border-radius: 50%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 700;
    cursor: pointer;
    box-sizing: border-box;

    width: 140px !important;
    height: 140px !important;
    margin-right: 5px;
    margin-left: 5px;

    @media (min-width: var(--width-tablet)) {
        height: var(--height-tablet);
        width: var(--width-tablet);
    }
}
