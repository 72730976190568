@import '../../style/styles.scss';

.deckModalRandom {
    overflow: hidden;
    align-items: center;
    align-content: center;
    text-align: center;
}

.Bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% + 60px);
    pointer-events: none;
    background-image: linear-gradient(#FFFFFF, #E3D4CE);    
    background-size: cover;
    
}

.BgBubbles {
    --bg-gradient-mobile-deck-modal: var(--bg-gradient-mobile);
    --bg-gradient-desktop-deck-modal: var(--bg-gradient-desktop);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% + 60px);
    pointer-events: none;
    background-image: url('./images/bg-mobile-deck-modal.svg'),
        var(--bg-gradient-mobile-deck-modal)!important;
    background-size: cover;
    background-image: linear-gradient(#FFFFFF, #E3D4CE);    

    @media (min-width: 1048px) {
        background-image: url('./images/bg-desktop-deck-modal.svg'),
            var(--bg-gradient-desktop-deck-modal)!important;
    }        
}


.deckModal,
.deckModalRandom {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex !important;
    flex-direction: column;

    
    .shuffleButton {
        background-color: black;
        position: absolute;
        top: 0;
        width: 100px;
        height: 100px;
        right: 500px;
    }

    .favouriteHeartIntro {
        right: 8px !important;
    }
    .favouriteHeart {
        -webkit-tap-highlight-color: transparent;
        align-items: center;
        background-position: center;
        background-repeat: no-repeat;
        background: none;
        border: none;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-width: 26px;
        top: 8px;
        padding: 8px;
        margin-top: 2px;
        position: absolute;
        right: 64px;
        height: 56px;
        width: 56px;
        z-index: 98;
        .redHeartIcon,
        .redHeartIconOutline {
            width: 17.4px;
            height: 16px;
            background-position: center;
            background-repeat: no-repeat;
            cursor: pointer;
            z-index: 99;
        }
        .redHeartIcon {
            background-image: url('../../images/icons/red-heart.svg');
        }
        .redHeartIconOutline {
            background-image: url('../../images/icons/heart-outline.svg');
        }
        @media (min-width: $tablet) {
            &:hover {
                background-color: rgba(43, 74, 100, 0.05);

                border-radius: 200px;
                padding: 14px 26px 14px 26px;
            }
        }
        @media (max-width: $tablet) {
            margin-top: 0px;
            top: 0px;
            right: 40px;
        }
    }
    
    .moreVert {
        -webkit-tap-highlight-color: transparent;
        align-items: center;
        background-position: center;
        background-repeat: no-repeat;
        background: none;
        border: none;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-width: 26px;
        top: 8px;
        padding: 8px;
        margin-top: 2px;
        position: absolute;
        right: 8px;
        padding: 14px 26px 14px 26px;
        height: 56px;
        width: 56px;
        z-index: 98;
        @media (min-width: $tablet) {
            &:hover {
                background-color: rgba(43, 74, 100, 0.05);
                border-radius: 200px;
                padding: 14px 26px 14px 26px;
            }
        }
        @media (max-width: $tablet) {
            margin-top: 0px;
            top: 0px;
            right: 0px;
        }
    }

    .footer {
        padding-top: $gutter;
        padding-bottom: $gutter;
        text-align: center;
        font-size: $fontSizeSm;
        font-style: italic;
        color: $primaryColor;
        z-index: 1;

        span:before {
            content: attr(data-content-mobile);

            @media (min-width: $desktop) {
                content: attr(data-content-desktop);
            }
        }
    }

    .IOSFooter {
        margin-bottom: env(safe-area-inset-bottom) !important;
        padding-bottom: 0px !important;
    }

    .swiperSection {
        flex-grow: 1;
        flex-shrink: 0;
        position: relative;
        display: flex;
    }

    .introSection {
        flex-grow: 1;
        flex-shrink: 0;
        position: relative;
        display: flex;
    }
}

.ToastContainer{
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
    position: absolute;
    
    z-index: 90;
    width: 100%;
    @media (min-width: $desktop) {        
        align-items: center;
    }
 
}

.RemovedQuestionToast{
    display: flex;
    position: -webkit-sticky;
    position: sticky;    
    bottom: 16px;
    margin: 16px 16px 16px 16px;
    margin-bottom: calc(env(safe-area-inset-bottom) + 16px);
    
    padding-right: 32px;
    border-radius: 4px;
    align-items: center;
    background-color: var(--sanjuan);
    width: unset;
    opacity: 1;
    -webkit-animation: cssAnimation 3s forwards; 
    animation: cssAnimation 3s forwards;
    

    @media (min-width: $desktop) {
        height: 40px;
        border-radius: 40px;
        margin-bottom: 32px;
    }
}

.Hidden{
    opacity: 0!important;
    animation: none!important;
}

