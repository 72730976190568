@import '../../../style/styles.scss';

.CreateAccountForm {
    --color-form-label: #1b1c1d;
    --color-form-input-bg: #ffffff;
    --color-form-input-border: #dee7ec;
    --color-form-input-border-error: #f48e75;
    --color-form-input-border-active: #2b4a64;
    --color-form-hint: #2F2F37;
    --color-form-error: #c43f17;
    --color-link: #2b4a64;
    --color-terms: #717179;
    --radius-form-input: 8px;
}

.CreateAccountForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    a {
        text-decoration: underline;
        &:hover,
        &:focus,
        &:active,
        &:visited {
            color: var(--color-link);
        }
    }

    form {
        max-width: 311px;
        display: flex;
        justify-content: center;
        flex-direction: column;

        .createAccountButton {
            margin-bottom: 56px;
            min-height: 56px;
        }
    }
}

.HasAccount {
    display: none;
}

.Form__Info {
    color: var(--color-terms);
    text-align: center;
    font-size: 0.875rem;
    margin: 48px 0;
}

.Form__Terms {
    margin-bottom: 16px;
}

.Form__Actions {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}

// Form controls
.Form__Field {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
    position: relative;

    input {
        background: var(--color-form-input-bg) 0% 0% no-repeat padding-box;
        padding: 12px;
        margin-bottom: 2px;
    }

    & > span {
        height: 15px;
        margin-bottom: 4px;
        letter-spacing: 0;
        font-size: 0.688rem;
        opacity: 0.7;
    }
}

.Form__Label {
    text-align: left;
    letter-spacing: 0.34px;
    color: var(--color-form-label);
    font-size: 0.875rem;
    font-weight: bold;
    margin-bottom: 2px;
}

.Form__Input {
    border: 2px solid var(--color-form-input-border);
    border-radius: var(--radius-form-input);
    min-width: 311px;
    min-height: 48px;

    &:hover,
    &:active,
    &:focus {
        outline: none;
        border: 2px dashed var(--color-form-input-border-active);
    }
}

.Form__Input__Error {
    border: 2px solid var(--color-form-input-border-error);
    border-radius: var(--radius-form-input);
    min-width: 311px;
    min-height: 48px;

    &:hover,
    &:active,
    &:focus {
        outline: none;
        border: 2px dashed var(--color-form-input-border-error);
    }
}

.Form__Placeholder {
    color: var(--color-form-hint);
}

// Error styles
.Form__Error__Show {
    color: var(--color-form-error);
}

.Form__Error__Hidden {
    visibility: hidden;
}

.Form__PassToggle__Hidden {
    position: absolute;
    top: 36px;
    right: 12px;
    background-color: transparent;
    border: none;
    outline: none;
    margin: 0;
    padding: 0;
}

.Form__PassToggle__Reveal {
    position: absolute;
    top: 32px;
    right: 11px;
    background-color: transparent;
    border: none;
    outline: none;
    margin: 0;
    padding: 0;
}

.ButtonLink {
    cursor: pointer;
    border: none;
    background-color: transparent;
    color: var(--color-link);
    text-decoration: underline;
    margin: 0;
    padding: 0;
    font-size: inherit;
}

@media (max-width: $tablet) {
    .Steps {
        display: none;
    }

    .HasAccount {
        display: inline;
        margin: 24px 0 12px 0;
        font-size: 0.875rem;
    }

    .CreateAccountForm {
        justify-content: flex-start;

        form {
            min-width: 100%;
            padding: 32px 36px;
            justify-content: flex-end;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 32px 32px 0 0;
            background-color: var(--color-form-input-bg);
        }
    }
}
