@import '../..//style/styles.scss';

.ErrorPage {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    background-image: url('./../error-page/images/bg-mobile-error.svg');
    background-repeat: no-repeat;
    background-position-y: bottom;
    background-size: 100% auto;
    background-attachment: fixed;

    @media (min-width: $desktop) {
        background-image: url('./../error-page/images/bg-desktop-error.svg');
    }

    .Title{        
        margin-top: 160px;
        margin-bottom: 40px;        
        @media (min-width: $desktop) {
            margin-top: 0px;
        }
    }

    .Container {
        position: relative;
        max-width: 500px;
        margin: 0 auto;        
        display: flex;
        flex-direction: column;        
        line-height: 1.5;
        text-align: center;

        @media (min-width: $desktop) {
            justify-content: center;
            margin: auto;   
        }

        .Button {
            margin-top: 40px;
            margin-left: auto;
            margin-right: auto;
            background: $white;
            min-width: 200px;
        }

    }
}