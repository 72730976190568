@import '../../style/styles.scss';

.MyLibraryPageContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 10;

    @media (max-width: $tablet) {
        align-content: flex-start;
        width: 100%;
    }
    @media (min-width: $tablet) {
        margin: 136px 0px 0px 0px;
        padding: 0px 30px 40px 30px;
    }
}

.BothFavouritesContainer {
    flex-wrap: nowrap;
    display: flex;
}

.noFavouritesContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    @media (min-width: $tablet) {
        justify-content: center;
    }
}
.noFavouritesText {
    text-align: center;
    margin-bottom: 40px;
    font-style: italic;
    color: #717179;

    @media (max-width: $tablet) {
        max-width: 214px;
        margin-top: 25vh;
    }
}

.noFavouritesButton {
    max-width: 218px;
    min-height: 56px;
}

.MyLibraryPageContainerQuestionsOnlyMobile {
    display: flex;
    flex-direction: column;
    position: relative;

    z-index: 10;
    display: flex;

    @media (max-width: $tablet) {
        padding: 40px 23px 40px 22px;
        align-content: flex-start;
        width: 100%;
    }
    @media (min-width: $tablet) {
        margin: 136px 0px 0px 16px;
        padding: 0px 30px 40px 30px;
    }
}

.MyFavouritesTitle {
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 40px;
    @media (min-width: $tablet) {
        margin-left: 8px;
        
    }
}

.myFavouritesButton {
    width: 100%;
    align-items: center;
    border-radius: 16px;
    border-width: 2px !important;
    border: solid #dee7ec;
    display: flex;
    height: 96px;
    margin-bottom: 40px;
    margin-top: 40px;
    width: 330px;
    max-width: 330px;
    @media (min-width: $tablet) {
        padding: 10px 10px 10px 0px;
        max-width: 482px;
        width: 470px !important;
        height: 96px !important;
        display: flex !important;
        flex-direction: row !important;
    }
    @media (min-width: $desktop) {
        margin-bottom: 72px;
    }
}

.BothFavouritesContainer :first-child.bothMyFavouritesButton{
    margin-right: 16px;
    @media (min-width: $tablet) {
        margin-right: 40px;
    }
}

.bothMyFavouritesButton {
    width: 150px;
    height: 170px;
    flex-direction: column;
    // margin-right: 8px;
    // margin-left: 8px;
    margin-bottom: 32px;
    place-content: center;
    div{
        margin-left: 0px;
    }
    @media (min-width: $tablet) {
        place-content: unset!important;
        div{
            // margin-left: 8px;
        } 
    }
    @media (min-width: $desktop) {
        margin-bottom: 72px;
    }
    
}

.IconContainer{
    display: flex;
    justify-content: center;
    width: 88px;
}

.redHeartIcon {
    align-items: center;
    background-image: url('../../images/icons/red-heart.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 90%;
    display: flex;
    flex-direction: column;
    height: 1.5rem;
    justify-content: center;
    // margin-left: 28px;
    width: 26px;
    @media (min-width: $tablet) {
        // margin: 0px 31px 0px 21px;
    }
}

.conversationIcon {
    align-items: center;
    background-image: url('../../images/icons/conversation.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 90%;
    display: flex;
    flex-direction: column;
    height: 1.5rem;
    justify-content: center;
    // margin-left: 28px;
    width: 26px;
    @media (min-width: $tablet) {
        // margin: 0px 31px 0px 21px;
    }
}

.header{
    display: flex;
    justify-content: center;
}

.ConversationCardsOuterContainer{
    display: flex;
    justify-content: center;
    width: 330px;
    @media (min-width: 500px ) {
        width: 495px;
    }
    @media (min-width: 670px ) {        
        width: 660px;
    }
    @media (min-width: 920px ) {
        width: 825px;
    }
    @media (min-width: 1045px ) {
        width: 995px;
    }
}

.ConversationCardsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 80px;
    @media (max-width: $tablet) {
        justify-content: left;
    }
}

.FavouriteConversationCard {
    margin-bottom: 15px;
    margin-right: 7.5px;
    margin-left: 7.5px;
    width: 150px;
    &:hover {
        cursor: pointer;
    }
}

.userCreatedConversation1 {
    background-image: linear-gradient(#fce8b3, #f8bbac);
}
.userCreatedConversation2 {
    background-image: linear-gradient(#eef5f5, #d1e4d0);
}
.userCreatedConversation3 {
    background-image: linear-gradient(#fdeae6, #ebdbeb);
}

.myFavouritesButtonTextContainer {
    margin-left: 1.5rem;
}
.bothMyFavouritesButtonTextContainer {
    text-align: center;
    margin-left: 0px;
    margin-top: 16px;
    @media (min-width: $tablet) {
        margin-top: 0px;
        text-align: start;
    }
}

.mainMyFavouritesText {
    font-size: 18px;
    font-weight: bold;
}
.numberOfQuestions {
    color: #717179;
    font-size: 14px;
}
.bothNumberOfQuestions {
    margin-top: 4px;
    @media (min-width: $tablet) {
        margin-top: 0px;
    }
}

.FavouriteConversationsTitle {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 16px;
    width: 316px;
    @media (min-width: 500px ) {
        width: 482px;
    }
    @media (min-width: 670px ) {        
        width: 646px;
    }
    @media (min-width: 780px ) {
        width: 720px;
    }
    @media (min-width: 1045px ) {
        width: 995px;
    }

}
.NoFavouriteMobileConversations {
    color: #717179;
    font-size: 14px;
    font-style: italic;
    margin-top: 8px;
    text-align: center;
}

.NoFavouriteMobileConversationsQuestionsOnly {
    color: #717179;
    font-size: 14px;
    font-style: italic;
    margin: 48px 14px 40px 14px;
    text-align: center;
}

.NoFavouriteQuestions {
    margin-bottom: 40px;
}

.NoFavouriteMobileQuestions {
    color: #717179;
    font-size: 14px;
    font-style: italic;
    margin-top: 32px;
    padding-bottom: 114px;
    text-align: center;
}
.FavouriteQuestionsContainer {
    margin-top: 32px;
    width: 100%;
    @media (min-width: $tablet) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
}

.FavouriteHeartContainers {
    background-color: #f7f3f1 !important;
}
.ResultContainer {
    @media (max-width:$tablet) {
        display: flex;
        flex-direction: column;
    }
    @media (min-width: $tablet) {
        margin: 0 auto;
        width: 736px;
    }
    @media (min-width: $desktop) {
        margin: 0 auto;
        width: unset;
    }
}
