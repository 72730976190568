@import '../../../../style/styles.scss';

.logo {
    width: auto !important;

    &.size {
        &--small {
            height: 12px !important;
        }

        &--medium {
            height: 16px !important;
        }

        &--large {
            height: 20px !important;
        }
    }
}
