@import '../../../../style/styles.scss';

.Benefits__List {
    list-style-type: none;
    text-align: left;
    margin: 0;
    padding: 0;
}

.Benefits__Item {
    margin-bottom: 8px;
    text-indent: -1.2em;

    &:last-of-type {
        margin-bottom: 0;
    }

    .Item__Text {
        font-weight: $weightMedium;
        margin-left: 4px;
    }
}

@media (max-width: $tablet) {
    .Benefits__List {
        margin: 0 16.7px;
        .Benefits__Item .Item__Text {
            font-size: 0.875rem;
        }
    }
}
