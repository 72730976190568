button.SelectQuestionBox {
    width: 100%;
    border-radius: 8px !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: white !important;
    color: #2F2F37 !important;    
    padding: 12px 4px 12px 16px!important;
    min-height: 58px;
    margin-bottom: 8px;
    max-height: none !important;
    height: auto !important;    
    border: white 1px solid!important;
    &:focus {
        background-image: none !important;
    }
    &:hover{              
        box-shadow: 0px 2px 4px 0px #E4D5CD;
    }

    .QuestionsTextContainer {
        min-height: 26px;
        line-height: 20px;
        text-align: start;
        font-weight: 400;
    }
    
    .QuestionBoxIcon{
        width: 50px; 
        min-width: 50px;
        max-width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        &:hover{        
          border-radius: 100px;
          align-self: center;
          background-color: rgba(50, 77, 103, 0.05)!important;
        }    

    .DeleteIcon {
        max-width: 13px;
        max-height: 16px;
        margin: 17px;
    }
    .CheckIcon {
        min-width: 19px;
        min-height: 19px;    
        margin: 15px;
    }
    .AddIcon{
        min-width: 15px;
        min-height: 15px;
        margin: 17px;
    }
}
}

button.SelectQuestionBox.Selected {
    background-color: #f4f6f7 !important;
    border: solid 1px #556e83 !important;        
}
button.SelectQuestionBox.EditingNotSelected{
    font-weight: 300!important;

    color: var(--jumbo)!important;
    font-style: italic!important;
}

button.SelectQuestionBox.EditingSelected{
    font-weight: 400
}
