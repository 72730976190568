@import '../../style/styles.scss';

.MyConversationInfoPageContainer {
    height: 100% !important;
    margin-top: env(safe-area-inset-top);
    position: relative;    
}

.bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% + 60px);
    pointer-events: none;
    z-index: -1;
    background-color: var(--pampas);
    background-size: cover;
}


.MyConversationInfoPageOuterContainer{
    margin-left:20px;
    margin-right:20px;

    @media (min-width: $tablet) {
        align-items: center;
        display: flex;
        flex-direction: column;;
    }  

}
.Container{
    @media (min-width: $tablet) {        
        min-width: 700px;
    }
    @media (min-width: $desktop) {        
    max-width: 980px;
    min-width: 980px;
    }
}


.ContentContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    padding-top: 40px;

    .InnerContentContainer{
        align-items:flex-start;
        width: 291px;
        
    }
}

.QuestionBoxContainer{
    @media (min-width: $tablet) {
    columns: 2 auto;
    }
    
 
}
.BackButtonWhiteContainer{
    background: rgba(247, 243, 241, 0.9); 
}

.BackButtonContainer {
    width: 100%;
    position: sticky;
    top: 0px;
    z-index: 100;   
}

.BackButton {
    padding: 8px 16px 8px 2px;
    color: #2b4a64;
    font-family: $fontFamily;
    margin-bottom: 8px;
    font-size: 16px;
    background: none !important;
    border: none;
    cursor: pointer;
    margin-top: 40px;
    margin-left: 20px;
    text-decoration: none;
    
    @media (min-width: $tablet) {
        margin-bottom: 8px;
        margin-left: 5px;
        padding: 8px 16px 8px 16px;
    }
    @media (min-width: $desktop) {
        padding: 10px 15px;
        margin-left: 40px;
        &:focus,
        &:hover {
            background:rgba(50, 77, 103, 0.05) !important;
            border-radius: 60px !important;
        }
    }

  
}

.LeftArrow {
    margin-right: 8px;
}

.title {    
    margin-bottom: 40px;
    font-weight: var(--weightBold);
}

.EditNameAndDescriptionContainer{
 
    margin-right: 32px;
    margin-left: 32px;
    .FieldContainer{
        display: flex;
        flex-direction: column;    
    }
    .InvalidInput {
        border: #f48e75 1px solid !important;
    }

    input,
    textarea {
        border: #e3d4ce 1px solid;
        border-radius: 8px;
    }

    .TitleLabel {
        font-size: 14px;
        font-weight: $weightMedium;
    }

    .titleField,
    .descriptionField {
        min-width: 296px;
        padding: 12px 16px;
        font-family: $fontFamily;
        font-size: 16px;
    }

    .titleField {
        min-height: 48px;
    }

    .DescriptionLabel {
        margin-top: 8px;
        font-size: 14px;
        font-weight: $weightMedium;
    }
    .descriptionField {
        height: 86px;
        resize: none;
    }
    .descriptionField:focus-visible {
        outline: 0;
    }
    .Counter {
        margin-top: 2px;
        display: flex;
        justify-content: flex-end;
        color: #717179;
    }
    .ButtonContainer {
        display: flex;
        justify-content: center;
        .SaveButton {
            margin-top: 51px;
            max-width: 218px;
            max-height: 56px;
        }
    }
}

.QuestionSelectTitleWrapper{
    margin-right: 16px;
    margin-bottom: 40px;
}

.QuestionSelectTitle, .QuestionSelectTitleCounter{    
    font-size: 1.5rem;
    line-height: 2rem;
    font-size: 24px;
    font-family: var(--fontFamily);
    font-weight: var(--weightBold);
}

.QuestionSelectTitleCounter{
    font-weight: var(--weightMedium);
}

.userCreatedConversation1 {
    background-image: linear-gradient(#fce8b3, #f8bbac)!important;
}
.userCreatedConversation2 {
    background-image: linear-gradient(#eef5f5, #d1e4d0);
}
.userCreatedConversation3 {
    background-image: linear-gradient(#fdeae6, #ebdbeb);
}


.customRadios {
    margin-top: 40px;
    margin-bottom: 80px;
    div {
        display: inline-block;
    }

    div:last-of-type button {
        margin-right: 0px;
    }
    div:last-of-type label button {
        margin-right: 0px !important;
    }

    input[type='radio'] {
        display: none !important;

        + label {
            button {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 40px;
                height: 40px;
                margin: -1px 4px 0 0;
                vertical-align: middle;
                cursor: pointer;
                border-radius: 8px;
                border: 1px solid #99adbf;
                margin-right: 16px;
                background-repeat: no-repeat;
                background-position: center;
                text-align: center;
                line-height: 44px;
                .IconCheck {
                    opacity: 0;
                    transition: all 0.3s ease;
                    width: 23px;
                    margin-right: 2px;
                }
            }
        }

        &:checked + label button {
            border: 2px solid #2b4a64;
        }

        &.color1 + label button {
            background: linear-gradient(#fce8b3, #f8bbac) left top no-repeat;
        }

        &.color2 + label button {
            background: linear-gradient(#eef5f5, #d1e4d0) left top no-repeat;
        }

        &.color3 + label button {
            background: linear-gradient(#fdeae6, #ebdbeb) left top no-repeat;
        }

        &:checked + label button .IconCheck {
            opacity: 1;
        }
    }
}

.PublicIconAndTitle{
    display: flex;
    align-items: center;
}

.PublishOption{
    flex-direction: column;
    align-items: flex-start!important;
}

.PublishInfoText{
    margin-left: 32px;
    font-size: 13px;
    font-style: italic;
    color: var(--jumbo);
    line-height: 24px;
    font-weight: var(--weightLight);
}

.option{
    font-size: 16px;
    font-weight: var(--weightMedium);
    font-family: var(--fontFamily);
    color:  var(--sanjuan);
    display: flex;
    align-items: center;
    padding: 13px 24px 13px 16px;
    margin-bottom: 16px;
}


.EditIcon {
    margin-right: 13px;
    margin-left: 1px;
    width: 16px;
    height: 16px;
}

.ConversationIcon{
    margin-right: 13.43px;
    width: 17.57px;
    height: 14px;
}
.PublicIcon{
    margin-right: 14px;
    margin-left: 1px;
    width: 16px;
    height: 16px;
}

.PrivateIcon{
    width: 12.19px;
    height: 16px;
    margin-right: 14.91px;
    margin-left: 3px;
}

.ShareIcon{
    width: 14.55px;
    height: 20px;
    margin-right: 14.73px;
}
.DeleteIcon{
    margin-right: 15.78px;
    width: 12.44px;
    height: 16px;
    margin-left: 3px;
}

.SelectingQuestionsButtonContainer {
    background: linear-gradient(0deg, #f7f3f1 0, rgba(40, 40, 40, 0) 100%);
    position: sticky;
    bottom: 0px;
    height: 96px;
    .ConfirmButton {
        margin-bottom: calc(env(safe-area-inset-bottom) + 24px);
        margin-top: 16px;
    }
}

.ToastContainer{
    display: flex;
    flex-direction: column-reverse;
    height: 100vh;
    
    position: absolute;    
    width: 100%;
    z-index: -1;
    @media (min-width: $desktop) {        
        align-items: center;
    }
 
}

.RemovedQuestionToast{
    display: flex;
    position: -webkit-sticky;
    position: sticky;    
    bottom: 16px;
    margin: 16px 16px 16px 16px;
    
    padding-right: 32px;
    border-radius: 4px;
    align-items: center;
    background-color: var(--sanjuan);
    width: unset;
    opacity: 1;
    -webkit-animation: cssAnimation 3s forwards; 
    animation: cssAnimation 3s forwards;

    @media (min-width: $desktop) {
        height: 40px;
        border-radius: 40px;
    }
}

.Hidden{
    opacity: 0!important;
    animation: none!important;
}

@keyframes cssAnimation {
    0%   {opacity: 1;}
    90%  {opacity: 1;}
    100% {opacity: 0;}
}
@-webkit-keyframes cssAnimation {
    0%   {opacity: 1;}
    90%  {opacity: 1;}
    100% {opacity: 0;}
}

.EmptyStateContainer{
    display: flex;
    flex-direction: column;    
    justify-content: center;
    align-items: center;
    @media (min-width: $desktop) {        
        height: calc(100vh - 202px) ;
    }
}

.options{
    margin-top: 40px;    
}

.option{
    font-size: 16px;
    font-weight: var(--weightMedium);
    font-family: var(--fontFamily);
    color:  var(--sanjuan);
    display: flex;
    align-items: center;
    padding: 13px 24px 13px 16px;
    margin-bottom: 16px;
    cursor: pointer;
}

.icon {
    margin-right: 13px;
    margin-left: 1px;
    width: 16px;
    height: 16px;
}

.ButtonContainer {
    display: flex;
    justify-content: center;
    background: linear-gradient(0deg, #f7f3f1 0, rgba(40, 40, 40, 0) 100%);
    position: sticky;
    bottom: 0px;
    height: 96px;
    width: 100%;    
    .ConfirmButton{
        border-radius: 24px;
    }
}
