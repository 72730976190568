@import './../../style/styles.scss';

.homePage {
    margin-bottom: $gutter * 3;

    @media (min-width: $tablet) {
        margin-bottom: 0;
    }
}

.conversationRow {
    margin-bottom: $gutter * 3;

    @media (min-width: $tablet) {
        margin-bottom: $gutter * 3;
    }
    @media (min-width: $desktop) {
        margin-bottom: $gutter * 5;
    }
}

.timelyTopics {
    margin-bottom: $gutter * 3;

    @media (min-width: $tablet) {
        margin-bottom: $gutter * 3;
    }
    @media (min-width: $desktop) {
        margin-bottom: $gutter * 5;
    }
}

.userCreatedConversation1 {
    background-image: linear-gradient(#fce8b3, #f8bbac);
}
.userCreatedConversation2 {
    background-image: linear-gradient(#eef5f5, #d1e4d0);
}
.userCreatedConversation3 {
    background-image: linear-gradient(#fdeae6, #ebdbeb);
}