@import '../../style/styles.scss';
.languageFooter {
    white-space: nowrap;
    display: flex;
    bottom: 0px;
    width: 100%;
    background-color: #f4f6f7;
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 9px;

    @media (min-width: $tablet) {
        justify-content: center;
    }
    @media (max-width: $tablet) {
        padding-left: 16px;
    }

    .languageFooterText {
        margin-left: 4px;
        margin-right: 8px;
    }
    .link {
        color: #2b4a64;
        text-decoration: underline;
        cursor: pointer;
    }
}
