@import '../../style/styles.scss';
.QuestionInfoPageContainer {
    min-height: 100%;
    margin-top: env(safe-area-inset-top);
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% + 60px);
    pointer-events: none;
    z-index: -1;
    background-color: var(--brown-95);    
    background-size: cover;
}

.AddQuestionToConversationContainer {
    height: 100% !important;
    margin-top: env(safe-area-inset-top);
    position: relative;    
}

.BackButtonContainer {
    
    margin-left: 16px;
    padding-top: 40px;
    width: 100%;
    margin: 0 auto;
    position: sticky;
    top: 0px;
    z-index: 100;
    background: rgba(246, 241, 238, 0.9);

    
    @media (min-width: $tablet) {
        max-width: 694px;
    }
    @media (min-width: $desktop) {
        max-width: unset!important;        
        margin-left: 40px;
    }
}

.backButton {
    margin-left: 17px;   
    margin-top: calc(env(safe-are-inset-top) + 40px); 
    @media (min-width: $tablet) {        
        margin-left: 1px;
    }
}

.ContentContainer {
    display: flex;
    align-items: center;
    flex-direction: column;

    .OptionsContainer {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    
        .OptionContainer {
            font-size: 16px;
            color: #2b4a64;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-right: 30px;
            cursor: pointer;
            border: none!important;
            
        }
        
        div.OptionContainer:focus {
            border: none !important;
        }
        
        div.OptionContainer:focus-visible {
            border: none !important;
        }
        
    }
}

.ContentContainerShared {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
}

.QuestionSection {
    max-width: 500px;
    font-size: 24px;
    color: #2b4a64;
    font-weight: 600;
    text-align: center;
    margin-left: 58px;
    margin-right: 58px;
    @media (min-width: $tablet) {
        font-size: 40px;
        max-width: 650px;
    }
    @media (min-width: $desktop) {
        max-width: 800px;
    }
}

.FavouriteSection {
    margin-top: 80px;
    font-size: 16px;
    color: #2b4a64;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 30px;
}


.IconContainer:focus {
    border: none !important;
}

.IconContainerContainer {
    border-radius: 0px 8px 8px 0px;
    display: flex;
    align-content: center;
}
.IconContainer {
    align-items: center;
    outline: none;
    display: flex;
    justify-content: center;
    min-height: 50px !important;
    min-width: 50px !important;
    cursor: pointer;
}
.SharingSection {
    margin-top: 4px;
    font-size: 16px;
    color: #2b4a64;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 30px;
}


.SharingSectionContainer {
    align-items: center;
    border: none;
    display: flex;
    justify-content: center;
    min-height: 50px !important;
    min-width: 50px !important;
    cursor: pointer;
}

.outLineHeartIcon {
    background-image: url('../../images/icons/heart-outline.svg');
    background-position: center;
    background-repeat: no-repeat;
    color: #2b4a64;
    margin-top: 2px;
    margin-left: 1px;
    min-height: 18.35px !important;
    min-width: 20px !important;
    z-index: 99;
}
.ShareIcon {
    background-image: url('../../images/icons/icon-share.svg');
    background-position: center;
    background-repeat: no-repeat;
    color: #2b4a64;
    min-height: 18px !important;
    min-width: 22px !important;
    z-index: 99;
}

.redHeartIcon {
    background-image: url('../../images/icons/red-heart.svg');
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 2px;
    margin-left: 1px;
    min-height: 18.35px !important;
    min-width: 20px !important;
    z-index: 99;
}

.addIcon {
    background-image: url('../../images/icons/addIcon.svg');
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 2px;
    margin-left: 1px;
    min-height: 18.35px !important;
    min-width: 20px !important;
    z-index: 99;
}

.AddToFavouritesText {
    outline: none;
    padding: 10px 0px 10px 0px;
    cursor: pointer;
}

.favouriteHeart {
    -webkit-tap-highlight-color: transparent;
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 26px;
    top: 8px;
    padding: 8px;
    margin-top: 2px;
    position: absolute;
    right: 64px;
    height: 56px;
    width: 56px;
    z-index: 100;
    .redHeartIcon,
    .redHeartIconOutline {
        width: 17.4px;
        height: 16px;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
        z-index: 99;
    }
    .redHeartIcon {
        background-image: url('../../images/icons/red-heart.svg');
    }
    .redHeartIconOutline {
        background-image: url('../../images/icons/heart-outline.svg');
    }
    @media (min-width: $tablet) {
        &:hover {
            background-color: rgba(43, 74, 100, 0.05);

            border-radius: 200px;
            padding: 14px 26px 14px 26px;
        }
    }
    @media (max-width: $tablet) {
        margin-top: 0px;
        top: 0px;
        right: 40px;
    }
}
.moreVert {
    -webkit-tap-highlight-color: transparent;
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 26px;
    top: 8px;
    padding: 8px;
    margin-top: 2px;
    position: absolute;
    right: 8px;
    padding: 14px 26px 14px 26px;
    height: 56px;
    width: 56px;
    z-index: 100;
    @media (min-width: $tablet) {
        &:hover {
            background-color: rgba(43, 74, 100, 0.05);
            border-radius: 200px;
            padding: 14px 26px 14px 26px;
        }
    }
    @media (max-width: $tablet) {
        margin-top: 0px;
        top: 0px;
        right: 0px;
    }
}

.headerContainer{
    margin-left: 32px;
    h2{
        font-size: 24;
        font-weight: 700;
        margin-bottom: 40px;
        @media (min-width: $tablet) {
            margin-left: 16px
        }
    }
    @media (min-width: $tablet) {
        margin: 0 auto;
        max-width: 694px;
    }
    @media (min-width: $desktop) {
        max-width: unset!important;        
        margin-left: 39px;
    }
}

.ConversationCardsContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
    @media (min-width: $desktop) {
        
        justify-content: center;
        margin-left: 40px;        
    }
}

.ConversationCardsInnerContainer{
    max-width: 340px;
    display: flex;
    flex-wrap: wrap;    
    justify-content: flex-start;
    margin-bottom: calc(env(safe-area-inset-bottom) + 80px);
    
    @media (min-width: $tablet) {
        max-width: 684px;
    }
    @media (min-width: $desktop) {
        max-width: 1000px;        
        align-self: flex-start;
    }
    
}

.ConversationCard{
    min-width: 150px;
    height: 140px;
    margin: 8px;
}

.userCreatedConversation1 {
    background-image: linear-gradient(#fce8b3, #f8bbac)!important;
}
.userCreatedConversation2 {
    background-image: linear-gradient(#eef5f5, #d1e4d0);
}
.userCreatedConversation3 {
    background-image: linear-gradient(#fdeae6, #ebdbeb);
}


.ToastContainer{
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
    position: absolute;    
    width: 100%;
    @media (min-width: $desktop) {        
        align-items: center;
    }
 
}

.ToastMessage{
    display: flex;
    position: -webkit-sticky;
    position: sticky;    
    bottom: 16px;
    margin: 16px 16px 16px 16px;
    
    padding-right: 32px;
    border-radius: 4px;
    align-items: center;
    background-color: var(--sanjuan);
    width: unset;
    opacity: 1;
    // -webkit-animation: cssAnimation 3s forwards; 
    // animation: cssAnimation 3s forwards;

    @media (min-width: $desktop) {
        height: 40px;
        border-radius: 40px;
    }
}

.Hidden{
    opacity: 0!important;
    animation: none!important;
}

@keyframes cssAnimation {
    0%   {opacity: 1;}
    90%  {opacity: 1;}
    100% {opacity: 0;}
}
@-webkit-keyframes cssAnimation {
    0%   {opacity: 1;}
    90%  {opacity: 1;}
    100% {opacity: 0;}
}