@import '../../../style/styles.scss';

.DeckModal {
    --height-bg: calc(100% + 60px);
    --bg-gradient-mobile-deck-modal: var(--bg-gradient-mobile);
    --bg-gradient-desktop-deck-modal: var(--bg-gradient-desktop);
    --gutter-deck-modal: var(--gutter);
    --font-size-deck-modal: var(--normal);
    --color-deck-modal: var(--sanjuan);
    --margin-top-footer: 13px;
    --padding-bottom-footer: 24px;
    --font-size-footer: 13px;
}

.DeckModal {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex !important;
    flex-direction: column;


    .Bg {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(100% + 60px);
        pointer-events: none;
        background-image: linear-gradient(#FFFFFF, #E3D4CE);    
        background-size: cover;
        
    }

    .BgBubbles {
        height: var(--height-bg);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        pointer-events: none;

        background-image: url('./images/bg-mobile-deck-modal.svg'),
            var(--bg-gradient-mobile-deck-modal);
        background-size: cover;

        @media (min-width: 1048px) {
            background-image: url('./images/bg-desktop-deck-modal.svg'),
                var(--bg-gradient-desktop-deck-modal);
        }        
    }

    
    .favouriteHeartIntro {
        right: 8px !important;
    }
    .favouriteHeart {
        -webkit-tap-highlight-color: transparent;
        align-items: center;
        background-position: center;
        background-repeat: no-repeat;
        background: none;
        border: none;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-width: 26px;
        top: 8px;
        padding: 8px;
        margin-top: 2px;
        position: absolute;
        right: 64px;
        height: 56px;
        width: 56px;
        z-index: 98;
        .redHeartIcon,
        .redHeartIconOutline {
            width: 17.4px;
            height: 16px;
            background-position: center;
            background-repeat: no-repeat;
            cursor: pointer;
            z-index: 99;
        }
        .redHeartIcon {
            background-image: url('../../../images/icons/red-heart.svg');
        }
        .redHeartIconOutline {
            background-image: url('../../../images/icons/heart-outline.svg');
        }
        @media (min-width: $tablet) {
            &:hover {
                background-color: rgba(43, 74, 100, 0.05);

                border-radius: 200px;
                padding: 14px 26px 14px 26px;
            }
        }
        @media (max-width: $tablet) {
            margin-top: 0px;
            top: 0px;
            right: 40px;
        }
    }
    .moreVert {
        -webkit-tap-highlight-color: transparent;
        align-items: center;
        background-position: center;
        background-repeat: no-repeat;
        background: none;
        border: none;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-width: 26px;
        top: 8px;
        padding: 8px;
        margin-top: 2px;
        position: absolute;
        right: 8px;
        padding: 14px 26px 14px 26px;
        height: 56px;
        width: 56px;
        z-index: 98;
        @media (min-width: $tablet) {
            &:hover {
                background-color: rgba(43, 74, 100, 0.05);
                border-radius: 200px;
                padding: 14px 26px 14px 26px;
            }
        }
        @media (max-width: $tablet) {
            margin-top: 0px;
            top: 0px;
            right: 0px;
        }
    }
    .Footer {
        padding-top: $gutter;
        padding-bottom: $gutter;
        text-align: center;
        font-size: $fontSizeSm;
        font-style: italic;
        color: $primaryColor;
        z-index: 0;

        span:before {
            content: attr(data-content-mobile);

            @media (min-width: 1048px) {
                content: attr(data-content-desktop);
            }
        }
    }

    .IOSFooter {
        margin-bottom: env(safe-area-inset-bottom) !important;
        padding-bottom: 0px !important;
        z-index: 0;
    }

    .SwiperSection {
        flex-grow: 1;
        flex-shrink: 0;
        position: relative;
        display: flex;
    }

    .IntroSection {
        flex-grow: 1;
        flex-shrink: 0;
        position: relative;
        display: flex;
    }
}
