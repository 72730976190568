@import '../../style/styles.scss';

.Loading {
  --color-dots-active: #2b4a64;
  --color-dots-base: #dee7ec;
}

.Loading {
  background-image: url('../../images/bg-desktop-speechbubbles.svg');
  background-repeat: no-repeat;
  background-position-x: right;
  background-size: cover;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.Bubbles {
  height: 400px;
  width: 100%;
  max-width: 150px;
  padding: 0 5px;
  position: relative;
}

.Bubbles > * {
  position: absolute;
  & > * {
    margin: 9px 0;
  }
}

.FirstBubble {
  text-align: center;
  z-index: 100;
  .Dots {
    position: absolute;
    top: -10px;
    left: 34%;
  }
}

.ThirdBubble {
  right: 0;
}

@media (max-width: $tablet) {
  .Loading {
    background-image: none;
  }
}
