@import '../../style/styles.scss';

$borderColor: #dee7ec;

.group {
    display: flex;
    flex-wrap: wrap;
    padding-top: 48px;
    flex-basis: 100%;
    margin-right: -$gutter;

    &:last-of-type {
        padding-bottom: 48px;
    }

    .title {
        font-size: 18px;
        flex-basis: 100%;
        font-weight: 700;
    }
}

.subgroup {
    display: flex;
    flex-wrap: wrap;
    padding-top: 8px;
    padding-bottom: 48px;
    flex-basis: 100%;

    &:last-of-type {
        padding-bottom: 0;
    }

    .title {
        font-size: 18px;
        flex-basis: 100%;
        font-weight: normal;
    }
}
