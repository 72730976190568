@import '../../style/styles.scss';

.container {
    position: relative;
    margin: 0 auto;

    @media (min-width: $tablet) {
        width: $tablet - $gutter * 2;
    }

    @media (min-width: $desktop) {

        &.medium {
            width: $desktop - $gutter * 2;
        }

        &.small {
            width: 650px;
        }

        &.xs {
            width: 360px;
        }
    }
}