@import '../../style/styles.scss';

$headerHeightMobile: 48px;
$headerHeightDesktop: 96px;

.headSpace {
    height: $headerHeightMobile;

    @media (min-width: $tablet) {
        height: $headerHeightDesktop;
    }
}
