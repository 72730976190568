@import '../../style/styles.scss';
.favouriteQuestionCardContainer {
  border-radius: 8px;
  margin-bottom: 8px;
  display: flex;
  &:hover{              
      box-shadow: 0px 2px 4px 0px #E4D5CD;
    }
  @media (min-width: $tablet) {
    margin: 0px 8px 8px 0px;
    max-width: 314px;
    min-width: 314px;
  }
  .dark {
    background-color: #f7f3f1 !important;
  }
  .bright {
    background-color: white !important;
  }
}
.favouriteQuestionCard {
  background-color: #f7f3f1;
  border-radius: 8px 0px 0px 8px;
  border: none;
  color: $primaryTextColor;
  cursor: pointer;
  display: flex;
  font-family: $fontFamily;
  font-size: $fontSize;
  font-weight: 500;
  justify-content: space-between;
  letter-spacing: 0px;
  min-height: 56px;
  outline: none;
  text-align: left;
  width: 100%;
  z-index: 98;
}

.favouriteQuestionText {
  align-self: center;
  padding: 16px 12px 16px 16px;
}

.FavouriteHeartContainer:focus {
  border: none !important;
  outline: 0;
}
.FavouriteHeartContainerContainer {
  padding-right: 5px;
  border-radius: 0px 8px 8px 0px;
  display: flex;
  align-content: center;
  .dark {
    background-color: #f7f3f1 !important;
  }
  .bright {
    background-color: white !important;
  }
}
.FavouriteHeartContainer {
  align-items: center;
  background-color: white;
  border-radius: 0px 8px 8px 0px;
  border: none;
  display: flex;
  justify-content: center;
  min-height: 50px !important;
  min-width: 50px !important;
  width: 18px !important;
  cursor: pointer;

  @media (min-width: $tablet) {
    &:hover,
    &:hover + .favouriteQuestionText {
      border-radius: 100px;
      align-self: center;
      background-color: rgba(50, 77, 103, 0.05)!important;
    }
  }
}
.redHeartIcon {
  background-image: url('../../images/icons/red-heart.svg');
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 2px;
  margin-left: 1px;
  min-height: 16px !important;
  min-width: 18px !important;
  z-index: 99;
}
