.link {
    --color-link: #2b4a64;
    --color-link-highlight: #c43f17
}

.link {
    color: var(--color-link);
    text-decoration: none;

    &.block {
        display: block;
    }

    &.highlight {
        color: var(--color-link-highlight);
    }

    &.underline {
        text-decoration: underline;
    }
}