@import '../../../style/styles.scss';

$paginationHeight: 40px;
$navHeight: $paginationHeight;

.swiperContainer {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    padding: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;

    :global .swiper-wrapper {
        position: relative;
        width: 100%;
        z-index: 1;
        display: flex;
        transition-property: transform;
        box-sizing: border-box;
        flex: 1;
    }
}

.nextRandomButton {
    position: fixed;
    bottom: calc(env(safe-are-inset-bottom) + 48px);
    left: calc(50% - 101.3px);
    z-index: 2;

    &.disabled {
        display: none;
    }
}

.navButton {
    bottom: 0;
    height: $navHeight;
    background: unset;
    padding: $gutter / 2 $gutter;
    border: 0 !important;
    border-radius: $navHeight / 2;
    z-index: 99;

    span > svg {
        pointer-events: none;
        fill: $primaryColor;
    }

    &.next {
        left: calc(50% + 16px + 24px);

        span > svg {
            margin-left: $gutter * 0.5;
        }
    }

    &.prev {
        right: calc(50% + 16px + 24px);

        span > svg {
            margin-right: $gutter * 0.5;
        }
    }

    &.disabled {
        display: none;
    }
}

.swiperNavButtonDisabled {
    display: none !important;
}

.questionSwiperSlide {
    width: 100% !important;
}