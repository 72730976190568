@import '../../../style/styles.scss';

.recoverAccountConfirmationPage {
  display: flex;
  flex-direction: column;
  background-image: url('./../../confirmation-page/images/bg-mobile-confirmation.svg');
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-size: 100% auto;
  background-attachment: fixed;

  @media (min-width: $desktop) {
    background-image: url('./../../confirmation-page/images/bg-desktop-confirmation.svg');
  }

  .container {
    flex-grow: 1;
    position: relative;
    max-width: 300px;
    margin: 0 auto;
    padding: 48px 16px;
    display: flex;
    flex-direction: column;
    line-height: 1.5;
    text-align: center;    
    margin-top: calc(env(safe-area-inset-top) + 135px);

    .button {
      margin-top: $gutter * 2.5;
      margin-left: auto;
      margin-right: auto;
      background: $white;
      min-width: 200px;
      max-height: 56px;
    }
  }
}
