@import '../../style/styles.scss';

.SearchPageContainerClass {
    margin-top: env(safe-area-inset-top);
    @media (min-width: $tablet) {
        display: flex;
        justify-content: center;
    }
}

.SearchPageContainer {
    align-items: center;
    @media (min-width: $tablet) {
        display: flex;
        flex-direction: column;
    }
}
.SearchPageContainerIOS {
    margin-top: calc (env(safe-area-inset-top) -47px);
}

.SearchPageContainerIOS.StartPosition {
    margin-top: -47px;
}

.SearchPageContainer.StartPosition {
    background-attachment: fixed;
    background-image: url('../../images/bg-mobile-speechbubbles.svg');
    background-position-x: right;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (min-width: $tablet) {
        background-image: url('../../images/bg-desktop-speechbubbles.svg');
    }
}

.SearchPageContainer.EmptyResults {
    background-attachment: fixed;
    background-image: url('../../images/bg-mobile-speechbubbles.svg');
    background-position-x: right;
    background-repeat: no-repeat;
    background-size: cover;
    @media (min-width: $tablet) {
        background-image: url('../../images/bg-desktop-speechbubbles.svg');
    }
}

.NoResultsContainer {
    margin-top: 200px;
    text-align: center;
    padding-left: 24px;
    padding-right: 24px;
    color: #717179;
    font-style: italic;
}

.EmptyConversationsTextMobile {
    margin-left: 22px;
    margin-top: 30px;
    margin-bottom: -30px;
    color: #717179;
    font-style: italic;
}
.EmptyQuestionsTextMobile {
    margin-left: 22px;
    margin-top: 30px;
    margin-bottom: -30px;
    color: #717179;
    font-style: italic;
}

.EmptyConversationsTextDesktop {
    color: #717179;
    font-style: italic;
}
.EmptyQuestionsTextDesktop {
    color: #717179;
    font-style: italic;
}

.SearchTitleContainer {
    padding-top: 40px;
    margin-bottom: 16px;
    text-align: center;
    h2 {
        @media (min-width: $tablet) {
            font-size: 24px;
            font-weight: bold !important;
            padding-top: 136px;
        }
    }
}
.SearchTitleContainerIOS {
    padding-top: 80px;
}
.SearchTitleContainer.StartPosition {
    @media (min-width: $tablet) {
        position: relative;
        justify-content: center;
        margin-bottom: 16px;
    }
    margin-bottom: 0px;
    position: absolute;
    top: 0px;
}

.UpperSearchContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    margin-right: 24px;
    margin-left: 24px;
}

.DesktopResultContainer {
    @media (min-width: $tablet) {
        display: flex;
        flex-direction: row;
        align-self: flex-start;
        margin-top: 48px;
        margin-bottom: 80px;
    }
}

.DesktopContainerReducedTopMargin {
    @media (min-width: $tablet) {
        margin-top: 31px;
    }
}

.MidSearchContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    margin-right: 30px;
    margin-left: 30px;
    margin-top: 40px;

    @media (max-width: 371px) {
        margin-left: 20px;
        margin-right: 20px;
    }

    @media (min-width: $tablet) {
        align-items: flex-start;
        min-width: 330px;
        max-width: 330px;
        margin: 0px;
    }
    @media (min-width: $desktop) {
        min-width: 500px;
        max-width: 500px;
    }
}

.MidSearchContainerReducedTopMargin {
    margin-top: 23px;
}

.LowerSearchContainer {
    margin-right: 16px;
    margin-left: 16px;
    @media (min-width: $tablet) {
        // margin-top: 48px;
        margin: 0px 0px 0px 80px;
    }
}
.SearchFieldContainer {
    background-color: white;
    border-color: #dee7ec !important;
    border-radius: 40px;
    border-width: 2px !important;
    border: solid;
    display: flex;
    flex-direction: row;
    max-height: 48px;
    padding: 2px;
    width: 100%;
    @media (min-width: $tablet) {
        width: 500px;
        height: 48px;
        // margin-bottom: 48px;
    }
}
.MinimumCharactersError {
    font-size: 11px;
    color: #c43f17;
    margin-top: 4px;
}
.ErrorField {
    border-color: #d95535 !important;
}

.SearchIcon {
    margin: 9px 16px 8px 14px;
    width: 25.3px;
    @media (max-width: $tablet) {
        margin: 11px 16px 8px 14px;
    }
}

.SearchField {
    border-radius: 40px;
    border: none;
    color: #2F2F37;
    font-family: $fontFamily;
    font-size: 16px;
    width: 100%;
}
::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #717179;
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #717179;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #717179;
}

.SearchField:focus,
input:focus {
    outline: none;
}
.SearchButton {
    background-color: #d1e4d0;
    border-radius: 60px;
    border: none;
    color: #2F2F37;
    font-family: $fontFamily;
    font-size: 14px;
    padding: 10px 24px 9px 24px;
    cursor: pointer;
}
.ClearSearchField {
    padding: 10px;
    margin-right: 5px;
    margin-left: 35px;
    cursor: pointer;
}
.SpinnerContainer {
    display: flex;
    justify-content: center;
}
.ResultSpinner {
    width: 100px !important;
    height: 100px !important;
    @media (max-width: $tablet) {
        margin-top: 150px;
    }
    @media (min-width: $tablet) {
        margin-top: 200px;
    }
}

.InfoText {
    color: #717179;
    font-family: $fontFamily;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    @media (max-width: $tablet) {
        margin: 40px;
    }
    @media (min-width: $tablet) {
        margin-top: 48px;
        width: 295px;
    }
}
.InfoTextReducedTopMargin {
    margin-top: 23px;
    @media (min-width: $tablet) {
        margin-top: 32px;
    }
}

.AllConversationsResult {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    margin-right: 20px;
    margin-left: 20px;
}

.AllConversationsLabel,
.AllQuestionsLabel {
    font-size: 18px;
    margin-left: 2px;
    margin-bottom: 4px;
    display: flex;
    flex-wrap: nowrap;
    @media (min-width: $tablet) {
        margin-bottom: 0px;
    }
}

.ConversationCountLabel,
.QuestionCountLabel {
    font-size: 14px;
    color: #717179;
    margin-left: 2px;
    margin-bottom: 40px;
    @media (min-width: $tablet) {
        margin-bottom: 0px;
    }
}

.AllConversationsLabelSection,
.AllQuestionsLabelSection {
    display: flex;
    flex-direction: column;
    @media (min-width: $tablet) {
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 16px;
        width: 100%;
    }
}

.AllQuestionsResult {
    margin-right: 16px;
    margin-left: 16px;
}

.BackButtonContainer {
    width: 100%;
    position: sticky;
    top: 0px;
    z-index: 100;
    background: rgba(255, 255, 255, 0.9);
}

.BackButton {
    padding: 8px 16px 8px 2px;
    color: #2b4a64;
    font-family: $fontFamily;
    // margin-right: auto;
    // margin-left: auto;
    margin-bottom: 8px;
    margin-top: calc(env(safe-area-inset-top) + 8px);
    font-size: 16px;
    background: none !important;
    border: none;
    cursor: pointer;
    margin-top: 40px;
    margin-left: -12px;
    text-decoration: none;
    @media (min-width: $tablet) {
        margin-bottom: 8px;
        padding: 8px 16px 8px 16px;
    }
    @media (min-width: $desktop) {
        padding: 10px 15px;
        &:focus,
        &:hover {
            background: #eef5f5 !important;
            border-radius: 60px !important;
        }
    }
}

.LeftArrow {
    margin-right: 8px;
}

.ConversationsTitle {
    font-size: 18px;
    font-weight: bold;
}

.ConversationsHeader {
    flex-wrap: nowrap;
    display: flex;
    align-items: center;
    font-size: 18px;
    margin-left: 2px;
    margin-bottom: 16px;
}

.ConversationCardsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 9px;
    width: 100%;
}

.ConversationCard {
    margin-bottom: 15px;
    margin-right: 15px;
    width: 150px;
    &:hover {
        cursor: pointer;
    }
}
.RightCard {
    margin-right: 0px;
}
.LeftCard {
    margin-right: 15px;
}
.OrganizationCard {
    --background-color: var(--tuna);
    --color-organization-card: var(--white);
}

.OrganizationCard {
    background: var(--background-color);
    color: var(--color-organization-card);
    margin-left: 5px;
    @media (min-width: $tablet) {
        margin-right: 20px;
    }
    .OrganizationCard + .RightCard {
        margin-right: 20px !important;
    }
}
.OrganizationCard.LeftCard {
    margin-left: 5px !important;
    margin-right: 20px !important;
}

.ThemeCard {
    margin-left: 5px;
    @media (min-width: $tablet) {
        margin-right: 20px;
    }
    .RightCard {
        margin-right: 20px !important;
    }
}
.ThemeCard.LeftCard {
    margin-left: 5px !important;
    margin-right: 20px !important;
}

.ShowAllQuestionsLink,
.ShowAllConversationsLink {
    color: #2b4a64;
    font-family: $fontFamily;
    font-size: 16px;
    background: none !important;
    border: none;
    cursor: pointer;
    padding-top: 0px !important;
    padding-bottom: 32px !important;
    text-decoration: underline;
    flex-wrap: nowrap;
    display: flex;
}

.BoldSearchedText {
    font-weight: 600;
}

.ShowAllQuestionsLink {
    margin-left: 16px;
    @media (max-width: 371px) {
        margin-left: 6px;
    }
}

.QuestionsContainer {
    margin-bottom: 80px;
    width: 100%;
}

.QuestionsHeader {
    flex-wrap: nowrap;
    display: flex;
    align-items: center;
    font-size: 18px;
    margin-left: 16px;
    margin-bottom: 16px;
    margin-top: 40px;
    @media (min-width: $tablet) {
        margin: 0px 0px 16px 0px;
    }
    @media (max-width: 371px) {
        margin-left: 6px;
    }
}
.QuestionsTitle {
    font-size: 18px;
    font-weight: bold;
}

.QuestionBoxesContainer {
    margin-bottom: 24px;
}

.AllQuestionBoxesContainer {
    margin-bottom: 24px;
    @media (min-width: $tablet) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
}

.AllQuestionsCardContainer {
    @media (min-width: $tablet) {
        // margin-right: 0px !important;
        max-width: 364px !important;
        min-width: 364px !important;
    }
    @media (min-width: $desktop) {
        max-width: 504px !important;
        min-width: 504px !important;
    }
}

.NoRightMargin {
    margin-right: 0px !important;
}

.userCreatedConversation1 {
    background-image: linear-gradient(#fce8b3, #f8bbac)!important;
}
.userCreatedConversation2 {
    background-image: linear-gradient(#eef5f5, #d1e4d0);
}
.userCreatedConversation3 {
    background-image: linear-gradient(#fdeae6, #ebdbeb);
}
