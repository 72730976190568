@import '../../style/styles.scss';

.appbar {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 1000;
    width: 100%;
    height: env(safe-area-inset-bottom) + 48px;
    display: flex;
    justify-content: space-between;
    padding-left: $gutter * 2;
    padding-right: $gutter * 2;
    padding-bottom: env(safe-area-inset-bottom);
    background: $white;
    box-shadow: 0px 0px 2px #00000029;

    @media (min-width: $tablet) {
        position: static;
        bottom: unset;
        height: unset;
        width: unset;
        padding-left: 0;
        padding-right: 0;
        background: unset;
        box-shadow: none;
    }
}

.list {
    list-style: none;
    display: flex;
    justify-content: space-evenly;
    margin: 0;
    padding: 0;
    width: 100%;

    .item {
        height: 48px;
        width: 48px;
        position: relative;
    }
}

.link {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-repeat: no-repeat;
    &:hover {
        background-color: rgba(244, 246, 247, 1);
        border-radius: 200px;
    }

    &.homeIcon {
        background-image: url('./images/home.svg');
    }

    &.userIcon {
        background-image: url('./images/user.svg');
    }

    &.proIcon {
        background-image: url('./images/pro.svg');
    }
    &.heartIcon {
        background-image: url('./images/heart-outline.svg');
    }
    &.searchIcon {
        background-image: url('./images/magnifier.svg');
    }

    .indicator {
        display: none;
        position: absolute;
        bottom: 4px;
        height: 4px;
        width: 4px;
        border-radius: 50%;
        background: $primaryTextColor;
    }

    &.active {
        &.homeIcon {
            background-image: url('./images/home_active.svg');
        }

        &.userIcon {
            background-image: url('./images/user_active.svg');
        }
        &.proIcon {
            background-image: url('./images/pro_active.svg');
        }
        &.heartIcon {
            background-image: url('./images/heart-filled.svg');
        }
        &.searchIcon {
            background-image: url('./images/magnifier_active.svg');
        }

        .indicator {
            @media (min-width: $tablet) {
                display: block;
            }
        }
    }
}
