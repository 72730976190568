@import '../../style/styles.scss';

$border: 1px solid #dee7ec;

.container {
    padding: 48px 32px 32px 32px;
    @media (max-width: 768px) {
        padding-top: 0px !important;
        margin-top: -10px !important;
    }
    .pageHeader {
        padding-top: 0px;
        padding-bottom: 40px;
        color: $primaryTextColor;
    }
    .pageItem {
        padding-bottom: 24px;
    }
}
@media (max-width: $tablet) {
    .container {
        .BackButton Button {
            padding-left: 0px;
            padding-bottom: 8px;
        }
    }
}

@media (min-width: $tablet) {
    .pageContainer {
        background-image: url('../../images/bg-desktop-speechbubbles-white.svg');
        background-repeat: no-repeat;
        background-size: cover;
    }
    .container {
        //background-color: blue;
        margin-top: 120px;
        display: flex;
        flex-direction: column;
        text-align: center;
        .BackButton {
            align-self: center;
        }
        .pageHeader {
            margin-top: 8px;
            font-size: 1.5rem;
            line-height: 2rem;
            font-weight: bold;
        }

        h3 {
            font-weight: bold;
        }
    }
}
