@import '../../../style/styles.scss';

.page {
    display: flex;
    flex-direction: column;

    .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        background-repeat: no-repeat;
        background-size: 100% auto;
    }
}

.container {
    flex-grow: 1;
    margin-top: $gutter * 2.5;
    margin-right: 32px;
    margin-left: 32px;
    display: flex;
    flex-direction: column;
    @media (max-width: 768px) {
        margin-top: -8px !important;
    }
    @media (min-width: $tablet) {
        align-self: center;
        margin-top: 200px;
    }
    @media (min-width: $tablet) {
        width: 520px !important;
    }
}

.backButton {
    margin-left: -12px;

    @media (min-width: $tablet) {
        align-self: center;
    }
}

.title {
    margin-bottom: 40px;
    font-size: 1.5rem;
    line-height: 1.5;
    font-weight: 700;

    @media (min-width: $tablet) {
        align-self: center;
    }
}

.text {
    margin-top: 0;
    margin-bottom: $gutter * 1.5;

    &:last-of-type {
        margin-bottom: 0;
    }
}

.cta {
    margin-top: $gutter * 2.5;
    margin-bottom: $gutter * 2.5;
}
