@import '../../../style/styles.scss';

.header {
    position: relative;
    margin-top: 35px;
    margin-bottom: $gutter * 2.5;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 768px) {
        margin-top: -8px !important;
    }

    .title {
        font-size: 1.5rem;
        line-height: 2rem;
        font-weight: 700;
        flex-grow: 1;

        @media (min-width: $tablet) {
            text-align: center;
        }
    }

    .button {
        @media (min-width: $tablet) {
            position: absolute;
            right: 0;
            top: 0;
        }
    }
}

.link{
    cursor: pointer;
}

.container {
    padding-left: $gutter * 2;
    padding-right: $gutter * 2;

    @media (min-width: $tablet) {
        padding-left: 0;
        padding-right: 0;
    }
}
.DeleteWarningContainer{        
    .WarningText{
        color: #BD2C0F;
    }
    .DeleteMeLink, .UndoDeleteLink{
        color: #BD2C0F;
        text-decoration-color: #BD2C0F;
        cursor: pointer;
    }
}

.DeleteWarningContainerMobile{
    margin-bottom: 40px;
}

.ConfirmEmailText {
    padding: 2px 0px 20px 0px;
    text-align: start;
    font-weight: 600;
    color: #d95535;
    @media (min-width: $tablet) {
        text-align: center;
    }
}

.onlyMobile {
    @media (min-width: $tablet) {
        display: none !important;
    }
}


.ToastContainer{
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
    position: fixed;
    
    // z-index: 90;
    width: 100%;
    @media (min-width: $desktop) {        
        align-items: center;
    }
 
}

.RemovedQuestionToast{
    display: flex;
    position: -webkit-sticky;
    position: sticky;    
    bottom: 16px;
    margin: 16px 16px 16px 16px;
    margin-bottom: calc(env(safe-area-inset-bottom) + 56px);
    right: 0px;
    left: 0px;
    padding-right: 32px;
    border-radius: 4px;
    align-items: center;
    background-color: var(--sanjuan);
    width: unset!important;
    opacity: 1;
    -webkit-animation: cssAnimation 3s forwards; 
    animation: cssAnimation 3s forwards;
    

    @media (min-width: $desktop) {
        height: 40px;
        border-radius: 40px;
        margin-bottom: 32px;
    }
}

.Hidden{
    opacity: 0!important;
    animation: none!important;
}

