.OptionContainer{
    min-width: 306px;
    min-height: 48px;
    display: flex;
    flex-direction: row;    
padding: 13px 24px 0px 16px;
font-size: 16px;
font-weight: var(--weightMedium);
font-family: var(--fontFamily);
color:  var(--sanjuan);
&:hover{
    background-color: var(--hoverBg);
    cursor: pointer;
    &:first-of-type {
        border-radius: 8px 8px 0px 0px;
    }
    &:last-of-type{
        border-radius: 0px 0px 8px 8px;
    }
}

}
.IconContainer{
    flex: 1;
    // max-width: 12px;
    margin:  3px 16px 0px 0px;
}

.TextContainer{
    padding: 0px 0px 11px 0px;
}

.SubTitle{
    font-size: 13px;
    font-style: italic;
    color: var(--jumbo);
    line-height: 24px;
    font-weight: var(--weightLight);
}