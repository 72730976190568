@import '../../../style/styles.scss';
@import './variables.scss';

$backgroundHover: #2b4a640d;
$dotColor: #d4dbe0;
$dotColorActive: $primaryColor;

.navigationButton {
    position: absolute;
    width: $navButtonWidth;
    height: 100%;
    background: none;
    padding: 0;
    margin: 0 -16px;
    border: 2px solid transparent;
    border-radius: 8px;
    flex-shrink: 0;
    outline: none;
    box-shadow: none;

    &:hover {
        background: $backgroundHover;

        .dots {
            opacity: 1;
        }
    }
    &:focus {
        border: 2px dotted $primaryColor;
        outline: none;

        .dots {
            opacity: 1;
        }
    }

    &.right {
        right: 0;
    }
    &.left {
        left: 0;
    }
}

.dots {
    position: absolute;
    bottom: 0;
    width: 100%;
    margin: 0 auto 8px auto;
    opacity: 0;
    transition: opacity 0.3s;
}

.dot {
    display: inline-block;
    background: $dotColor;
    border-radius: 50%;
    height: 4px;
    width: 4px;
    margin-left: 2px;
    margin-right: 2px;

    &.active {
        background: $dotColorActive;
    }
}
