@import '../../../style/styles.scss';

.container {
    width: unset;
    max-width: 460px;

    .link {
        color: $primaryColor;
    }
}
