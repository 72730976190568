@import '../../style/styles.scss';

.layout {
    min-height: 100%;
    display: flex;
    flex-direction: column;

    main {
        flex-grow: 1;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .page {
        flex-grow: 1;
    }
}
