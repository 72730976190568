@import '../../style/styles.scss';

.OptionModalContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 99;
}
  
.OptionModal{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 1px #2B4A6433;
    border-radius: 8px;
    margin: 80px 32px;
}
.IconEdit{
    max-width: 13.33px!important;
    max-height: 13.33px!important;
    margin-left: 3px;
}
.IconConversationOutline{
    max-width: 17.57px!important;
    max-height: 14px!important;
    
}
.IconPublic{
    max-width: 16px!important;
    max-height: 16px!important;
    margin-left: 2px;
}
.IconPrivate{
    max-width: 16px!important;
    max-height: 16px!important;
    margin-top: 0px;
    margin-left: 1px;
}
.IconDelete{
    max-width: 12.44px!important;
    max-height: 16px!important;
    margin-left: 4px
}