.ProSubContainer .ProSubContainerOwner {
    --weight-bold-header: var(--weight-bold);
}

.ProSubContainer {
    @media (min-width: 768px) {
        padding-bottom: 0px;
        border-bottom: none;
    }
}

.Header {
    font-size: 1.125em;
    font-weight: var(--weight-bold-header);
    font-weight: bold;
}

.FaqLink {
    color: #2b4a64;
    text-decoration: underline;
}
