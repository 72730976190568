@import '../../style/styles.scss';

.page {
    text-align: center;

    .container {
        max-width: 320px;
        margin-top: $gutter * 4;

        @media(min-width: $tablet) {
            margin-top: 200px;
        }
    }

    .form {
        margin: 0 auto;
        margin-top: $gutter * 2.5;
    }

    .input {
        margin-top: $gutter;
    }

    .cta {
        margin-top: $gutter * 2.5;
    }

}