@import '../../../../../style/styles.scss';

.Product {
    --white: #ffffff;
    --color-default: #dee7ec;
    --color-active: #d16747;
    --radius: 16px;
    --padding: 32px 50px 24px 50px;
    --minWidth: 311px;
    --maxHeight: 143px;
    --size-title: 1.125rem;
    --size-price: 1.125rem;
    --size-details: 0.875rem;
    --weight-title: bold;
}

button {
    font-family: 'Source Sans Pro';
}

.Product {
    background-color: var(--white);
    border: 2px solid var(--color-default);
    border-radius: var(--radius);
    padding: var(--padding);
    text-align: center;
    min-width: var(--minWidth);
    height: var(--maxHeight);
    margin: 16px;
    position: relative;
    color: black;

    p {
        margin: 0;
    }

    &:hover {
        background: #2b4a640d;
        @include solidBorder(var(--color-default), 16px, 4);
    }

    &:focus {
        outline: none;
        border: none;
        @include dashedBorder($primaryColor, 16px, 4);
    }

    .Title {
        font-size: var(--size-title);
        font-weight: var(--weight-title);
        line-height: 1.5rem;
    }

    .Price {
        font-size: var(--size-price);
        line-height: 1.5rem;
    }

    .Details {
        font-weight: 400;
        font-style: italic;
        font-size: var(--size-details);
        opacity: 0.75;
        margin-top: 16px;
    }
}

.Highlight {
    @include solidBorder(var(--color-active), 16px, 4);

    &:focus {
        @include dashedBorder($activeColor, 16px, 4);
    }
}

.Product__Callout {
    position: absolute;
    top: 20%;
    right: -20px;
}

@media (max-width: $tablet) {
    .Product {
        margin: 8px;
    }
    .Product .Price {
        font-size: 1rem;
    }
}
