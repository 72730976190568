@import '../../../style/styles.scss';

.ProOwnerContainer{
    @media (min-width: $desktop) {
        background-color: var(--color-blue98);
        border: 2px solid var(--color-blue90);
        border-radius: 8px;
        padding: 24px 32px;
    }
}

.Header {
    font-size: 1.125em;
    font-weight: var(--weight-bold-header);
    font-weight: bold;
}

.FaqLink {
    color: #2b4a64;
    text-decoration: underline;  
    cursor: pointer;
}
