@import '../../../style/styles.scss';

.CompanySignupForm {
    --color-form-label: #1b1c1d;
    --color-form-input-bg: #ffffff;
    --color-form-input-border: #dee7ec;
    --color-form-input-border-error: #f48e75;
    --color-form-input-border-active: #2b4a64;
    --color-form-hint: #2F2F37;
    --color-form-error: #c43f17;
    --color-link: #2b4a64;
    --color-terms: #717179;
    --radius-form-input: 8px;
}

.Circle {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    padding: 3px 11px 4px 11px;
    background: var(--color-black20);
    color: white;      
    text-align: center;
    justify-content: center;
    font-size: 20px;
}

.CompanySignupForm {
    margin-bottom: 24px;
    

    .GoogleRegisterButton{
        @media (max-width: 1047px) {
            width: 100%;            
        }
    }
    
    .CreateNewUserHeader{
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 24px;
    }

    .GoogleRegisterButton button{
        max-width: 311px;
        width: 226px;
        max-height: 80px;        
        height: 48px;
        min-height: 48px;
        border-radius: 8px;
        color: #2F2F37;
        border: 2px solid #dee7ec !important;
        padding: 14px 24px 14px 15px !important;

        span {
            padding: 1px 1px 1px 1px !important;
            margin: 0px;
            font-size: 14px!important;            
        
            @media (min-width: $desktop) {
                white-space: nowrap;
            }
        }
        svg{
            height: 20px!important;
            min-height: 20px!important;
            margin-left: 0px;
            margin-right: 12.438px;
            min-width: 23.45px!important;
            
        
        }
        @media (max-width: 1047px) {
            width: 100%;     
        }
    }

    .LoggedInAsTitle{
        font-weight: bold;
    }

    .LoggedInUserInfoContainer{
        border: 2px solid var(--color-blue90);
        border-radius: 8px;
        padding: 18px 24px;
        width: 464px;
        display: flex;
        margin-top: 24px;            

        .LoggedInUserLogo{            
            background-color: var(--color-blue90);
            height: 40px;
            width: 40px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .LoggedInUserNameEmail{
            margin-left: 24px;
            
            .name{
                font-weight: bold;
                margin-bottom: 4px;
            }
        }
        @media (max-width: 1047px) {
            width: auto;            
        }

    }

    .LoggedInUserLowerContainer{
        margin-top: 24px;

        .LogInWithOtherAccountLink{
            font-size: 14px;
        }

        .ConnectToOtherAccountLink{
            margin-top: 40px;
            font-size: 14px;
            margin-bottom: 40px;
        }           
        
        @media (max-width: 1047px) {
            .ButtonLink{
                text-decoration: underline!important;
            }           
        }
    }

    .EmailLoginContainer{
        .NoUserText {
            margin-left: 91px;
            color: var(--color-black-40);
            width: 323px!important;
            font-size: 14px;
            display: flex;                
            align-items: center;
            .ButtonLink{
                // width: 105px;            
                color: var(--color-blue30);       
            }
            @media (max-width: 1047px) {
                margin-left: 0px;
                margin-top: 24px;
                .ButtonLink{
                    text-decoration: underline!important;
                }                
            }

        }

        div div input{
            width: 464px;
        }

        .reset{
            font-size: 13px;
            color: var(--color-black-40);            
            z-index: 2;
        }

        .BottomContainer{
            display: flex;

            .Button{
                width: 150px;                 
            }         
           
        }
        @media (max-width: 1047px) {

            form {
                padding: 0px;
                align-items: start;                   
            }

            div{
                width: 100%;
            }
            div div input {
                width: 100%;
                min-width: unset;
            }
           
        }
    }

    .LeftContainer{
        display: flex;
        @media (max-width: 1047px) {
            flex-direction: column;
            width: 100%;                        

            .HeaderContainerMobile{
                display: flex;

                h2{
                    font-size: 20px;
                    line-height: 30px;
                    margin-left: 24px;
                }
            }
        }
        
        .CreateLoginContainer{
            margin-left: 24px;
            @media (max-width: 1047px) {
                width: 100%;
                margin: 0px;
                margin-top: 6px;
            }

            h2{
                color: var(--color-black20);
                font-weight: bold;                
                line-height: 2rem;     
                font-size: 24px;
                margin-bottom: 40px;           
            }

            .CreateUserBanner{
                background-image: url('../images/createUserBanner.svg');   
                height: 400px;
                width: 464px;
                flex-direction: column;
                align-items: center;
                color: white;
                display: flex;

                .CreatUserBannerText1{
                    font-weight: bold;
                    margin-top: 64px;
                }
                .CreatUserBannerText2{
                    margin-top: 8px;
                }
                .CreateUserButton{
                    margin-top: 40px;
                }

                @media (max-width: 1047px) {
                    width: 100%;
                    background-image: url('../images/createUserBanner_Mobile.svg');  
                    background-repeat: no-repeat;  
                    background-size: cover;
                    background-position: center;
                    border: 2px #2B4A64 solid;
                    border-radius: 16px;
                    .CreatUserBannerText1{
                        margin: 48px 24px 0px 24px;
                        align-content: center;
                        text-align: center;
                    }
                    .CreatUserBannerText2{
                        margin: 16px 24px 0px 24px;
                    }
                }

            }
            .LoginContainer{
                margin-top: 40px;
                display: flex;
                flex-direction: row;

                .EmailLoginButton{
                    margin-left: 16px;
                    border-radius: 8px;
                    color: #2F2F37;
                    border: 2px solid #dee7ec !important;
                    width: 226px;
                    padding: 15px 39px 13px  16px;
                    font-size: 14px;
                    height: 48px;
                    min-height: 48px;
                    span{
                        margin-left: 0px;
                        svg{
                            height:21.005px;
                        }
                    }
                }

                @media (max-width: 1047px) {
                    flex-direction: column;

                    .EmailLoginButton{
                        width: 100%;                        
                        margin: 16px 0px 0px 0px;
                        text-align: start;
                        span {
                        margin-left: 1px;
                        }
                        div{
                            margin-left: 8px;
                            display: flex;
                            place-content: flex-start;
                        }
                    }

                    .GoogleRegisterButton {
                        width: 100%;      
                                               
                        button{
                            width: 100%;
                            max-width: unset;
                        }
                        svg{
                            margin-right: 15.562px;
                        }
                    }
                }
            }
        }
    }

    .CollapsedContainer{
        border: 2px solid var(--color-brown85);
        border-radius: 16px;
        padding: 44px 32px 40px 32px;      
        display: flex;
        justify-content: space-between;       

        @media (max-width: 1047px) {
            margin-left: 16px;
            margin-right: 16px;
            padding: 32px 0px 24px 24px;

            .LeftLeftContainer{            
                display: flex;
                h2 {
                    font-size: 20px;
                    line-height: 30px;
                    margin-left: 24px;
                }
            }                   
        }
        .LeftMiddleContainer{
            display: flex;
            flex-direction: column;
            margin-left: 24px;
            h2{
                color: var(--color-black20);
                font-weight: bold;                
                line-height: 2rem;
                font-size: 24px;
                margin-bottom: 0px;
            }
            .UserInfoContainer{                
                margin-top: 24px;
                line-break: anywhere;    
                .Name{
                    font-weight: bold;
                }
                .Email{
                    margin-top: 4px;
                }
                .Phone{
                    margin-top: 4px;
                }
                @media (max-width: 1047px) {                    
                    margin-left: 32px;   
                    margin-top: 0px;                         
                }

            }
        }

        .RightContainer{
            // margin-left: 270px;
            margin-top: 6px;
            .ButtonLink{
                padding: 24px;
                text-decoration: none;
                font-weight: 600;
            }

        }

    }

    .ExpandedContainer{
        display: flex;
        background-color: white;
        border: 2px solid var(--color-black20);
        border-radius: 16px;
        padding: 44px 88px 56px 32px;  
        
        @media (max-width: 1047px) {
            padding: 24px 40px 32px 40px;
            width: 100%;
        }
       
        .LeftLeftContainer{
            @media (max-width: 1047px) {
            display: flex;
                h2 {
                    font-size: 20px;
                    line-height: 30px;
                    margin-left: 24px;
                }
            }
                     
        }
        
        .LeftMiddleContainer{
            display: flex;
            flex-direction: column;
            margin-left: 24px;
            
            @media (max-width: 1047px) {
                margin-left: 0px;
            }

            .CreateUserForm{
                padding: 0px;                
                width: 100%;
            }


            h2{
                color: var(--color-black20);
                font-weight: bold;                
                line-height: 2rem;
                margin-bottom: 0px;
                font-size: 24px;
            }

            .LoginContainer{
                display: flex;            
                margin-bottom: 40px;
                margin-top: 40px;                              

                .HasAccount{                
                    align-self: center;
                    font-size: 14px;
                    margin-left: 24px;
                }
            }
                      
            .EmailInputContainer{
                div div input{
                    width: 464px;   
                                 
                }

                @media (max-width: 1047px) {
                    // div {
                    //     width: 100%;
                    // }
                    min-width: unset;

                    div div {
                        width: 100%;
                    }
                    div div input{
                        width: 100%;
                        min-width: unset;
                        margin: 0px;
                    }
                }    
            }

            .NameContainer{
                display: flex;
                flex-direction: row;
                
                div div input{                
                    width: 224px!important;
                    min-width: 224px!important;

                    &:first-child{
                        margin-right: 16px;
                    }
                }
                @media (max-width: 1047px) {
                    flex-direction: column;
                    width: 100%;
                   
                    div div, div div input{  
                        width: 100%!important;                        
                        &:first-child{
                            margin-right: 0px;
                        }
                    }
                }                
            
            }

            .PasswordInputContainer{
                div div input {
                    width: 464px;                   
                }
                @media (max-width: 1047px) {
                    div div, div div input{
                        width: 100%;
                        min-width: unset;
                    }
                } 
            }

            // .InputInfoText{
            //     font-size: 13px;
            //     color: var(--color-black-40);
            //     font-weight: 300;
            //     margin-top: -19px;
            //     margin-left: 2px;
            // }

            // .label {
            //     color: var(--color-black20);
            //     font-size: 14px;
            //     letter-spacing: 0.34px;
            // }
            // .inputField{
            //     margin-bottom: 23px;
            //     border: 2px solid var(--color-blue90);
            //     height: 48px;
            // }

        

        }

        .RightContainer{
            // width: 50px;

        }
        
        a {
            text-decoration: underline;
            &:hover,
            &:focus,
            &:active,
            &:visited {
                color: var(--color-link);
            }
        }

        form {
            // max-width: 311px;
            min-width: 100px!important;
            display: flex;
            justify-content: center;
            flex-direction: column;

            .createAccountButton {            
                min-height: 56px;
                width: 126px;
                
                @media (max-width: 1047px) {
                    margin-bottom: 56px;
                }
            }
        }
    }
}

.HasAccount {
    @media (max-width: 1047px) {
        font-size: 14px;
        margin-bottom: 48px;
        .ButtonLink{
            text-decoration: underline!important;
        }
    }
}

.Form__Info {
    color: var(--color-terms);
    text-align: center;
    font-size: 0.875rem;
    margin: 48px 0;
    
    @media (max-width: 1047px) {
        margin: 40px 0;        
    }
}

.Form__Terms {
    // margin-bottom: 16px;
    text-align: start;
    @media (max-width: 1047px) {
        font-size: 14px;
        line-height: 18px;

        .ButtonLink{
            text-decoration: underline!important;
        }

    }
}

.Form__Actions {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}

// Form controls
.Form__Field {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
    position: relative;

    .inputField input {
        background: var(--color-form-input-bg) 0% 0% no-repeat padding-box;
        padding: 12px;
        margin-bottom: 2px;        
        
    }

    & > span {
        height: 15px;
        margin-bottom: 4px;
        letter-spacing: 0;
        font-size: 0.688rem;
        opacity: 0.7;
    }
}

.Form__Label {
    text-align: left;
    letter-spacing: 0.34px;
    color: var(--color-form-label);
    font-size: 0.875rem;
    font-weight: 600;
    margin-bottom: 2px;
}

.Form__Input {
    border: 2px solid var(--color-form-input-border);
    border-radius: var(--radius-form-input);
    // min-width: 311px;
    min-width: 100px!important;
    min-height: 48px;

    &:hover,
    &:active,
    &:focus {
        outline: none;
        border: 2px dashed var(--color-form-input-border-active);
    }
}

.Form__Input__Error {
    border: 2px solid var(--color-form-input-border-error);
    border-radius: var(--radius-form-input);
    // min-width: 311px;
    min-height: 48px;

    &:hover,
    &:active,
    &:focus {
        outline: none;
        border: 2px dashed var(--color-form-input-border-error);
    }
}

.Form__Placeholder {
    color: var(--color-form-hint);
}

// Error styles
.Form__Error__Show {
    color: var(--color-form-error);
}

.Form__Error__Hidden {
    visibility: hidden;
}

.Form__PassToggle__Hidden {
    position: absolute;
    top: 36px;
    right: 12px;
    background-color: transparent;
    border: none;
    outline: none;
    margin: 0;
    padding: 0;
}

.Form__PassToggle__Reveal {
    position: absolute;
    top: 32px;
    right: 11px;
    background-color: transparent;
    border: none;
    outline: none;
    margin: 0;
    padding: 0;
}

.ButtonLink {
    cursor: pointer;
    border: none;
    background-color: transparent;
    color: var(--color-link);
    text-decoration: underline;
    margin: 0;
    padding: 0;
    font-size: inherit;
    @media (max-width: 1047px) {
        text-decoration:none!important;
        font-size: 14px;
    }
}

.Toast{
    width: 100%;
    margin-top: 8px;
    padding-right: 32px;
    border-radius: 40px;
    align-items: center;
    text-align: center;
    background-color: var(--sanjuan);
    opacity: 1;

    @media (max-width: 560px) {
        position: fixed;
        bottom: 32px;
        left: 50%;
        transform: translateX(-50%);
        width: 96%;
    }
}
