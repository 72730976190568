@import '../../../style/styles.scss';
@import '../../../components/conversation-card/variables.scss';
@import './variables.scss';

$placeholderDark: $primaryColor;
$placeholderLight: #dee7ec;
$navigationWidth: $navButtonWidth + $gutter;

.container {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding-left: $gutter;
    margin-top: 16px;
    margin-bottom: 80px;
}

.randomCardItem {
    opacity: 1;
    display: flex;
    border: 2px solid #dee7ec;
    border-radius: 24px;
    justify-content: space-between;
    align-items: center;
    width: 482px;
    padding: 32px 40px;

    h2 {
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
    }
}

@media (max-width: $tablet) {
    .container {
        margin-bottom: 38px;
        padding-left: 56px;
        padding-right: 56px;
        width: 100%;
        display: flex;
        justify-content: center;
        
        Button {
            flex: 1;
        }
    }
    .randomCardItem {
        border: none;
        padding: 0;
    }

}
