@import '../../style/styles.scss';

.Layout {
    display: flex;
    justify-content: center;
}

.QuestionInfoPageContainer {
    min-height: 100%;
    margin-top: env(safe-area-inset-top);
    position: relative;
    display: flex;
    padding-bottom: 60px;
}

.bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% + 60px);
    pointer-events: none;
    z-index: -1;
    background-image: url('../deck-modal/images/bg-mobile-deck-modal.svg'),
        $bgGradientMobile;
    background-size: cover;

    @media (min-width: $desktop) {
        background-image: url('../deck-modal/images/bg-desktop-deck-modal.svg'),
            $bgGradientDesktop;
    }
}

.ContentContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    margin: auto;
}

.QuestionSection {
    max-width: 500px;
    font-size: 24px;
    color: #2b4a64;
    font-weight: 600;
    text-align: center;
    margin-left: 58px;
    margin-right: 58px;
    line-height: 1.4;
    @media (min-width: $tablet) {
        font-size: 40px;
        max-width: 650px;
    }
    @media (min-width: $desktop) {
        max-width: 800px;
    }
}

.CreateUserButton {
    min-height: 56px;
    min-width: 190px;
    position: fixed;
    bottom: calc(env(safe-area-inset-bottom) + 77px);
}

.footer {
    bottom: calc(env(safe-area-inset-bottom) + 20px);
    position: fixed;
    font-size: 14px;
    font-weight: 400;

    .link {
        margin-left: 8px;
        text-decoration: underline;
        color: #2b4a64;
    }
}
