@import '../../../style/styles.scss';

.form {
    flex-grow: 1
}

.DeleteMeLinkContainer {
    text-align: center;
    padding: $gutter * 2 0;
    bottom: calc(env(safe-area-inset-bottom) + 108px);
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;


    .WarningText{
        color: #BD2C0F;
    }

    .DeleteWarningText2{
        margin-bottom: 16px;
    }

    .DeleteMeLink, .UndoDeleteLink{
        color: #BD2C0F;
        text-decoration-color: #BD2C0F;        
    }

    .UndoDeleteLink{
        
    }
}
