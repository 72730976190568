@import '../../style/styles.scss';

.container {
    padding-left: $gutter;
    padding-right: $gutter;
    margin-bottom: $gutter * 3;
    margin-top: -24px;
    @media (min-width: 768px) {
        margin-top: 0px;
    }
}

.title {
    font-size: 1.5rem;
    line-height: 1.25;
    font-weight: 700;
}

.backButton {
    margin-left: -12px;
}

.card {
    margin-right: $gutter;
    margin-top: $gutter;
}

.ConversationCardsOuterContainer{
    display: flex;
    justify-content: center;
    width: 340px;
    @media (min-width: 500px ) {
        width: 505px;
    }
    @media (min-width: 670px ) {        
        width: 670px;
    }
    @media (min-width: 920px ) {
        width: 835px;
    }
    @media (min-width: 1045px ) {
        width: 1005px;
    }
}

.ConversationCardsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 80px;
    @media (max-width: $tablet) {
        justify-content: left;
    }
}

.newlyAddedConversationsSection{
    margin-top: 48px;
}


.userCreatedConversation1 {
    background-image: linear-gradient(#fce8b3, #f8bbac);
}
.userCreatedConversation2 {
    background-image: linear-gradient(#eef5f5, #d1e4d0);
}
.userCreatedConversation3 {
    background-image: linear-gradient(#fdeae6, #ebdbeb);
}

.FavouriteThemeCard {
    margin-left: 5px;    
    .RightCard {
        margin-right: 20px !important;
    }
}
.FavouriteThemeCard.LeftCard {
    margin-left: 5px !important;
    margin-right: 20px !important;
}