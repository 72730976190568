@import '../../../style/styles.scss';

.container {
    height: 100%;
    display: flex;
    justify-content: center;
    .content {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 40px;
    }
}

.containerRandom {
    .content {
        text-align: center;
        padding: 0 40px;
    }
    @media (max-width: $tablet) {
        justify-content: unset !important;
        top: 40vh;
    }
}
