@import '../../style/styles.scss';

.confirmationPage {
  display: flex;
  flex-direction: column;

  background-image: url('./images/bg-mobile-confirmation.svg');
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-size: 100% auto;
  background-attachment: fixed;

  @media (min-width: $desktop) {
    background-image: url('./images/bg-desktop-confirmation.svg');
  }

  .container {
    flex-grow: 1;
    position: relative;
    max-width: 500px;
    margin: 0 auto;
    padding: 48px 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1.5;
    text-align: center;

    .text {
    }

    .button {
      margin-top: $gutter * 3;
      margin-left: auto;
      margin-right: auto;
      background: $white;
      min-width: 200px;
      max-height: 56px;
    }
  }

  .ExploreLink {
    margin-top: 20px;
    text-decoration: underline;
    color: #2F2F37;
  }

  .inAppPurchaseText1 {
    margin-top: $gutter * 2;
    color: red;
  }
  .inAppPurchaseText2 {
    color: red;
  }
  .inAppPurchaseMobileConfirmationText {
    margin-top: $gutter * 2;
    color: red;
  }
}
