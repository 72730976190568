.container {
    --small-margin: var(--gutter);
    --double-margin: var(--double-gutter);

    --width-tablet: 808px;
    --width-desktop: 982px;
}

.container {
    margin-left: var(--small-margin);
    margin-right: var(--small-margin);

    width: 100%;
    justify-content: center;
    display: flex;

    @media (min-width: 768px) {
        margin-left: var(--double-margin);
        margin-right: var(--double-margin);
    }
}

.list {
    --child-padding: 16px;

    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    width: 100%;

    @media (min-width: 768px) {
        width: var(--width-tablet);
        margin-left: var(--child-padding);
    }

    @media (min-width: 1048px) {
        width: var(--width-desktop);
        margin-left: 0px;
    }

    & > * {
        margin-right: var(--child-padding);
        margin-bottom: var(--child-padding);

       
    }

    & > *:last-of-type{
        margin-right: 0px;
        margin-bottom: var(--child-padding);
    }                
}
