@import '../../../style/styles.scss';

.MobileUserTableDeleting {
    z-index: 10001;
    position: absolute;
    width: 100%;
    top: 0;
    padding-bottom: 0px!important;
}

.MobileUserTable{    
    background-color: var(--color-blue95) ;
    border-radius: 0px;
    padding: 40px 24px;
    padding-bottom: 100px;
    // height: 100%;

    .BackButton{
        margin-left: -15px;
    }

    .Header{
        font-size: 24px;
        font-weight: bold;
    }

    .InfoText1{
        margin-top: 16px;
    } 

    .InfoText1, .InfoText2{
        font-size: 14px;
        line-height: 18px;
        font-style: italic;
        a{
            text-decoration: underline;
        }
    }

    .TableControllers{
        margin: 24px 0px 16px 0px;
        display: flex;
        justify-content: space-between;
                
        .SearchFieldContainer {
            background-color: white;
            border-color: #dee7ec !important;
            border-radius: 40px;
            border-width: 2px !important;
            border: solid;
            display: flex;
            flex-direction: row;
            max-height: 48px;
            padding: 2px;
            width: 100%;      
                        
        }
        :has(> .SearchField:focus ) {
            border: 2px dotted var(--color-blue30)!important;
        }
        .SearchField {
            border-radius: 40px;
            border: none;
            color: #2F2F37;
            font-family: $fontFamily;
            font-size: 14px;
            width: 100%;
            ::placeholder{
                color: var(--color-black40);
            }
            
        }

        .SearchIcon {
            margin: 11px 16px 8px 14px;
            width: 16px;
        }
        .MoreOptions{
            margin-right: -16px;
        }

    }
    .OptionsModal{
        width: 200px;
        // height: 100px;
        background-color: white;
        box-shadow: 0px 4px 40px 1px #2B4A6433;
        position: absolute;
        right: 0;
        padding: 8px 0px;
        margin-top: -16px;
        margin-right: 2px;
        border-radius: 8px;
        
        .RemoveUsersToggle{
            color: var(--color-red40);            
        }
        .SortUsersToggle{
            color: var(--color-black20);  
        }
        .RemoveUsersToggle, .SortUsersToggle{            
            width: 100%;
            display: flex;
            padding-top: 16px;
            padding-bottom: 16px;            
            padding-left: 25px;
            align-items: center;            
            border: none;
            font-size: 16px;
            background-color: white;
        }
        .RemoveUsersToggleText, .SortUsersToggleText{
            margin-left: 16px;
        }
    }

    .TableCards{
        padding-bottom: 40px;

        min-height: 700px;
        .Card:has(input[type="checkbox"]:checked){
            background-color: var(--color-blue90);
            border: 2px dotted var(--color-blue30);
        }
        .Card{                        
            margin-top: 8px;
            border: 2px solid var(--color-blue90);
            background-color: white;
            border-radius: 8px;
            padding: 16px;
            display: flex;
            .CheckBox{
                padding: 14px 8px ;                            
                label{
                    margin: 0px;
                    padding: 0px;                    
                }
            }
            
            .UserLogo{
                margin-top: 5px;
                background-color: var(--color-blue90);
                padding: 10px;
                border-radius: 100px;
                height: 32px;
                width: 32px;
                display: flex;
                justify-content: center;
                align-items: center;               
            }
            .UserInfoContainer{
                margin-left: 16px;
                .Name{
                    line-height: 22px;
                    font-size: 16px;
                    font-weight: bold;
                }
                .Email{
                    margin-top: 2px;
                    font-size: 14px;
                    line-height: 22px;
                }
                .MemberSince{
                    margin-top: 8px;
                    line-height: 22px;
                    font-size: 13px;
                    color: var(--color-black40);
                    font-style: italic;
                }
            }
            .DeleteUserInfoContainer{
                margin-left: 0px;
            }
        }
        .DeleteCard:last-of-type{
            margin-bottom: 80px;
        }
        .DeleteCard{
            padding: 16px 16px 16px 0px;

            .DeleteCheckbox{
                padding: 14px 13px!important;

            }
        }
        .CheckedCard{
            background-color: var(--color-blue90);
            border: 2px dotted var(--color-blue30);
        }
    }
   

    .DeleteFooter{
        position: sticky;
        width: 100%;
        bottom: 0;
        display: flex;
        justify-content: space-between;
        background-color: var(--color-blue95);

        .SelectedForDeleteText{
        padding: 30px 0px 30px 48px;
        }
        .RemoveUsersButton{
            color: var(--color-red40);     
            background-color: unset;       
            padding: 29px 49px;
            display: flex;
            align-items: center; 
            font-weight: 600;           
            border: none;
            font-size: 16px;
        }
        .RemoveUsersButtonText{
            margin-left: 8px;
        }
    }

    .SortModalContainer{        
        position: fixed; 
        width: 100%; 
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgb(43, 74, 100, 0.4); 
        z-index: 1001;         
        
        .SortModal{
            bottom: 0;
            opacity: 1;
            position: absolute;
            width: 100%;
            background-color: white;
            border-radius: 32px 32px 0px 0px;
            padding: 32px 48px 40px 48px;
            display: flex;
            flex-direction: column;
            justify-content: center;            
            
            .SortHeader{
                text-align: center;
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 32px;
            }
            .SortOptions{

                .SortOption{
                    color:  var(--color-blue30);
                    font-size: 16px;
                    width: 100%;
                    padding: 13px 24px;
                    border: none;
                    background-color: white;
                    text-align: start;
                    display: flex;
                    align-items: center;
                    font-weight: 600;
                    svg, .IconPlaceholder{
                        height: 8.73px;
                        width: 11.7px;
                        margin-right: 14px;
                    }
                }
            }         
            .ButtonLink{
                margin-top: 24px;
                padding: 10px 0px;
                text-decoration: underline!important;
                border: none;
                background: white;
                color:  var(--color-blue30);
            }      
        }
    }
}