@import '../../style/styles.scss';

.Navbar {
    display: none;

    @media (min-width: $desktop) {
        display: block;
        position: absolute;
        top: 0;
        right: 0;

        a {
            margin-left: $gutter * 2;
            margin-right: $gutter * 5;
        }
    }
}
