@import '../../style/styles.scss';

.loginPage {
    display: flex;
    flex-direction: column;
    text-align: center;
    background-image: url('../../images/bg-desktop-speechbubbles.svg');
    background-repeat: no-repeat;
    background-position-x: right;
    background-size: cover;

    .container {
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        @media (min-width: $tablet) {
            margin-top: 120px;
        }
    }

    .form {
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        .input {
            input {
                border-radius: 40px;
            }
        }

        .button {
            margin-bottom: $gutter * 1.5;
            cursor: pointer;
            width: 311px;
        }
    }

    .register,
    .LoginToPay {
        font-size: 14px;
        @media (min-width: $tablet) {
            margin-top: -8px;
            margin-bottom: 40px;
        }
    }

    .signInSocialMediaWrapper {
        margin-left: auto;
        margin-right: auto;
    }

    @media (max-width: $tablet) {
        background-image: url('../../images/bg-mobile-speechbubbles.svg');
        .title {
            font-size: 24px;
        }
        .container,
        .form {
            margin-top: $gutter * 2;
        }
    }
}

.reset {
    margin: 0;
    margin-bottom: $gutter;
    font-size: 14px;
    align-self: flex-start;
    margin-left: $gutter / 2;
    margin-top: -7px;
}

.LoginDivider {
    display: flex;
    width: 100%;
    margin-top: 8px;
    margin-bottom: 32px;

    hr {
        width: 100%;
        color: #dee7ec;
        height: 1px;
        background: #dee7ec;
        border: 0px; //test
    }

    .LoginDividerText {
        margin-right: 8px;
        margin-left: 8px;
        color: #717179;
        font-size: 14px;
    }
}

.AzureInfoText {
    margin-left: $gutter * 3;
    margin-right: $gutter * 3;
    text-align: start;
    margin-top: -8px;
    font-size: 13px;
    align-self: center;
    color: #717179;
    max-width: 300px;
    margin-bottom: calc(env(safe-area-inset-bottom) + 40px);
}

.LoginLogo > svg {
    height: 20px;
}

.NativeBack {
    position: fixed;
    top: 8px;
    left: 12px;
    z-index: 2000;
}
