@import '../../../../style/styles.scss';

.link {
    margin-top: $gutter;
}

.addProCodeLink {
    margin-top: 0px;
}

.buttonContainer {
    .selectSubButton {
        margin-top: 16px;
        padding-left: 40px;
        padding-right: 40px;
    }
}

.productName {
    font-size: 1.125em;
    font-weight: $weightBold;
}
