@import '../../../../style/styles.scss';

.swiper {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;

    .slide {
        height: 100%;
        display: flex;
    }

    .pagination {
        border: 1px dashed;
        min-width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto
    }

    .disabled {
        display: none;
    }
}
