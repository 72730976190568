@import './../../style/styles.scss';

$borderColor: #dee7ec;
$border: 2px solid $borderColor;
$regularDeckHeight: 91px;

.decksList {
    padding-left: $gutter;
    padding-right: $gutter;
    display: flex;
    flex-wrap: wrap;

    @media (min-width: $tablet) {
        padding-right: 0;
    }

    .item {
        flex-basis: 100%;
        flex-shrink: 0;
        margin-bottom: $gutter;

        @media (min-width: $tablet) {
            flex-basis: 50%;
        }

        @media (min-width: $desktop) {
            flex-basis: 33.3333%;
        }
    }

    .deckCard {
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
        border-radius: 16px;
        background: $white;
        @include solidBorder($borderColor, 16px, 4);
        cursor: pointer;
        min-height: 90px;
        padding-left: $gutter * 2;
        padding-right: $gutter * 2;

        @media (min-width: $tablet) {
            justify-content: center;
            min-height: 120px;
            margin-right: $gutter;
        }

        &:hover {
            background: $hoverBg;
            @include solidBorder($borderColor, 16px, 4);
        }

        &:focus {
            outline: none;
            @include dashedBorder($primaryColor, 16px, 4);
        }

        .bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-position-x: right;
            background-repeat: no-repeat;
            pointer-events: none;

            @media (max-width: $tablet) {
                &.bg1 {
                    background-image: url('./images/deck-card-bg1.svg');
                }

                &.bg2 {
                    background-image: url('./images/deck-card-bg2.svg');
                }
            }
        }

        .textBlock {
            position: relative;
            display: flex;
            flex-direction: column;

            @media (min-width: $tablet) {
                align-items: center;
                text-align: center;
            }

            .title {
                font-size: $fontSizeLg;
                line-height: 1.5;
                font-weight: 700;
            }

            .description {
                display: flex;
                flex-wrap: wrap;
                align-items: baseline;
                color: $primaryTextColor-70;

                .separator {
                    margin: 0 5px;
                }

                .category {
                    text-transform: uppercase;
                    font-size: $fontSizeXs;
                    letter-spacing: 0.8px;
                }
            }
        }

        .arrow {
            svg {
                fill: $borderColor;
            }
        }
    }
}
