@import '../../style/styles.scss';
@import './variables.scss';

$width: $cardWidth;
$widthTablet: $cardWidthTablet;
$widthDesktop: $cardWidthDesktop;

$height: 140px;
$heightTablet: 140px;
$heightDesktop: 140px;

$background-color: #b2ced6;

//PRO VARIABLES FOR FAVOURITES
.card {
    --color-bg-leadership: var(--brown-85);
    --color-bg-team: var(--white-smoke);
    --color-bg-organisation: var(--grey-med);
    --color-bg-innovation: var(--amour);
    --color-bg-governance: var(--bridal-heath);
    --color-bg-sustainability: var(--sustainability);
    --weight-regular-card: var(--weight-regular);
}

.BreakWords{
    word-break:break-all!important;
}

.card {
    height: $height;
    width: $cardWidth;
    padding: 20px 16px;
    border-radius: 16px;
    background-color: $background-color;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-weight: 700;
    text-align: left;
    border: none;
    cursor: pointer;

    @media (min-width: $tablet) {
        height: $heightTablet;
        width: $widthTablet;
    }
    @media (min-width: $desktop) {
        height: $heightDesktop;
        width: $widthDesktop;
    }

    .category {
        text-transform: uppercase;
        letter-spacing: 0.6px;
        font-size: 11px;
        font-weight: $weightRegular;
        line-height: 1rem;
        padding-left: 4px;
        justify-content: space-between;
        display: flex;
        width: 100%;
        color: black!important;
    }
    .CategoryName{
        opacity: 0.7;
    }
}

.newConversationCard {
    background-color: transparent;
    border: #99adbf 2px dotted;
    align-items: center;
    color: #2b4a64;
    font-size: 16px;
}

.userConversationCard {
    background-color: white;
    background-image: linear-gradient(#eef5f5, #d1e4d0);
    align-items: center;
    color: #2b4a64;
    font-size: 16px;
}

.AddIconContainer {
    width: 22.5px;
    height: 22.5px;
}

.PublicIconContainer {
    width: 12px;
    height: 12px;
    color: #2b4a64;    
}

.userConversation1 {
    background-image: linear-gradient(#fce8b3, #f8bbac);
}
.userConversation2 {
    background-image: linear-gradient(#eef5f5, #d1e4d0);
}
.userConversation3 {
    background-image: linear-gradient(#fdeae6, #ebdbeb);
}

.decoration1 {
    background-image: url('../../images/conversation-decoration/1.svg');
    background-repeat: no-repeat;
}
.userConversation1.decoration1 {
    background: url('../../images/conversation-decoration/1.svg') right bottom
            no-repeat,
        linear-gradient(#fce8b3, #f8bbac) left top no-repeat;
}
.userConversation2.decoration1 {
    background: url('../../images/conversation-decoration/1.svg') right bottom
            no-repeat,
        linear-gradient(#eef5f5, #d1e4d0) left top no-repeat;
}
.userConversation3.decoration1 {
    background: url('../../images/conversation-decoration/1.svg') right bottom
            no-repeat,
        linear-gradient(#fdeae6, #ebdbeb) left top no-repeat;
}

.decoration2 {
    background-image: url('../../images/conversation-decoration/2.svg');
    background-repeat: no-repeat;
}
.userConversation1.decoration2 {
    background: url('../../images/conversation-decoration/2.svg') right bottom
            no-repeat,
        linear-gradient(#fce8b3, #f8bbac) left top no-repeat;
}
.userConversation2.decoration2 {
    background: url('../../images/conversation-decoration/2.svg') right bottom
            no-repeat,
        linear-gradient(#eef5f5, #d1e4d0) left top no-repeat;
}
.userConversation3.decoration2 {
    background: url('../../images/conversation-decoration/2.svg') right bottom
            no-repeat,
        linear-gradient(#fdeae6, #ebdbeb) left top no-repeat;
}

.decoration3 {
    background-image: url('../../images/conversation-decoration/3.svg');
    background-repeat: no-repeat;
}
.userConversation1.decoration3 {
    background: url('../../images/conversation-decoration/3.svg') right bottom
            no-repeat,
        linear-gradient(#fce8b3, #f8bbac) left top no-repeat;
}
.userConversation2.decoration3 {
    background: url('../../images/conversation-decoration/3.svg') right bottom
            no-repeat,
        linear-gradient(#eef5f5, #d1e4d0) left top no-repeat;
}
.userConversation3.decoration3 {
    background: url('../../images/conversation-decoration/3.svg') right bottom
            no-repeat,
        linear-gradient(#fdeae6, #ebdbeb) left top no-repeat;
}

.decoration4 {
    background-image: url('../../images/conversation-decoration/4.svg');
    background-repeat: no-repeat;
}
.userConversation1.decoration4 {
    background: url('../../images/conversation-decoration/4.svg') right bottom
            no-repeat,
        linear-gradient(#fce8b3, #f8bbac) left top no-repeat;
}
.userConversation2.decoration4 {
    background: url('../../images/conversation-decoration/4.svg') right bottom
            no-repeat,
        linear-gradient(#eef5f5, #d1e4d0) left top no-repeat;
}
.userConversation3.decoration4 {
    background: url('../../images/conversation-decoration/4.svg') right bottom
            no-repeat,
        linear-gradient(#fdeae6, #ebdbeb) left top no-repeat;
}

.decoration5 {
    background-image: url('../../images/conversation-decoration/5.svg');
    background-repeat: no-repeat;
}
.userConversation1.decoration5 {
    background: url('../../images/conversation-decoration/5.svg') right bottom
            no-repeat,
        linear-gradient(#fce8b3, #f8bbac) left top no-repeat;
}
.userConversation2.decoration5 {
    background: url('../../images/conversation-decoration/5.svg') right bottom
            no-repeat,
        linear-gradient(#eef5f5, #d1e4d0) left top no-repeat;
}
.userConversation3.decoration5 {
    background: url('../../images/conversation-decoration/5.svg') right bottom
            no-repeat,
        linear-gradient(#fdeae6, #ebdbeb) left top no-repeat;
}

.tema {
    background-color: $primaryColor;
}
.familie {
    background-color: #d1e4d0;
}
.familien {
    background-color: #d1e4d0;
}
.family {
    background-color: #d1e4d0;
}
.kollegaer {
    background-color: #f7f3f1;
}
.colleagues {
    background-color: #f7f3f1;
}
.nye-bekjente {
    background-color: #ebdbeb;
}
.new-acquaintances {
    background-color: #ebdbeb;
}
.partner {
    background-color: #fdeae6;
}
.kjaeresten {
    background-color: #fdeae6;
}
.venner {
    background-color: #eef5f5;
}
.friends {
    background-color: #eef5f5;
}
.for-de-minste {
    background-color: #fce8b3;
}
.de-aller-minste {
    background-color: #fce8b3;
}
.the-youngest-ones {
    background-color: #fce8b3;
}
.barn {
    background-color: #fbdbbb;
}
.kids {
    background-color: #fbdbbb;
}
.ungdom,
.youth,
.teenagers {
    background-color: #f8bbac;
}

//PRO CATEGORIES FOR FAVOURITES

.leadership,
.ledelse {
    background: var(--color-bg-leadership);
}

.organisation,
.organisasjon {
    background: var(--color-bg-organisation);
}

.team,
.team {
    background: var(--color-bg-team);
}

.innovasjon,
.innovation {
    background: var(--color-bg-innovation);
}

.governance-and-board-work,
.eierskap-og-styrearbeid {
    background: var(--color-bg-governance);
}

.sustainability,
.bærekraft, 
.viktige-tema,
.important-topics {
    background: var(--color-bg-sustainability);
}
