.Container {
    height: 100%;
    display: flex;
    justify-content: center;
    .Content {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 40px;
    }
}
