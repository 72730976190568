@import '../../../../style/styles.scss';

.cta {
    margin: $gutter * 2.5 0;
}

.page {
    display: flex;
    flex-direction: column;

    .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        background-repeat: no-repeat;
        background-size: 100% auto;
    }
}

.container {
    flex-grow: 1;
    margin-top: $gutter * 2.5;
    display: flex;
    flex-direction: column;
    width: 320px !important;

    @media (min-width: $tablet) {
        margin-top: 200px;
    }
}

.backButton {
    margin-left: -12px;

    @media (min-width: $tablet) {
        align-self: center;
    }
}

.title {
    margin-bottom: 40px;
    font-size: 1.5rem;
    line-height: 1.5;
    font-weight: 700;

    @media (min-width: $tablet) {
        align-self: center;
    }
}


.cta {
    margin-top: $gutter * 2.5;
    margin-bottom: $gutter * 2.5;
}
.details {
    display: block !important;
}
