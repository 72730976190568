@import '../../../style/styles.scss';

.container {
    --primary-color-container: var(--sanjuan);
    --gutter-container: var(--gutter);
    --border-color-container: var(--mystic);
    --navigation-width-container: var(--nav-button-width);
    --navigation-width-container-desktop: var(--navigation-width-container) * 2;
}

.container {
    position: relative;
    width: 100% !important;
    display: flex;

    @media (min-width: 768px) {
        padding-left: var(--navigation-width-container);
        padding-right: var(--navigation-width-container);
    }
    @media (min-width: 1048px) {
        width: 980px + var(--navigation-width-container-desktop) !important;
    }
}

.swiperContainer {
    width: $desktop * 2 !important;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;

    * {
        box-sizing: border-box;
    }
}

.slide {
    height: 140px !important;
    margin-right: 16px;
    width: 150px !important;

    &:first-of-type {
        margin-left: calc($gutter/2);
    }

    @media (min-width: $tablet) {
        &:first-of-type {
            margin-left: 0;
        }

        &:last-of-type {
            margin-right: 0;
        }
    }
}

.showAllSlide {
    border: 1px solid var(--border-color-container);
    color: var(--primary-color-container);
    background: none;
    font-weight: normal;
}
