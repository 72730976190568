@import '../../style/styles.scss';

.toastMessage {
    // background-color: $primaryTextColor;
    color: white;
    width: 100%;    
    padding-top: 10px;
    padding-bottom: 9px;
    font-size: $fontSizeSm;
    @media (min-width: $tablet) {
        font-size: $fontSizeSm;
        margin-bottom: 16px;
        svg {
            margin-right: 8px;
            margin-left: 16px;
        }
    }
    @media (max-width: $tablet) {
        svg {
            margin-left: $gutter * 2;
            margin-right: 8px;
        }
    }
}
@media (max-width: $tablet) {
    main div div header {
        margin-top: 16px !important;
    }
}
