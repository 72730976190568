@import '../../../style/styles.scss';

.InputColors {
    --color-form-label: #1b1c1d;
    --color-form-input-bg: #ffffff;
    --color-form-input-border: #dee7ec;
    --color-form-input-border-error: #f48e75;
    --color-form-input-border-active: #2b4a64;
    --color-form-hint: #2F2F37;
    --color-form-error: #c43f17;
    --color-link: #2b4a64;
    --color-terms: #717179;
    --radius-form-input: 8px;
}

.cta {
    margin: $gutter * 2.5 0;
}

.page {
    display: flex;
    flex-direction: column;

    .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        background-repeat: no-repeat;
        background-size: 100% auto;
    }
}

.container {
    flex-grow: 1;
    margin-top: $gutter * 2.5;
    display: flex;
    flex-direction: column;
    margin: 0 auto !important;
    @media (min-width: $tablet) {
        margin-top: 200px;
    }
}

.formContainer {
    display: flex;

    flex-direction: column;
    .form .div .div .input {
        width: 100% !important;
        background-color: black !important;
    }
    @media (min-width: $tablet) {
        align-items: center;
    }
}

.proCodeInputLabel {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.34px;
    margin-left: 4px;
    margin-bottom: 2px;
}

.proCodeInput {
    padding: 12px 16px 12px 16px;
    border-radius: 8px;
    width: 100%;
    max-width: 311px;
    border: 2px solid #dee7ec;
    &:active,
    &:focus {
        outline: none;
        border: 2px dashed #2b4a64;
    }

    @media (min-width: $tablet) {
        margin: 0 auto;
        width: 311px;
    }
}

.proCodeInputError {
    border: solid 2px #f48e75;
    &:active,
    &:focus {
        border: 2px dashed #f48e75;
    }
}

.errorText {
    font-size: 11px;
    color: #c43f17;
    margin-left: 4px;
    margin-top: 2px;
}

.backButton {
    margin-left: -12px;

    @media (min-width: $tablet) {
        align-self: center;
    }
}

.infoText {
    margin-bottom: 40px;
    margin-top: 41px;
    @media (min-width: $tablet) {
        max-width: 400px;
        text-align: center;
    }
}

.submitCodeButton {
    min-height: 56px !important;
    border-radius: 80px !important;

    @media (min-width: $tablet) {
        width: 320px;
    }
}

.title {
    margin-bottom: 40px;
    font-size: 1.5rem;
    line-height: 1.5;
    font-weight: 700;

    @media (min-width: $tablet) {
        align-self: center;
    }
}

.cta {
    margin-top: $gutter * 2.5;
    margin-bottom: $gutter * 2.5;
}
.details {
    display: block !important;
}
