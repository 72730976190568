:root {
    --color-mystic: #dee7ec;
    --color-alabaster: #f9f9f9;
    --color-jumbo: #717179;
    --color-porcelain: #f4f6f7;
    --color-blue30: #324D67;
    --color-blue80: #BFC8D9;
    --color-blue90: #DFE5EC;
    --color-blue95: #EFF1F5;
    --color-blue98: #F9F9FB;
    --color-brown95: #F6F1EE;
    --color-brown85: #E4D5CD;
    --color-black20: #2F2F37;
    --color-black40: #5E5E6E;
    --color-red40: #BD2C0F;
    --hoverBg: var(--color-blue95);

    --fontSizeLg: 18px;
    --fontSize: 16px;
    --fontSizeSm: 14px;
    --fontSizeXs: 12px;
    --gutter: 16px;
    --doubleGutter: $gutter * 2;
    --primaryColor: #2b4a64;
    --activeColor: #d16747;

    --weightLight: 300;
    --weightRegular: 400;
    --weightMedium: 600;
    --weightBold: 700;

    --white: #ffffff;
    --white-98: #f9f9f9;

    --primaryTextColor: var(--color-black20);
    --primaryTextColor-70: var(--color-black40);
    --textColorGray: var(--color-jumbo);

    --tablet: 768px;
    --desktop: 1048px;

    --fontFamily: 'Source Sans Pro', -apple-system, BlinkMacSystemFont,
        'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
}

$fontSizeLg: 18px;
$fontSize: 16px;
$fontSizeSm: 14px;
$fontSizeXs: 12px;
$gutter: 16px;
$doubleGutter: $gutter * 2;
$primaryColor: #2F2F37;
$activeColor: #d16747;

$weightLight: 300;
$weightRegular: 400;
$weightMedium: 600;
$weightBold: 700;

$white: #ffffff;
$white-98: #f9f9f9;

$primaryTextColor: #2F2F37;
$primaryTextColor-70: #5E5E6E;
$textColorGray: #717179;

$tablet: 768px;
$desktop: 1048px;

$fontFamily: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;

$bgGradientMobile: linear-gradient(180deg, $white 0%, #e3d4ce 100%);
$bgGradientDesktop: linear-gradient(180deg, $white-98 0%, #e3d4ce 100%);
$hoverBg: #EFF1F5;

$bgGradientDarkStart: #417693;
$bgGradientDarkEnd: $primaryColor;

$bgGradientDark: linear-gradient(180deg, $bgGradientDarkStart 0%, $bgGradientDarkEnd 100%);

@function encodecolor($string) {
    @if type-of($string) == 'color' {
        $hex: str-slice(ie-hex-str($string), 4);
        $string: unquote('#{$hex}');
    }
    $string: '%23' + $string;
    @return $string;
}

@mixin dashedBorder($color, $radius, $strokeWidth) {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='#{$radius}' ry='#{$radius}' stroke='#{encodecolor($color)}' stroke-width='#{$strokeWidth}' stroke-dasharray='2 2 2 2' stroke-linecap='butt'/%3e%3c/svg%3e");
}

@mixin solidBorder($color, $radius, $strokeWidth) {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='#{$radius}' ry='#{$radius}' stroke='#{encodecolor($color)}' stroke-width='#{$strokeWidth}' stroke-linecap='butt'/%3e%3c/svg%3e");
}
