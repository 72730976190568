.card {
    --aspect-ratio: 0.6;
    --width-card: var(--card-width);
    --height-card: calc(var(--card-width) * var(--aspect-ratio));
    --width-card-tablet: var(--card-width-tablet);
    --height-card-tablet: calc(var(--card-width-tablet) * var(--aspect-ratio));
}

.card {
    height: var(--height-card);
    width: var(--width-card);
    padding: 20px 16px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-weight: 700;
    text-align: left;
    justify-content: flex-end;
    cursor: pointer;

    @media (min-width: 768px) {
        height: var(--height-card-tablet);
        width: var(--width-card-tablet);
    }
}
