@import '../../../style/styles.scss';

.ChoosePremProd {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 32px;

    .links {
        display: flex;
        justify-content: space-between;
        white-space: nowrap;

        .link {
            text-align: center;
            margin-top: $gutter;
            padding: 0 16px;

            @media (min-width: $tablet) {
                flex-basis: auto;
                margin-right: $gutter * 2;

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
    }
}

.proCodeLabel {
    color: #2b4a64;
    font-family: $fontFamily;
    font-size: 16px;
    background: none !important;
    border: none;
    cursor: pointer;
    padding-top: 0px !important;
    padding-bottom: 32px !important;
    text-decoration: underline;
    flex-wrap: nowrap;
    display: flex;
}

.restorePurchases {
    display: flex;
    justify-content: center;
    margin: 10px 0;
    a {
        text-decoration: underline;
    }
}

.Description {
    max-width: 400px;
    text-align: center;
    font-weight: $weightLight;
    padding: 8px 16px;
}

.Benefits {
    max-width: 400px;
    padding: 16px 16px 32px 24px;
    font-weight: $weightMedium;
}

.Plans {
    min-width: 100%;
    ul {
        display: flex;
        justify-content: center;
        flex-direction: row;
        flex-wrap: nowrap;
        list-style-type: none;
        padding: 0;
        margin-block-start: 0px;
        margin-bottom: 32px;
    }
}

@media (max-width: $tablet) {
    .ChoosePremProd {
        padding-bottom: 24px;

        .Steps {
            display: none;
        }
    }
    .Benefits {
        padding: 16px 28px 32px;
    }

    .Plans ul {
        flex-direction: column;
        align-items: center;
    }
}

.dots {
    position: relative;
    display: flex;
    justify-content: center;
    height: 50px;
}
.dots > div {
    top: -35px;
    position: absolute;
}
