@import '../../../style/styles.scss';

$bgGradient: linear-gradient(180deg, #e3d4ce 0%, $white 100%);
$sectionSpacer: 40px;
$smallerSectionSpacer: 200px;
.ToastContainer{
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
    position: fixed;    
    width: 100%;
    z-index: 9999;
    @media (min-width: 1048px) {        
        align-items: center;
    }
 
}

.Toast{
    display: flex;
    position: -webkit-sticky;
    position: fixed;    
    bottom: 16px;
    margin: 16px 16px 16px 16px;
    margin-bottom: calc(env(safe-area-inset-bottom) + 56px)!important;
    right: 0px;
    left: 0px;
    padding-right: 32px;
    border-radius: 4px;
    align-items: center;
    background-color: var(--sanjuan);
    width: unset!important;
    opacity: 1;
    -webkit-animation: cssAnimation 3s forwards; 
    animation: cssAnimation 3s forwards;
    

    @media (min-width: 1048px) {
        height: 40px;
        border-radius: 40px;
        margin-bottom: 32px!important;
        width: fit-content!important;
        left: unset;
        right: unset;
    }
}

.IOSToast{
    margin-bottom: calc(env(safe-area-inset-bottom) + 56px)!important;
}



.PageLayout{    
        // padding-left: 16px;        
        padding-top: $sectionSpacer;
        padding-bottom: $smallerSectionSpacer;        
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;     
        @media (max-width: 1047px) {
            padding-bottom: 0px;
            padding-top: 0px;
        }
        
    .PageContainer{
        // background-color: black;
        width: 100%;
        @media (min-width: 768px) {
            width: 703px;
        }
        @media (min-width: 1048px) {
            margin-right: 16px;
            width: 980px;
        }

        .BackButton{
            margin-left: -15px;
            @media (max-width: 1047px) {
                margin-left: 17px;                
            }
        }
        .IosBackButton{
            margin-top: 0px!important;
        }
    
        .PageTitle{
            font-size: 24px;    
            font-weight: bold;
            @media (max-width: 1047px) {
                margin-left: 32px;
            }
        }
      
        .CompanyInfoBox{
            margin-top: 40px;
            width: 100%;            
            border: 2px solid var(--color-blue30);
            border-radius: 8px;
            display: flex;
            flex-direction: row;
            padding: 32px 64px;
            justify-content: space-between;   
            align-items: center;          

            .OrganizationLogo{
                width: 150px;                               
            }
            .CompanyCodeContainer{      
                max-width: 200px;          
                .CompanyCodeLabel{
                    font-size: 14px;                
                }
                .CompanyCode{
                    font-size: 18px;
                    font-weight: 600;                    
                    word-break: break-all;
                }
            }
            .CompanyCodeCopyButton{                
                min-height: 48px;
                padding: 0px 24px;
            }
            .UserCounter{
                background-color: var(--color-blue30);
                padding:5px 12px;
                border-radius: 32px;
                color: white;
                display: flex;
                .PersonIcon{
                    margin-right: 8px;
                }
            }

            @media (max-width: 1047px) {
                flex-direction: column;
                padding: 40px 32px;
                margin-left: 24px;
                margin-right: 24px;
                width: auto;    
                text-align: center;
                .CompanyCodeLabel{
                    margin-top: 40px;
                }
                .UserCounter{
                    margin-top: 24px;
                }
                .CompanyCodeCopyButton{
                    margin-top: 24px;
                }
            }
        }

        .UserTableContainer{
            padding-top: 48px;
            .UserTableHeader{
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 16px;

            }
            .UserTable{
                width: 100%;
                border: 2px solid var(--color-blue90);
                border-radius: 8px;
                @media (max-width: 1047px) {
                    border-radius: 0px;
                    border: none;
                }                
            }
        }
    }
}