@import '../../style/styles.scss';

.azureButton {
    span {
        text-align: start;
        padding-left: 40px;
        width: 100%;
        font-weight: 600 !important;
        margin-right: 20px;
        font-size: 14px;
    }
    
    svg {
        width: 20px;
        height: 20px;
        margin-left: -8px;
    }
}
