@import '../../style/styles.scss';

.FavouriteConversationsPageContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 10;

    @media (max-width: $tablet) {
        bottom: calc(env(safe-area-inset-bottom) + 40px);
        // padding: 40px 0px 40px 0px;
        align-content: flex-start;
        width: 100%;
        // padding-left: 20px;
        // padding-right: 20px;
    }
    @media (min-width: $tablet) {
        margin: 136px 0px 0px 16px;
        padding: 0px 30px 40px 30px;
    }
}
.ConversationsContainer{
    width: 350px;
}
.Android {
    padding-left: 14px;
    padding-right: 14px;
}

.BackButtonContainer {
    width: 100%;
    position: sticky;
    top: 0px;
    z-index: 100;
    background: rgba(255, 255, 255, 0.9);
}

.BackButton {
    padding: 8px 16px 8px 2px;
    color: #2b4a64;
    font-family: $fontFamily;    
    margin-bottom: 8px;    
    font-size: 16px;
    background: none !important;
    border: none;
    cursor: pointer;
    margin-top: calc(env(safe-area-inset-bottom) + 40px);
    margin-left: 15px;
    text-decoration: none;
    @media (min-width: $tablet) {
        margin-bottom: 8px;
        padding: 8px 16px 8px 16px;
    }
    @media (min-width: $desktop) {
        margin-left: 0px;
        padding: 10px 15px;
        &:focus,
        &:hover {
            background:rgba(50, 77, 103, 0.05) !important;
            border-radius: 60px !important;
        }
    }
}

.LeftArrow {
    margin-right: 8px;
}

.ConversationCardsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-left: 15px;
}

.FavouriteConversationCard {
    margin-bottom: 15px;
    margin-right: 15px;
    width: 150px;
    &:hover {
        cursor: pointer;
    }
}
.RightCard {
    margin-right: 0px;
}
.LeftCard {
    margin-right: 15px;
}

.OrganizationCard {
    --background-color: var(--tuna);
    --color-organization-card: var(--white);
}

.OrganizationCard {
    color: var(--color-organization-card);
    background: var(--background-color);
    margin-left: 5px;
    .OrganizationCard + .RightCard {
        margin-right: 20px !important;
    }
}
.OrganizationCard.LeftCard {
    margin-left: 5px !important;
    margin-right: 20px !important;    
}

.FavouriteThemeCard {
    margin-left: 5px;    
    .RightCard {
        margin-right: 20px !important;
    }
}
.FavouriteThemeCard.LeftCard {
    margin-left: 5px !important;
    margin-right: 20px !important;
}

.FavouriteConversationsTitle {
    margin-bottom: 40px;
    margin-top: 40px;
    font-size: 1.5rem;
    font-weight: bold;
    margin-left: 15px;
}

.ResultContainer {
    display: flex;
    @media (min-width: $tablet) {
        margin: 0 auto;
        width: 736px;
    }
    @media (min-width: $desktop) {
        margin: 0 auto;
        width: unset;
    }
}

.userCreatedConversation1 {
    background-image: linear-gradient(#fce8b3, #f8bbac);
}
.userCreatedConversation2 {
    background-image: linear-gradient(#eef5f5, #d1e4d0);
}
.userCreatedConversation3 {
    background-image: linear-gradient(#fdeae6, #ebdbeb);
}