@import '../../style/styles.scss';

.passwordResetPage {
    text-align: center;

    .container {
        max-width: 320px;
        margin-top: $gutter * 4;

        @media(min-width: $tablet) {
            margin-top: 200px;
        }
    }

    .description {
        margin: $gutter 0 38px 0;
    }

    .form {
        margin: 0 auto;

        button{
            margin-top: 40px;
        }
    }

}

