@import '../../style/styles.scss';

.googleButton {
    span {
        text-align: start;
        padding-left: 40px;
        width: 100%;
        font-weight: 600 !important;
        margin-right: 20px;
        font-size: 14px;
    }
    
    svg {
        width: 20px;
        height: 20px;
        margin-left: -8px;
    }
}

button.createUser {
    span {
        font-size: 16px;
    }

    svg {
        height: 24;
        width: 23.45px;
        min-width: 23.45px;
        min-height: 24px;
        margin-left: 0px;
    }
}
