.card {
    --color-bg-leadership: var(--brown-85);
    --color-bg-team: var(--white-smoke);
    --color-bg-organisation: var(--grey-med);
    --color-bg-innovation: var(--amour);
    --color-bg-governance: var(--bridal-heath);
    --color-bg-sustainability: var(--sustainability);
    --weight-regular-card: var(--weight-regular);
}

.card {
    background: var(--color-bg-leadership);
}

.category {
    font-weight: var(--weight-regular-card);
    text-transform: uppercase;
    letter-spacing: 0.6px;
    font-size: 11px;
    line-height: 1rem;
    opacity: 0.7;
    padding-left: 4px;
}

.leadership,
.ledelse {
    background: var(--color-bg-leadership);
}

.organisation,
.organisasjon {
    background: var(--color-bg-organisation);
}

.team,
.team {
    background: var(--color-bg-team);
}

.innovasjon,
.innovation {
    background: var(--color-bg-innovation);
}

.governance-and-board-work,
.eierskap-og-styrearbeid {
    background: var(--color-bg-governance);
}

.sustainability,
.bærekraft {
    background: var(--color-bg-sustainability);
}
