@import '../../style/styles.scss';

.collaborationModal {
    background: $bgGradientDark;
    background-size: cover;
    background-position: bottom;
    display: flex !important;
    flex-direction: column;
    height: 100%;
    position: relative;
    width: 100%;
}

.bgDecorator {
    height: auto;
    margin: 0 -100px;
    width: calc(100vw + 200px);
}