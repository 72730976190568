.InputRow {
  --color-form-label: #1b1c1d;
  --color-form-input-bg: #ffffff;
  --color-form-input-border: #dee7ec;
  --color-form-input-border-error: #f48e75;
  --color-form-input-border-active: #2b4a64;
  --color-form-hint: #2F2F37;
  --color-form-error: #c43f17;
  --color-link: #2b4a64;
  --color-terms: #717179;
  --radius-form-input: 8px;
}

.InputRow {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  position: relative;

  input {
    background: var(--color-form-input-bg) 0% 0% no-repeat padding-box;
    padding: 12px;
    margin-bottom: 2px;
  }

  & > span {
    height: 15px;
    margin-bottom: 4px;
    letter-spacing: 0;
    font-size: 0.688rem;
    opacity: 0.7;
  }
}

.InputRow__Label {
  text-align: left;
  letter-spacing: 0.34px;
  color: var(--color-form-label);
  font-size: 0.875rem;
  font-weight: bold;
  margin-bottom: 2px;
}

.InputRow__InputContainer {
  position: relative;
  margin-bottom: 2px;
}

.InputRow__StartIcon {
  position: absolute;
  height: 100%;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.InputRow__Input {
  border: 2px solid var(--color-form-input-border);
  border-radius: var(--radius-form-input);
  min-width: 311px;
  min-height: 48px;

  &:active,
  &:focus {
    outline: none;
    border: 2px dashed var(--color-form-input-border-active);
  }

  &.withStartIcon {
    padding-left: 48px;
  }

  &.invalid {
    border: 2px solid var(--color-form-input-border-error);  

    &:active,
    &:focus {
      border: 2px dashed var(--color-form-input-border-error);
    }
  }
}


// Error styles
.InputRow__Error__Show {
  color: var(--color-form-error);
}

.InputRow__Error__Hidden {
  visibility: hidden;
}

.InputRow__Hint {
  color: var(--color-form-hint);
}

.InputRow__PassToggle__Hidden {
  position: absolute;
  cursor: pointer;
  top: 16px;
  right: 12px;
  background-color: transparent;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
}

.InputRow__PassToggle__Reveal {
  position: absolute;
  cursor: pointer;
  top: 12px;
  right: 11px;
  background-color: transparent;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
}