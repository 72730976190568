@import '../../style/styles.scss';
.BackButton {
    --color-link-bg:rgba(50, 77, 103, 0.05);
    --color-text: #2b4a64;
    --radius-border: 60px;
}

.BackButton {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    outline: none;
    border: none;
    background-color: transparent;
    color: var(--color-text);
    font-size: 1rem;
    margin-top: env(safe-area-inset-top);
    padding: 10px 15px;
    max-height: 40px;
    span {
        margin-left: 8px;
    }

    @media (min-width: $desktop) {
        &:focus,
        &:hover {
            background: var(--color-link-bg);
            border-radius: var(--radius-border);
            cursor: pointer;
        }
    }
}
