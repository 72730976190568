@import '../../style/styles.scss';

.header {
    position: relative;
    margin-top: $gutter * 2.5;
    margin-bottom: $gutter * 2.5;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .title {
        font-size: 1.5rem;
        line-height: 2rem;
        font-weight: 700;
        flex-grow: 1;

        @media (min-width: $tablet) {
            text-align: center;
        }
    }

    .button {
        @media (min-width: $tablet) {
            position: absolute;
            right: 0;
            top: 0;
        }
    }
}

.container {
    background-image: url('../../images/bg-mobile-emptystate.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center bottom;
    width: 100%;
    position: absolute;
    height: calc(100% - 48px);
    display: flex;
    flex-direction: column;
    padding-left: $gutter * 2;
    padding-right: $gutter * 2;

    @media (min-width: $tablet) {
        padding-left: 0;
        padding-right: 0;
    }
}

.content {
    margin-top: 30%;
    text-align: center;
    font-style: italic;
    color: #717179;
    line-height: 24px;
}
