@import '../../../style/styles.scss';
@import './variables.scss';

$borderColor: #dee7ec;
$navigationWidth: $navButtonWidth;

.container {
    position: relative;
    width: 100% !important;
    display: flex;

    @media (min-width: $tablet) {
        padding-left: $navigationWidth;
        padding-right: $navigationWidth;
    }
    @media (min-width: $desktop) {
        width: 980px + $navigationWidth * 2 !important;
    }
}

.swiperContainer {
    width: $desktop * 2 !important;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    
    * {
        box-sizing: border-box;
    }
}

.slide {
    height: 140px !important;
    margin-right: $gutter;
    width: 150px !important;

    &:first-of-type {
        margin-left: calc($gutter/2);
    }

    @media (min-width: $tablet) {
        &:first-of-type {
            margin-left: 0;
        }

        &:last-of-type {
            margin-right: 0;
        }
    }
}

.showAllSlide {
    background: none;
    border: 1px solid $borderColor;
    color: $primaryColor;
    font-weight: normal;
}
