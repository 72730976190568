.Group {
    --margin-right-group: var(--gutter);
    --tablet: var(--tablet);
    --desktop: var(--desktop);
    --borderColor: var(--mystic);
}

.Group {
    display: flex;
    flex-wrap: wrap;
    padding-top: 48px;
    flex-basis: 100%;

    &:last-of-type {
        padding-bottom: 48px;
    }

    .Title {
        font-size: 18px;
        flex-basis: 100%;
        font-weight: 700;
    }
}

.Subgroup {
    display: flex;
    flex-wrap: wrap;
    padding-top: 8px;
    padding-bottom: 48px;
    flex-basis: 100%;

    &:last-of-type {
        padding-bottom: 0;
    }

    .Title {
        font-size: 18px;
        flex-basis: 100%;
        font-weight: normal;
    }
}
