@import '../../style/styles.scss';

.bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% + 60px);
    pointer-events: none;
    z-index: -1;
    background-color: var(--pampas);
}

.BackButtonContainer{
    margin-top: 80px;    
}

.backButton{
    margin-left: -14px;
    &:hover{
        background-color: rgba(43, 74, 100, 0.05);
        border-radius: 200px;
    }
}

.title{
    font-weight: var(--weight-bold);
}

.userCreatedConversation1 {
    background-image: linear-gradient(#fce8b3, #f8bbac)!important;
}
.userCreatedConversation2 {
    background-image: linear-gradient(#eef5f5, #d1e4d0);
}
.userCreatedConversation3 {
    background-image: linear-gradient(#fdeae6, #ebdbeb);
}


.customRadios {
    margin-top: 40px;
    margin-bottom: 80px;
    div {
        display: inline-block;
    }

    div:last-of-type button {
        margin-right: 0px;
    }
    div:last-of-type label button {
        margin-right: 0px !important;
    }

    input[type='radio'] {
        display: none !important;

        + label {
            button {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 40px;
                height: 40px;
                margin: -1px 4px 0 0;
                vertical-align: middle;
                cursor: pointer;
                border-radius: 8px;
                border: 1px solid #99adbf;
                margin-right: 16px;
                background-repeat: no-repeat;
                background-position: center;
                text-align: center;
                line-height: 44px;
                .IconCheck {
                    opacity: 0;
                    transition: all 0.3s ease;
                    width: 23px;
                    margin-right: 2px;
                }
            }
        }

        &:checked + label button {
            border: 2px solid #2b4a64;
        }

        &.color1 + label button {
            background: linear-gradient(#fce8b3, #f8bbac) left top no-repeat;
        }

        &.color2 + label button {
            background: linear-gradient(#eef5f5, #d1e4d0) left top no-repeat;
        }

        &.color3 + label button {
            background: linear-gradient(#fdeae6, #ebdbeb) left top no-repeat;
        }

        &:checked + label button .IconCheck {
            opacity: 1;
        }
    }
}

.EditNameAndDescriptionContainer{ 
    margin-left:20px;
    margin-right:20px;

    @media (min-width: $tablet) {
        align-items: center;
        display: flex;
        flex-direction: column;;
    }  

    .Container{

        @media (min-width: $tablet) {        
            min-width: 700px;
        }
        @media (min-width: $desktop) {        
        max-width: 980px;
        min-width: 980px;
        }
    }
    
    .InnerContainer{
        margin-top: 80px!important;
        width: fit-content;
        margin: auto;
    }
    
    .CreateConversationPage {
        margin: 0px 16px 0px 16px;
        display: inherit;
        display: flex;
        flex-direction: column;
        align-items: center;        
    }

    .FieldContainer{
        display: flex;
        flex-direction: column;    
    }
    .InvalidInput {
        border: #F48771 2px solid !important;
    }

   


    input,
    textarea {
        border: #e3d4ce 1px solid;
        border-radius: 8px;
    }

    .TitleLabel {
        font-size: 14px;
        font-weight: $weightMedium;
        margin-bottom: 2px;
        margin-left: 4px;
    }

    .titleField,
    .descriptionField {
        min-width: 296px;
        padding: 12px 16px;
        font-family: $fontFamily;
        font-size: 16px;
    }

    .titleField {
        min-height: 48px;
    }

    .DescriptionLabel {
        margin-top: 8px;
        font-size: 14px;
        font-weight: $weightMedium;
        margin-bottom: 2px;
        margin-left: 4px;
    }
    .descriptionField {
        height: 86px;
        resize: none;
    }
    .descriptionField:focus-visible {
        outline: 0;
    }

    .TitleErrorAndCounter{
        display: flex;
        justify-content: space-between;
        
        .TitleErrorText{
            font-size: 11px;
            color: #BD2C0F;
            margin-top: 4px;
            font-size: 12px;
        }
    }
    
    .Counter {
        color: #5E5E6E;
        display: flex;
        font-size: 12px;
        justify-content: flex-end;
        margin-right: 4px;
        margin-top: 2px;
        
    }
    .ButtonContainer {
        display: flex;
        justify-content: center;
        .SaveButton {
            margin-top: 51px;
            max-width: 218px;
            max-height: 56px;
        }
    }
}
