@import '../../style/styles.scss';
.SearchQuestionCardContainer {
  border-radius: 8px;
  margin-bottom: 8px;
  width: 100%;
  display: flex;
  @media (min-width: $tablet) {
    margin: 0px 8px 8px 0px;
    // max-width: 314px;
    min-width: 314px;
  }

  background-color: #f7f3f1 !important;
}
.SearchQuestionCard {
  background-color: #f7f3f1;
  border-radius: 8px 0px 0px 8px;
  border: none;
  color: $primaryTextColor;
  cursor: pointer;
  display: flex;
  font-family: $fontFamily;
  font-size: 18px;
  font-weight: 500;
  justify-content: space-between;
  letter-spacing: 0px;
  min-height: 56px;
  outline: none;
  text-align: left;
  width: 100%;
  z-index: 98;
  @media (min-width: $tablet) {
    &:hover,
    &:hover + .SearchHeartContainerContainer,
    &:hover + .SearchHeartContainerContainer div {
      background-color: #ede3df !important;
    }
  }
}

.SearchQuestionText {
  align-self: center;
  padding: 16px 12px 16px 16px;
}

.SearchHeartContainer:focus {
  border: none !important;
  outline: 0;
}

.SearchHeartContainerContainer {
  padding-right: 5px;
  border-radius: 0px 8px 8px 0px;
  display: flex;
  align-content: center;
  background-color: #f7f3f1 !important;
}
.SearchHeartContainer {
  align-items: center;
  // background-color: white;
  border-radius: 0px 8px 8px 0px;
  border: none;
  display: flex;
  justify-content: center;
  min-height: 50px !important;
  min-width: 50px !important;
  width: 18px !important;
  cursor: pointer;

  @media (min-width: $tablet) {
    &:hover,
    &:hover + .SearchQuestionText {
      border-radius: 100px;
      align-self: center;
      background-color: #ede3df !important;
    }
  }
}

.outLineHeartIcon {
  background-image: url('../../images/icons/heart-outline.svg');
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 2px;
  margin-left: 1px;
  min-height: 16px !important;
  min-width: 18px !important;
  z-index: 99;
}

.redHeartIcon {
  background-image: url('../../images/icons/red-heart.svg');
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 2px;
  margin-left: 1px;
  min-height: 16px !important;
  min-width: 18px !important;
  z-index: 99;
}
