@import '../../../style/styles.scss';

$borderColor: #dee7ec;

.swiperContainer {
    padding-left: $gutter;
    padding-right: $gutter;
    padding-bottom: $gutter * 2;
    margin-top: 8px;

    button {
        white-space: nowrap;
    }

    .featuredConversationBtn {
        background: none;
        border: none;
    }

    .pagination {
        position: static;
        font-size: 8px;
        height: 8px;
        width: fit-content !important;
        margin: auto;
        margin-top: $gutter;

        .bullet {
            margin: 0 4px;
            width: 8px;
            height: 8px;
            display: inline-block;
            border-radius: 100%;
            background: $borderColor;
            cursor: pointer;
        }
    
        .bulletActive {
            opacity: 1;
            background: $primaryColor;
            cursor: default;
        }
    }

}
