@import '../../style/styles.scss';

.errorPage {
    min-height: 100%;
    display: flex;
    flex-direction: column;

    background-image: url('./images/bg-mobile-error.svg');
    background-repeat: no-repeat;
    background-position-y: bottom;
    background-size: 100% auto;
    background-attachment: fixed;

    @media (min-width: $desktop) {
        background-image: url('./images/bg-desktop-error.svg');
    }

    .container {
        position: relative;
        max-width: 500px;
        margin: auto;
        padding: 80px 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        line-height: 1.5;
        text-align: center;

        .button {
            margin-top: $gutter * 3;
            margin-left: auto;
            margin-right: auto;
            background: $white;
            min-width: 200px;
        }

    }
}