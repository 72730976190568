:root {
    --frame-max-width: min(570px, 90%);
}

.BamboraInline {
    display: flex;
    justify-content: center;
    height: 100%;
}

iframe {
    max-width: var(--frame-max-width);
}
