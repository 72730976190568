@import '../../../style/styles.scss';

.CompanySignupForm {
    --color-form-label: #1b1c1d;
    --color-form-input-bg: #ffffff;
    --color-form-input-border: #dee7ec;
    --color-form-input-border-error: #f48e75;
    --color-form-input-border-active: #2b4a64;
    --color-form-hint: #2F2F37;
    --color-form-error: #c43f17;
    --color-link: #2b4a64;
    --color-terms: #717179;
    --radius-form-input: 8px;
}

.Circle {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    padding: 3px 11px 4px 11px;
    background: var(--color-black20);
    color: white;      
    text-align: center;
    justify-content: center;
    font-size: 20px;
}

.CompanySignupForm {    
    margin-bottom: 24px;    
    
    .LeftContainer{
        display: flex;
        @media (max-width: 1047px) {
            flex-direction: column;
            width: 100%;            

            .HeaderContainerMobile{
                display: flex;

                h2{
                    font-size: 20px;
                    line-height: 30px;
                    margin-left: 24px;
                }
            }
        }
    }

    .CollapsedContainer{
        border: 2px solid var(--color-brown85);
        border-radius: 16px;
        padding: 44px 32px 40px 32px;      
        display: flex;
        justify-content: space-between;

        @media (max-width: 1047px) {
            margin-left: 16px;
            margin-right: 16px;
            padding: 32px 0px 24px 24px;

            .LeftLeftContainer{            
                display: flex;
                h2 {
                    font-size: 20px;
                    line-height: 30px;
                    margin-left: 24px;
                }
            }      
            
            .RightContainer{                
                .ButtonLink{
                    text-decoration: none!important;
                    font-size: 14px;                    
                }                          
            }
        }
        .LeftMiddleContainer{
            display: flex;
            flex-direction: column;
            margin-left: 24px;
            h2{
                color: var(--color-black20);
                font-weight: bold;                
                line-height: 2rem;
                font-size: 24px;
                margin-bottom: 0px;
            }
            .CompanyInfoContainer{
                margin-top: 24px;
                line-break: anywhere;
                .CompanyInfoName{
                    font-weight: bold;
                }
                .CompanyInfoAddress{
                    margin-top: 4px;
                }            
                @media (max-width: 1047px) {
                    margin-top: 0px;
                    margin-left: 32px;
                }
            }            
        }

        .RightContainer{
            margin-top: 6px;
            .ButtonLink{
                text-decoration: none;
                padding: 24px;            
                font-weight: 600;
            }
        }
    }

    .ExpandedContainer{
        display: flex;
        background-color: white;
        border: 2px solid var(--color-black20);
        border-radius: 16px;
        padding: 44px 88px 56px 32px;      
        justify-content: space-between;
       
        @media (max-width: 1047px) {
            padding: 24px 40px 32px 40px;
            width: 100%;
        }
       
        .LeftLeftContainer{
            @media (max-width: 1047px) {
            display: flex;
                h2 {
                    font-size: 20px;
                    line-height: 30px;
                    margin-left: 24px;
                }
            }
                     
        }
        
        .LeftMiddleContainer{
            display: flex;
            flex-direction: column;
            margin-left: 24px;

            @media (max-width: 1047px) {
                margin: 0px;
                // width: 100%;
            }

            h2{
                color: var(--color-black20);
                font-weight: bold;                
                line-height: 2rem;
                margin-bottom: 0px;
                font-size: 24px;
            }

            .LoginContainer{
                display: flex;            
                margin-bottom: 40px;
                margin-top: 40px;

                .GoogleRegisterButton button{                
                    max-width: 311px;
                    width: 226px;
                    max-height: 80px;
                    border-radius: 8px;
                    color: #2F2F37;
                    border: 2px solid #dee7ec !important;
                    padding: 14px 24px 14px 15px !important;                                

                    span {
                        padding: 1px 1px 1px 13.5px !important;
                        margin: 0px;
                        font-size: 14px!important;
                    
                        @media (min-width: $desktop) {
                            white-space: nowrap;
                        }
                    }
                }

                .HasAccount{                
                    align-self: center;
                    font-size: 14px;
                    margin-left: 24px;
                }
            }
            
            .CompanyNameContainer{
                div div, div div input {
                    width: 100%;
                    max-width: 462px !important;
                }
                @media (max-width: 1047px) {
                    flex-direction: column;
                    width: 100%;
                   
                    div div input{  
                        width: 100%!important;
                        min-width: unset;
                        &:first-child{
                            margin-right: 0px;
                        }
                    }
                }    
            }
           
            .OrganizationNumber{
                div div, div div input {
                    width: 100%;
                    max-width: 462px !important;                    
                }
                @media (max-width: 1047px) {
                    div div, div div input {
                        width: 100%;
                        min-width: unset;
                        max-width: 432px !important;
                    }
                    flex-direction: column;
                    width: 100%;
                   
                    div div input{  
                        width: 100%!important;
                        &:first-child{
                            margin-right: 0px;
                        }
                    }
                }   
            }

            .SchoolCheckBoxButton:hover{
                background-color: white!important;            
            }

            .SchoolCheckBoxButton{
                display: flex;
                border: none;
                align-self: start;
                padding-left: 0px;     
                min-height: 40px;
                text-align: start;
                font-weight: 400;
                span{
                    padding: 0px;
                }
                svg {
                    height: 20px;                    
                }
                               
                .SchoolCheckboxLabel{
                    color: var(--color-black40);
                    line-height: 24px;
                    margin-left: 8px;                    
                }
                @media (max-width: 1047px) {
                    padding-top: 2px;
                    padding-right: 0px;                    
                    p{
                        font-size: 14px;
                    }
                }
            }

            .FormHeader{
                color: var(--color-black20);
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 24px;
                 
            @media (max-width: 1047px) {
                align-self: flex-start;
            }
            }

            .AddressContainer{
                margin-top: 50px;
                
                @media (max-width: 1047px) {
                    width: 100%;

                    div div, div div input{  
                        width: 100%!important;
                        min-width: unset;
                        
                    }
                }

                .StreetContainer{   
                    .AddressLabel{
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 17.5px;
                        margin-bottom: 2px;

                    }                            
                    textarea{
                        border: 2px solid var(--color-form-input-border);
                        border-radius: var(--radius-form-input);
                        max-width: 462px;                        
                        width: 100%;
                        margin-bottom: -4px;
                        min-width: 224px!important;
                        height: 80px;     
                        min-height: 48px;
                        padding: 12px 16px 12px 8px;
                        font-family: Arial, Helvetica, sans-serif;
                        resize: vertical;
                        &:active,
                        &:focus {
                        outline: none;
                        border: 2px dashed var(--color-form-input-border-active);
                        }
                        
                        &.invalid {
                            border: 2px solid var(--color-form-input-border-error);  

                            &:active, &:focus {
                                border: 2px dashed var(--color-form-input-border-error);
                            }
                        }
                    }                    
                }
               
                .PostalCodeContainer{     
                    margin-top: 20px;               
                    display: flex;
                    flex-direction: row;
                   
                    div div input{                
                        width: 224px!important;
                        min-width: 224px!important;

                        &:first-child{
                            margin-right: 16px;
                        }
                    }  

                    @media (max-width: 1047px) {
                        flex-direction: column;
                        width: 100%;
                        div div, div div input{  
                            width: 100%!important;
                            
                        }
                    }
                                  
                }
            }
          
            .CountryContainer{
                display: flex;
                flex-direction: column;        
                max-width: 462px !important;       
                .CountrySelectLabel{
                    text-align: left;
                    letter-spacing: 0.34px;
                    color: var(--color-form-label);
                    font-size: 0.875rem;
                    font-weight: bold;
                    margin-bottom: 2px;
                }              

                
                .CountrySelect{
                    border: 2px solid var(--color-form-input-border);
                    border-radius: var(--radius-form-input);                    
                    min-width: 224px!important;
                    min-height: 48px;
                    padding: 12px 16px 12px 8px;                                  
                    margin-bottom: 2px;                    
                    
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                    background-image: url('./chevron_down.svg');
                    background-repeat: no-repeat;
                    right: 0;
                    background-position: right;
                    background-position-x: calc(100% - 20px);
                    background-color: unset;
                    color: var(--color-black20);

                    option{
                        padding: 20px!important;                                                
                    }

                    &:active,
                    &:focus {
                    outline: none;
                    border: 2px dashed var(--color-form-input-border-active);
                    }
                
                    &.invalid {
                    border: 2px solid var(--color-form-input-border-error);  
                
                    &:active,
                    &:focus {
                        border: 2px dashed var(--color-form-input-border-error);
                    }
                    }
                    @media (max-width: 1047px) {
                        width: 100%!important;                        
                    } 

                }
            }

            .NextButton{
                margin-top: 55px;
                width: 126px;
                @media (max-width: 1047px) {
                    align-self: start;
                }
            }
        }

        .RightContainer{
            // width: 50px;

        }
        
        a {
            text-decoration: underline;
            &:hover,
            &:focus,
            &:active,
            &:visited {
                color: var(--color-link);
            }
        }

        form {
            // max-width: 311px;
            min-width: 100px!important;
            display: flex;
            justify-content: center;
            flex-direction: column;
            margin-top: 40px;

            .createAccountButton {            
                min-height: 56px;
                width: 126px;
            }

            @media (max-width: 1047px) {
                width: 100%;
                padding: 0px
            }
        }
    }
}

.HasAccount {
    // display: none;
}

.Form__Info {
    color: var(--color-terms);
    text-align: center;
    font-size: 0.875rem;
    margin: 48px 0;
}

.Form__Terms {
    // margin-bottom: 16px;
    text-align: start;
    
    
}

.Form__Actions {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}

// Form controls
.Form__Field {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
    position: relative;

    .inputField input {
        background: var(--color-form-input-bg) 0% 0% no-repeat padding-box;
        padding: 12px;
        margin-bottom: 2px;        
        
    }

    & > span {
        height: 15px;
        margin-bottom: 4px;
        letter-spacing: 0;
        font-size: 0.688rem;
        opacity: 0.7;
    }
}

.Form__Label {
    text-align: left;
    letter-spacing: 0.34px;
    color: var(--color-form-label);
    font-size: 0.875rem;
    font-weight: 600;
    margin-bottom: 2px;
}

.Form__Input {
    border: 2px solid var(--color-form-input-border);
    border-radius: var(--radius-form-input);
    // min-width: 311px;
    min-width: 100px!important;
    min-height: 48px;

    &:hover,
    &:active,
    &:focus {
        outline: none;
        border: 2px dashed var(--color-form-input-border-active);
    }
}

.Form__Input__Error {
    border: 2px solid var(--color-form-input-border-error)!important;

    &:hover,
    &:active,
    &:focus {
        outline: none!important;
        border: 2px dashed var(--color-form-input-border-error)!important;
    }
}

.Form__Placeholder {
    color: var(--color-form-hint);
}

// Error styles
.Form__Error__Show {
    color: var(--color-form-error);
    font-size: 0.688rem;
    margin-bottom: 4px;
    opacity: 0.7;
}

.Form__Error__Hidden {
    visibility: hidden;
}

.Form__PassToggle__Hidden {
    position: absolute;
    top: 36px;
    right: 12px;
    background-color: transparent;
    border: none;
    outline: none;
    margin: 0;
    padding: 0;
}

.Form__PassToggle__Reveal {
    position: absolute;
    top: 32px;
    right: 11px;
    background-color: transparent;
    border: none;
    outline: none;
    margin: 0;
    padding: 0;
}

.ButtonLink {
    cursor: pointer;
    border: none;
    background-color: transparent;
    color: var(--color-link);
    text-decoration: underline;
    margin: 0;
    padding: 0;
    font-size: inherit;
}

@media (max-width: 1047px) {
    .Steps {
        display: none;
    }

    .HasAccount {
        display: inline;
        margin: 24px 0 12px 0;
        font-size: 0.875rem;
    }

    .CompanySignupForm {
        justify-content: flex-start;

        form {
            min-width: 100%;
            padding: 32px 36px;
            justify-content: flex-end;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 32px 32px 0 0;
            background-color: var(--color-form-input-bg);
        }
    }
}
