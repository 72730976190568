@import '../../../style/styles.scss';

.LogoSection {
    --font-color-logosection: var(--tuna);
    --margin-top-logosection: 42px;
    --margin-top-logosection-tablet: 40px;
    --margin-bottom-title: 40px;
    --font-size-title: 40px;
    --font-weight-logosection: var(--weight-extra-bold);
    --font-size-logosection: 1.5rem;
}

.LogoSection {
    color: var(--font-color-logosection);
    margin-top: var(--margin-top-logosection);
    font-size: var(--font-size-logosection);
    font-weight: var(--font-weight-logosection);
    display: flex;
    justify-content: center;
    @media (min-width: 768px) {
        margin-top: var(--margin-top-logosection-tablet);
    }
}

.Container {
    --gutter-container: var(--gutter);
    --gutter-container-tablet: var(--gutter);
    --tablet-container: var(--tablet);
    --desktop-container: var(--desktop);
    --margin-bottom-container: var(--triple-gutter);
}
.Container {
    margin-bottom: var(--margin-bottom-container);
    margin-top: -24px;
    padding-left: var(--gutter-container);
    @media (min-width: 768px) {
        margin-top: 0px;
        padding-left: var(--gutter-container-tablet);
    }
}

.Title {
    font-size: 24px;
    font-weight: bold;
    padding-right: 32px;
    margin-top: -16px;
    margin-bottom: 32px;

}

.BackButton {
    padding-top: 0px;
    padding-left: 0px;
    padding: 15px;
    margin-left: -12px;
}

.card {
    margin-right: $gutter;
    margin-top: $gutter;
}


.ConversationCardsOuterContainer{
    display: flex;
    justify-content: center;
    width: 340px;
    @media (min-width: 500px ) {
        width: 505px;
    }
    @media (min-width: 670px ) {        
        width: 670px;
    }
    @media (min-width: 920px ) {
        width: 835px;
    }
    @media (min-width: 1045px ) {
        width: 1005px;
    }
}

.ConversationCardsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 80px;
    @media (max-width: $tablet) {
        justify-content: left;
    }
}


.userCreatedConversation1 {
    background-image: linear-gradient(#fce8b3, #f8bbac);
}
.userCreatedConversation2 {
    background-image: linear-gradient(#eef5f5, #d1e4d0);
}
.userCreatedConversation3 {
    background-image: linear-gradient(#fdeae6, #ebdbeb);
}

.FavouriteThemeCard {
    margin-left: 5px;    
    .RightCard {
        margin-right: 20px !important;
    }
}
.FavouriteThemeCard.LeftCard {
    margin-left: 5px !important;
    margin-right: 20px !important;
}
.OrganizationCard{
    background-color: var(--tuna);
}