@import '../../style/styles.scss';

.container {
    background-attachment: fixed;
    background-image: url('../../assets/images/backgrounds/night/night-bg-mobile.svg'), $bgGradientDark;
    background-position: top;
    background-size: cover;
    display: flex !important;
    flex-direction: column;
    height: 100%;
    min-height: 100%;
    position: relative;
    width: 100%;

    @media (min-width: $tablet) {
        background-image: url('../../assets/images/backgrounds/night/night-bg-desktop.svg'), $bgGradientDark;
        background-position: bottom;
    }
}

.reactCodeInput {
    input {
        -moz-appearance: textfield;
        border: none;
        caret-color: transparent;
        color: var(--primaryTextColor);
        font-size: 32px;
        font-weight: 600;
        height: 64px;
        text-align: center;
        width: 56px;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        &:focus {
            outline: 2px solid;
        }
    }
}

.swiper {
    padding: 0 0 $gutter * 0.25 !important;
    max-width: 100%;
    width: max-content;
}

.swiperSlide {
    width: auto !important;
    
    &:first-child {
        padding-left: $gutter;
    }

    &:last-child {
        padding-right: $gutter;
    }
}

.answerChip {
    max-width: 224px !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    line-height: 16px !important;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px !important;
}

.answerForm {
    display: flex;
    justify-content: center;
}

.closeButton {
    left: 4px;
    padding: 8px;
    position: absolute !important;
    top: calc(env(safe-area-inset-top) + 4px);
    z-index: 20;
    
    @media (min-width: $tablet) {
        top: 8px;
        left: 8px;
    }
}