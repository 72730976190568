.price {
    --margin-top: 20px;

    --font-size: var(--fontSize);
    --font-family: var(--fontFamily);
    --font-weight-bold: var(--weightBold);

    --price-label-color: var(--primaryTextColor);
    --price-text-color: #d95535;

    --line-width: 1px;
    --line-color: lightgrey;
}

.priceLine {
    margin-top: var(--margin-top);
    display: flex;
    justify-content: space-between;
}

.priceLabel {
    font-size: var(--font-size);
    font-family: var(--font-family);
    color: var(--price-label-color);
}

.priceText {
    font-size: var(--font-size);
    font-family: var(--font-family);
    font-weight: var(--font-weight-bold);
    color: var(--price-text-color);
}

.dividerLine {
    height: var(--line-width);
    border: none;
    background-color: var(--line-color);
}
