@import '../../style/styles.scss';

.footer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: $fontSizeSm;

    .links {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @media (min-width: $tablet) {
            flex-wrap: nowrap;
        }

        .link {
            flex-basis: 100%;
            text-align: center;
            margin-top: $gutter;

            @media (min-width: $tablet) {
                flex-basis: auto;
                margin-right: $gutter * 2;

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
    }

    .social {
        margin: $gutter * 2 0;

        a {
            margin-right: $gutter;

            &:last-of-type {
                margin-right: 0;
            }
        }
    }

    .copyright {
        color: $textColorGray;

        @media (min-width: $tablet) {
            margin-bottom: $gutter * 3;
        }
        @media (max-width: $tablet) {
            margin-bottom: 40px;
        }
    }
}
