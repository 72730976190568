.container {
    --border-color-container: var(--mystic);
    --primary-color-container: var(--sanjuan);
    --gutter-container: var(--half-gutter);
    --double-gutter-container: var(--double-gutter);
    --triple-gutter-container: var(--triple-gutter);
    --conversation-width-tablet: 808px;
    --conversation-width-desktop: 1078px;
}

.container {
    margin-top: var(--gutter-container);
    @media (min-width: 768px) {
        width: var(--conversation-width-tablet) !important;
    }
    @media (min-width: 1048px) {
        margin-top: var(--triple-gutter-container);
        width: var(--conversation-width-desktop) !important;
    }
}

.header {
    padding: 0px 0px var(--gutter-container) var(--gutter-container);
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (min-width: 768px) {
        padding-left: var(--double-gutter-container);
        padding-right: var(--double-gutter-container);
    }

    @media (min-width: 1048px) {
        padding-left: var(--triple-gutter-container);
        padding-right: var(--triple-gutter-container);
    }

    .title {
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
    }
}

.showAllSlide {
    border: 1px solid var(--border-color-container);
    background: none!important;
    align-items: center;
    justify-content: center;

    .showAllLink {
        font-weight: normal;
        text-decoration: none;

        svg {
            fill: var(--primary-color-container);
            margin-left: 8px;
        }
    }
}
