@import '../../style/styles.scss';

.PageContainer {
    --color-page-bg-plain: #ffffff;
    --color-page-bg-mobile: #f7f3f1;
}

.PageContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-image: url('../../images/bg-desktop-speechbubbles.svg');
    background-repeat: no-repeat;
    background-position-x: right;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    border: 1px solid transparent;
    z-index: 10;
    padding-top: 80px;
}


@media (max-width: $tablet) {
    .PageContainer {
        background-image: none;
        padding-top: 24px;
    }

    .PlainBg {
        background-color: var(--color-page-bg-plain);
    }

    .ColorBg {
        background-color: var(--color-page-bg-mobile);
    }
}

.container {
    flex-grow: 1;
}

.copy {
    padding-top: 0 !important;
}