@import '../../../style/styles.scss';

$border: 1px solid #dee7ec;

.container {
    padding-top: $gutter * 1.5;
    padding-bottom: $gutter * 1.5;
    border-top: $border;
    border-bottom: $border;
}

.link {
    margin-top: $gutter;
}

.addProCodeLink {
    border-top: 1px solid #dee7ec;
    padding-top: 24px;
}

.productName {
    font-size: 1.125em;
    font-weight: $weightBold;
}
