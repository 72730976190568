$border: 1px solid #dee7ec;

.languageOption {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: $border;
    height: 59px;

    &:first-of-type {
        border-top: $border;
    }
    input {
        width: 14px;
        height: 14px;
        margin-right: 13px !important;
        margin-left: 5px;
    }
    input[type='radio'] {
        opacity: 0;

        + label {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            position: relative;
            cursor: pointer;
            padding-left: 13px;
            font-size: 18px;

            &::before {
                content: '';
                position: absolute;
                left: -24px;

                /* Fixed alignment in some browsers. */
                top: 18px;

                border-radius: 50%;
                border: 2px solid #2b4a64;
                width: 20px;
                height: 20px;
                margin-left: 5px;
            }

            &::after {
                content: '';
                position: absolute;
                left: -15px;
                top: 22px;
                border-radius: 50%;
                width: 12px;
                height: 12px;
            }
        }

        &:checked {
            + label::after {
                background: #2b4a64;

                /* The background is removed in Windows high-contrast mode, so we
           need to set it explicitly here. `WindowText` is a system color
           that should work with whatever high contrast mode the user
           has set. */
                @media screen and (-ms-high-contrast: active) {
                    & {
                        background: WindowText;
                    }
                }
            }
        }
    }
}
