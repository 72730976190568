@import '../../../style/styles.scss';

.PaymentCreateConfirmation {
    background-image: url('../../../images/bg-desktop-speechbubbles.svg');
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 150px;
}

.Content {
    max-width: 276px;
    h1 {
        margin-bottom: 24px;
    }
    & > *:not(:first-child):not(:last-child) {
        margin-bottom: 48px;
    }

    p {
        white-space: pre;
    }
}

.Actions {
    padding: 0 6px;
}

@media (max-width: $tablet) {
    .PaymentCreateConfirmation {
        padding-top: 236px;
    }
}
