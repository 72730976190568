@import '../../style/styles.scss';

$bgGradient: linear-gradient(180deg, #e3d4ce 0%, $white 100%);
$sectionSpacer: 40px;
$smallerSectionSpacer: 200px;
body, html {
    height: 100%;
    margin: 0;
    background-size : cover;
}

.IosPageLayout{
    top: 0px;        
    position: absolute;
    padding-top: 87px!important;
}

.PageLayout{    
        padding-left: 16px;        
        padding-top: $sectionSpacer;        
        padding-bottom: $smallerSectionSpacer;
        margin: 0 auto;        
        background-color: var(--color-brown95);   
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;       
        width: 100%;        
        @media (max-width: $tablet) {
            padding-left: 0px
        };
        
        .ContentContainer{
            display: flex;            
            padding-bottom: 40px;      
             height: 100%;

        .LeftOuterContainer{
            width: 640px;
            height: 731px;
            
            @media (max-width: $tablet) {
                width: 100%;
                height: fit-content;
            };
        
        }
        .RightOuterContainer{
            background-color: white;
            border-radius: 16px;
            margin-left: 40px;
            width: 300px;
            height: fit-content;            
            padding: 44px 48px 24px 48px;

            .YourPriceHeader{
                font-size: 18;
                font-weight: bold;
            }

            .NumberOfUsersInputLabel{
                margin-top: 24px;
                font-weight: 600;
                font-size: 14px;
            }
            .NumberOfUsersError{
                border: 2px solid var(--color-red40);
            }
           
            input{
                width: 204px;
                height: 48px;
                border: 2px solid var(--color-blue90);
                border-radius: 8px;
                padding: 9px 16px;
                font-size: 24px; 
                margin-top: 4px;                
            }

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
            input[type=number] {
                -moz-appearance: textfield;
            }
            
            .DiscountText{
                margin-top: 22px;
                background-color: var(--color-blue95);
                border-radius: 32px;
                width: fit-content;
                padding: 6px 16px;
                font-style: italic;
            }
            
            .PriceBorder{
                border-top: 2px dashed #E4D5CD;
                margin-left: -48px;
                margin-right: -48px;
                margin-top: 40px;
            }

            .PriceContainer{
                
                width: 100%;                
                // border: red solid;
                margin-top: 24px;
                .PriceCurrencyContainer{
                    display: flex;
                    flex-direction: row;
                    margin-top: 32px;
                    // align-self: center;
                
                    .Price{
                        
                        font-weight: bold;
                        font-size: 32px;
                        margin-right: 8px;
                        // justify-content: center;
                    }
                    .PriceCurrency{
                        margin-top: 10px;
                        font-size: 20px;
                    }
                }
                .DiscountPriceText{
                    margin-top: 4px;
                    font-size: 14px;
                    color: #BD2C0F;
                    text-decoration:line-through;
                    display: flex;
                    flex-direction: row;
                    font-style: italic;
                    .FullPrice{
                        margin-right: 8px;
                    }
                }
            }         
        }
    }
    
}

.ToastContainer{
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
    position: fixed;    
    width: 100%;
    z-index: 9999;
    @media (min-width: 1048px) {        
        align-items: center;
    }
 
}

.Toast{
    display: flex;
    position: -webkit-sticky;
    position: fixed;    
    bottom: 16px;
    margin: 16px 16px 16px 16px;
    margin-bottom: calc(env(safe-area-inset-bottom) + 56px)!important;
    right: 0px;
    left: 0px;
    padding-right: 32px;
    border-radius: 4px;
    align-items: center;
    background-color: var(--sanjuan);
    width: unset!important;
    opacity: 1;
    -webkit-animation: cssAnimation 3s forwards; 
    animation: cssAnimation 3s forwards;
    

    @media (min-width: 1048px) {
        height: 40px;
        border-radius: 40px;
        margin-bottom: 32px!important;
        width: fit-content!important;
        left: unset;
        right: unset;
    }
}

.IOSToast{
    margin-bottom: calc(env(safe-area-inset-bottom) + 56px)!important;
}