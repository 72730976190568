@import '../../style/styles.scss';

.title {
    font-size: 1.5rem;
    line-height: 1.3333;
    font-weight: 700;
    margin-bottom: $gutter;

    @media (min-width: $tablet) {
        font-size: 2rem;
        font-weight: 600;
        margin-bottom: $gutter * 1.5;
    }
}