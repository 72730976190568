.ToastContainer{
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
    position: fixed;    
    width: 100%;
    z-index: 9999;
    @media (min-width: 1048px) {        
        align-items: center;
    }
 
}

.Toast{
    display: flex;
    position: -webkit-sticky;
    position: fixed;    
    bottom: 16px;
    margin: 16px 16px 16px 16px;
    margin-bottom: calc(env(safe-area-inset-bottom) + 56px)!important;
    right: 0px;
    left: 0px;
    padding-right: 32px;
    border-radius: 4px;
    align-items: center;
    background-color: var(--sanjuan);
    width: unset!important;
    opacity: 1;
    -webkit-animation: cssAnimation 3s forwards; 
    animation: cssAnimation 3s forwards;
    

    @media (min-width: 1048px) {
        height: 40px;
        border-radius: 40px;
        margin-bottom: 32px!important;
        width: fit-content!important;
        left: unset;
        right: unset;
    }
}

.IOSToast{
    margin-bottom: calc(env(safe-area-inset-bottom) + 56px)!important;
}

.LogoSection {
    --font-color-logosection: var(--tuna);
    --margin-top-logosection: 0px;
    --margin-top-logosection-tablet: 40px;
    --font-weight-logosection: var(--weight-extra-bold);
    --font-size-logosection: 1.5rem;
    color: var(--font-color-logosection);
    margin-top: var(--margin-top-logosection);
    margin-bottom: 40px;
    font-size: var(--font-size-logosection);
    font-weight: var(--font-weight-logosection);
    display: flex;
    justify-content: center;

    img {
        padding-left: 40px;
        padding-right: 40px;
        width: 420px;
        @media (max-width: 768px) {
            width: 320px;
        }
        @media (max-width: 1920px) {
            width: 350px;
        }
    }

    @media (min-width: 768px) {
        margin-top: var(--margin-top-logosection-tablet);
    }
    
}

.ProHome {
    --margin-bottom-pro-home: var(--triple-gutter);
    --padding-d: 0 0 var(--double-gutter) 0;
    --padding-m: 0 0 var(--triple-gutter) 0;
}
.ProHome {
    margin-bottom: var(--margin-bottom-pro-home);
    padding: var(--padding-m);
    justify-content: center;
    @media (min-width: 768px) {
        margin-bottom: 0;
        padding: var(--padding-d);
    }

    .NewOwnerContainer{
        display: flex;
        align-items: center;
        justify-content: center;
        .NewOwnerSplash{        
            border: 2px solid var(--color-blue90);
            border-radius: 16px;            
            // width: 980px;
            margin-right: 4px;
            display: flex;                     

            @media (min-width: 768px) {
                width: 703px;
            }
            @media (min-width: 1048px) {
                width: 980px;
            }
            
            .LeftContainer{
                padding: 56px 50px;
                .NewOwnerImage{
                    background-image: url('./images/baloon.svg');
                    width: 98px;
                    height: 140px;                    
                }
            }

            .RightContainer{                
                display: flex;
                width: 100%;
                justify-content: space-between;
                .NewOwnerContentContainer{                    
                    padding: 40px 0px;

                    .NewOwnerHeader{
                        font-size: 24px;
                        font-weight: bold;
                        margin-bottom: 8px;      
                    }

                    .NewOwnerDescription{
                        font-size: 16px;
                        line-height: 24px;
                    }
                    .PersonOutlined{                        
                        width: 16px;
                        height: 16px;
                        margin-bottom: -3px;
                    }

                    .NewOwnerLowerContainer{
                        margin-top: 40px;
                        border-top: 1px solid var(--color-blue90);
                        padding-top: 24px;
                        display: flex;
                        @media (min-width: 768px) {
                            flex-direction: column;                            
                        }
                        @media (min-width: 1048px) {
                            flex-direction: row;
                        }

                        .CompanyCodeContainer{
                            margin-right: 76px;
                            margin-top: 5px;
                            .CompanyCodeLabel{
                                font-size: 14px;
                                color: var(--color-black40);
                            }
                            .CompanyCode{
                                font-size: 18px;
                                font-weight: 600;
                            }
                           
                        }
                        .CompanyCodeCopyButton{
                            @media (min-width: 768px) {
                                margin-top: 24px;
                                width: fit-content;
                            }
                            @media (min-width: 1048px) {
                                margin-top: 0px;
                            }
                        }
                    }
                }
            
                .CloseButton{
                    right: 0px;
                    margin: 16px;
                }
            }
        }
    }

    .NewOwnerMobileContainer{
        .NewOwnerSplash{
            border: 2px solid var(--color-blue90);
            border-radius: 16px;            
            display: flex; 
            flex-direction: column;
            margin: 0px 24px 34px 24px;            
            
            .TopContainer{
                display: flex;
                padding: 8px 8px 40px 40px;
                justify-content: space-between;

                .LeftContainer{
                    width: 125px;
                    margin-right: 32px;
                    margin-top: 24px;

                    .SplashHeader{
                        font-size: 24px;
                        font-weight: bold;
                    }
                    .SplashDescription{
                        margin-top: 18px;
                        line-height: 24px;
                    }
                }
                .MiddleContainer{
                    margin-top: 48px;
                    background-image: url('./images/baloon_mobile.svg');
                    width: 84px;
                    height: 120px;
                }
                .RightContainer{
                    margin-left: -24px;
                    z-index: 2;
                }
            }

            .BottomContainer{
                background-color: var(--color-blue95);
                border-radius: 0px 16px 16px 0px;
                padding: 32px 26px 38px 26px;
                text-align: center;

                .CompanyCodeLabel{
                    font-size: 14px;
                    color: var(--color-black40);
                }
                .CompanyCode{
                    font-size: 18px;
                    font-weight: 600;
                    margin-bottom: 24px;
                }

                .CompanyCodeCopyButton{
                    // width: 224px;
                }
            }
        }
    }
}
.OuterContentContainer{
    display: flex;
    justify-content: center;
}

.ContentContainer {
    width: 354px;
    @media (min-width: 600px ) {
        width: 505px;
    }
    @media (min-width: 768px ) {
        width: unset;
    }
}

.OrganizationRow,
.ConversationRow {
    --gutter-organization: var(--gutter);
    --gutter-organization-tablet: var(--double-gutter);
}
.OrganizationRow,
.ConversationRow {
    // margin-left: 8px;
    // margin-right: 8px;
    margin-bottom: calc(var(--gutter-organization) * 3);
    @media (min-width: 768px) {
        margin-left: var(--gutter-organization-tablet);
        margin-right: var(--gutter-organization-tablet);
    }
    @media (min-width: 1048px) {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: calc(var(--gutter-organization) * 5);
    }
    justify-content: center;
}

.OrganizationCard {
    --background-color: var(--tuna);
    --color-organization-card: var(--white);
}

.OrganizationCard {
    color: var(--color-organization-card);
    background: var(--background-color);    
}

.userCreatedConversation1 {
    background-image: linear-gradient(#fce8b3, #f8bbac);
}
.userCreatedConversation2 {
    background-image: linear-gradient(#eef5f5, #d1e4d0);
}
.userCreatedConversation3 {
    background-image: linear-gradient(#fdeae6, #ebdbeb);
}
