@import '../../../../style/styles.scss';

.container {
    font-size: 14px;
    padding: $gutter 0;

    @media (min-width: $tablet) {
        padding: $gutter * 2 0;
        margin-left: 35%;
    }

    .separator {
        margin: 0 $gutter / 2;
    }
}
