@import '../../style/styles.scss';

.ToastContainer{
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
    position: fixed;    
    width: 100%;
    z-index: 9999;
    @media (min-width: 1048px) {        
        align-items: center;
    }
 
}

.Toast{
    display: flex;
    position: -webkit-sticky;
    position: fixed;    
    bottom: 16px;
    margin: 16px 16px 16px 16px;
    margin-bottom: calc(env(safe-area-inset-bottom) + 56px)!important;
    right: 0px;
    left: 0px;
    padding-right: 32px;
    border-radius: 4px;
    align-items: center;
    background-color: var(--sanjuan);
    width: unset!important;
    opacity: 1;
    -webkit-animation: cssAnimation 3s forwards; 
    animation: cssAnimation 3s forwards;
    

    @media (min-width: 1048px) {
        height: 40px;
        border-radius: 40px;
        margin-bottom: 32px!important;
        width: fit-content!important;
        left: unset;
        right: unset;
    }
}

.IOSToast{
    margin-bottom: calc(env(safe-area-inset-bottom) + 56px)!important;
}

.BackgroundContainer{
    background-color: var(--color-brown95);   
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;       
    margin: 0 auto;
    width: 100%;     
    height: 100vh;
    top: 0;
    position: fixed;
}

.ButtonLink {
    cursor: pointer;
    border: none;
    background-color: transparent;
    color: var(--color-blue30);
    text-decoration: underline;
    margin: 0;
    padding: 0;
    font-size: inherit;
    margin-top: 24px;
}

.PageLayout{                    
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 240px;

        h2{
            font-weight: bold;
        }

        @media (max-width: 1047px) {
            padding-left: 0px;
            margin-top: 80px;
        };


        .Instruction1{
            display: flex;
            margin: 24px 40px 0px 40px;            
            .Email{
                margin-left: 4px;
                font-weight: 600;
            }
            @media (max-width: 1047px) {
                flex-direction: column;
                .Email{
                    text-align: center;
                }
            }
        }

    .ChangeEmailFieldOuterContainer{
        // width: 100%;
        align-items: center;
        align-content: center;
        display: flex;
        flex-direction: column;
        margin-left: 24px;
        margin-right: 24px;
    }
    .ChangeEmailFieldContainer {
        background-color: white;
        border-color: #dee7ec !important;
        border-radius: 40px;
        border-width: 2px !important;
        border: solid;
        display: flex;
        flex-direction: row;
        max-height: 56px;
        padding: 2px;
        width: 500px;
        height: 56px;
        margin-top: 56px;
        margin-right: 40px;
        margin-left: 40px;
        @media (max-width: 1047px) {
            // width: 500px;
            // height: 56px;
            // margin-bottom: 48px;
            
            width: 100%;
            // min-width: unset!important;
        }    
    }
    .EmailError {
        font-size: 11px;
        color: #c43f17;
        margin-top: 4px;
        padding-left: 84px;
        @media (max-width: 1047px) {
            padding-left: 0px;
        }
    }
    .ErrorField {
        border-color: #d95535 !important;
    }

    .ChangeEmailField {
        border-radius: 40px;
        border: none;
        color: #2F2F37;
        font-family: $fontFamily;
        font-size: 16px;
        width: 100%;   
        padding-left: 24px;
    }
    ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #717179;
        opacity: 1; /* Firefox */
        font-style: italic;
        // padding-left: 24px;
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #717179;
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #717179;
    }

    .ChangeEmailField:focus,
    input:focus {
        outline: none;
    }
    .ChangeEmailButton {
        background-color: #d1e4d0;
        border-radius: 60px;
        border: none;
        color: #2F2F37;    
        font-size: 14px;
        padding: 10px 24px 9px 24px;
        cursor: pointer;
        // max-width: 250px;
        // width: max-content;
        width: 138px;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 1047px) {
            margin-top: 16px;
            width: 118px;
        }
        
    }    
}
.PageLayoutIOS{
    margin-top: 120px;
}

