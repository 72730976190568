@import '../../style/styles.scss';

.PageContainer {
    @media (max-width: $tablet) {
        bottom: calc(env(safe-area-inset-bottom) + 40px);
        align-content: flex-start;
        width: 100%;
    }
    @media (min-width: $tablet) {
        margin: 96px 0px 0px 0px;
        padding: 0px 30px 40px 30px;
    }
}


