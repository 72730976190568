@import '../../../../style/styles.scss';

.apple-pay-button {
    width: 311px;
    height: 48px;
    border-radius: 24px;
    display: inline-block;
    &:disabled {
        opacity: 0.3;
    }
}

.apple-pay-button-with-text {
    background-color: black;
    color: white;
    padding: 0px;
    font-family: -apple-system;
    font-size: 18px;
    font-weight: 300;

    text-align: center;
    line-height: 10px;
}

.GooglePayButton {
    font-family: Roboto, 'Noto Sans', 'Noto Sans JP', 'Noto Sans KR',
        'Noto Naskh Arabic', 'Noto Sans Thai', 'Noto Sans Hebrew',
        'Noto Sans Bengali', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: white;
    box-sizing: border-box;
    border: 0;
    outline: none;
    letter-spacing: 0px;
    max-height: 56px;
    min-height: 40px;
    min-width: 311px;
    border-radius: 4px;
    padding: 12px 36px 10px 36px;
    background-color: black;
    &:focus {
        outline: none;
    }
}
.GooglePayIcon {
    &:focus {
        outline: none;
    }
}

.dots {
    position: relative;
    display: flex;
    justify-content: center;
}
.dots > div {
    top: -35px;
    position: absolute;
}
