@import '../../../style/styles.scss';

$border: 1px solid #dee7ec;

.container {
}

.link {
    font-size: $fontSizeLg;
    line-height: 1.3333;
    font-weight: normal;
    padding-top: $gutter;
    padding-bottom: $gutter;
    border-top: $border;

    &:last-of-type {
        border-bottom: $border;
    }
}
