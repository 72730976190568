@import '../../style/styles.scss';

.bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% + 60px);
    pointer-events: none;
    z-index: -1;
    background-color: var(--pampas);
    background-size: cover;
}

.FavouriteQuestionListPageContainer {
    height: 100% !important;
    // padding-top: env(safe-area-inset-top);
    background-color: var(--pampas);
    position: relative;
    width: 100%;
    display: flex !important;
    margin-bottom: 34px;
    align-self: center;
    position: absolute;
    -webkit-flex-direction: column;
    flex-direction: column;
}

.FavouriteQuestionsListPage {
    background-color: var(--pampas);
    height: 100%;
    padding-bottom: 20px;
    // padding-top: 40px;

    @media (max-width: $tablet) {
        align-content: flex-start;
        height: auto !important;
        max-height: none !important;
        padding-left: 16px;
        padding-right: 16px;
        width: 100%;
    }
    @media (min-width: $tablet) {
        padding-top: 80px;
        display: flex;
        flex-direction: column;
    }
}

.headerContainer {  
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    @media (min-width: $tablet) {
        align-self: flex-start;
        margin-left: 40px;
    }  
    @media (min-width: 1400px) {
        align-self: center;
        width: 1352px;
    }
     display: flex;
   
}

.BackButtonContainer {
    background: rgba(247, 243, 241, 0.9);
    // margin: 0 auto;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 100;   
    align-self: flex-start;
    display: flex;
    @media (min-width: $tablet) {
        align-self: flex-start;
        margin-left: 40px;
    }  
    @media (min-width: 1400px) {
        align-self: center;
        width: 1352px;
    }
}

.backButton{
    background: none!important;
    border: none;
    color: #2b4a64;
    cursor: pointer;
    font-family: Source Sans Pro,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    font-size: 16px;
    margin-top: calc(env(safe-area-inset-top) + 40px);
    padding: 0px 16px 8px 15px;
    text-decoration: none;
    @media (min-width: $tablet) {
        // margin-bottom: 8px;
        margin-left: -16px;
        padding: 8px 16px 8px 16px;
    }
    @media (min-width: $desktop) {
        padding: 10px 15px;
        margin-left: -15px;
        &:focus,
        &:hover {
            background:rgba(50, 77, 103, 0.05) !important;
            border-radius: 60px !important;
        }
    }
}
.backButtoniOS{
    margin-top: env(safe-area-inset-top);
}

// .backButton {
//     padding: 8px 15px 8px 15px;
//     align-self: start;
//     @media (min-width: $tablet) {
//         margin-left: -15px;
//     }
// }

.title {
    font-size: $fontSize;
    font-weight: 700;
    margin-left: 15px;
    align-self: start;
    font-size: 24px;
    @media (min-width: $tablet) {
        margin-left: 0px;
    }
}

.redHeartIcon {
    align-items: center;
    background-image: url('../../images/icons/red-heart.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 90%;
    display: flex;
    flex-direction: column;
    height: 1.5rem;
    justify-content: center;
    margin-left: 40px;
    width: 26px;
}
.bright {
    background-color: $white;
}

.FavouriteQuestionsContainer {
    background-color: var(--pampas);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.isiOS {
    padding-top: 0px;
}

.FavouriteQuestionsInnerContainer {
    width: 100%;
    margin-bottom: 20px;
    padding-top: 40px;
    @media (min-width: $tablet) {
        padding-left: 40px;
        padding-right: 40px;
        max-width: 1400px;
        padding-bottom: 40px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
    }
}


.ToastContainer{
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
    position: absolute;
    
    z-index: 120;
    width: 100%;
    @media (min-width: $desktop) {        
        align-items: center;
    }
 
}

.RemovedQuestionToast{
    display: flex;
    position: -webkit-sticky;
    position: sticky;    
    bottom: 16px;
    margin: 16px 16px 16px 16px;
    margin-bottom: calc(env(safe-area-inset-bottom) + 16px);
    
    padding-right: 32px;
    border-radius: 4px;
    align-items: center;
    background-color: var(--sanjuan);
    width: unset;
    opacity: 1;
    -webkit-animation: cssAnimation 3s forwards; 
    animation: cssAnimation 3s forwards;
    

    @media (min-width: $desktop) {
        height: 40px;
        border-radius: 40px;
        margin-bottom: 32px;
    }
}

.Hidden{
    opacity: 0!important;
    animation: none!important;
}

