.inputAdornment {
    button {
        font-size: 14px;
        text-transform: none;
    }
}

.endAdornment {
    :global .MuiIconButton-root {
        margin-right: -6px;
    }

    :global .MuiButton-root {
        margin-right: -10px;
    }
}