// The purpose of this file is to redeclare style tokens at the root level for pro solution.
// It will leverate css custom properties aka css variables for all units and tokens
// So that they can be accessed by JS
:root {
    // Colours
    --wafer: #e3d4ce;
    --pampas: #f7f3f1;
    --alabaster: #f9f9f9;
    --froly: #f48e75;
    --rosebud: #f8bbac;
    --bridesmaid: #fdeae6;
    --sanjuan: #2b4a64;
    --sanjuan-dark: #2b4a640d;
    --bluebayoux: #556e83;
    --geyser: #d4dbe0;
    --porcelain: #f4f6f7;
    --tuna: #2F2F37;
    --jumbo: #717179;
    --white: #ffffff;
    --white-98: #f9f9f9;
    --surfcrest: #d1e4d0;
    --mystic: #dee7ec;
    --link-water: #cbcfd3;
    --amour: #f0e6ea;
    --white-smoke: #efefef;
    --bridal-heath: #f7ede2;
    --grey-med: #cbcbd3;
    --brown-85: #e4d5cd;
    --brown-95: #F6F1EE;
    --sustainability: #d4d9d8;

    // Size
    --tiny: 10px;
    --small: 12px;
    --normal: 14px;
    --medium: 16px;
    --big: 18px;
    --xl: 40px;
    --half-gutter: 8px;
    --gutter: 16px;
    --large-gutter: 30px;
    --double-gutter: 32px;
    --xl-gutter: 40px;
    --triple-gutter: 48px;
    --tablet: 768px;
    --desktop: 1048px;
    --organization-card-width: 150px;
    --card-width: 150px;
    --card-width-tablet: 150px;
    --card-width-desktop: 150px;
    --card-height: 140px;
    --pagination-height: 32px;
    --nav-button-width: 48px;
    //Font Sizes
    --weight-regular: 400;
    --weight-bold: 700;
    --weight-extra-bold: 800;
    //Gradients
    --bg-gradient-mobile: linear-gradient(
        180deg,
        var(--white) 0%,
        #e3d4ce 100%
    );
    --bg-gradient-desktop: linear-gradient(
        180deg,
        var(--white-98) 0%,
        #e3d4ce 100%
    );
}
