@import '../../style/styles.scss';

$bgGradient: linear-gradient(180deg, #e3d4ce 0%, $white 100%);
$sectionSpacer: 80px;
$smallerSectionSpacer: 56px;

.landingPage {
    .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        pointer-events: none;
        background-image: url('./images/bg-mobile-landing.svg'), $bgGradient;
        background-size: cover;

        @media (min-width: $desktop) {
            background-image: url('./images/bg-desktop-landing.svg');
        }
    }

    .welcomeSection {
        position: relative;
        padding-top: $sectionSpacer;
        padding-bottom: $smallerSectionSpacer;
        text-align: center;

        @media (min-width: $desktop) {
            padding-top: $sectionSpacer * 1.5;
            padding-bottom: $smallerSectionSpacer * 1.5;
        }

        .welcomeSectionContainer {
            max-width: 650px;
            padding-left: $gutter;
            padding-right: $gutter;
        }

        .title {
            margin-bottom: $gutter;

            @media (min-width: $desktop) {
                margin-bottom: $gutter * 1.5;
            }

            h1 {
                font-size: 32px;
                font-weight: 600;
            }

            @media (max-width: $tablet) {
                h1 {
                    font-size: 24px;
                }
            }
        }

        .text {
            max-width: 500px;
            line-height: 1.5;
            padding-left: $gutter * 2;
            padding-right: $gutter * 2;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: $gutter * 2.5;

            @media (min-width: $desktop) {
                padding: 0;
            }
        }

        .buttonGroup{
            display: flex;
            flex: row;
            margin-left: auto; 
            margin-right: auto;

            button:first-of-type {
                margin-right: 32px;
            }           
            @media (max-width: $tablet) {
                flex-direction: column;
                align-items: center;
                button{
                    width: fit-content;                    
                }
                button:first-of-type{
                    margin-right: 0px;
                }
                button:last-of-type {
                    margin-top: 24px;                    
                }
            }

        }

        .proCodeButtonContainer {
            display: flex;
            align-items: center;
            flex-direction: column;
        }

        .proCodeButton {
            color: #2b4a64;
            font-family: $fontFamily;
            font-size: 16px;
            background: none !important;
            border: none;
            cursor: pointer;
            padding-top: 40px !important;
            text-decoration: underline;
            flex-wrap: nowrap;
            display: flex;
        }
    }

    .freeDecksSection {
        padding-bottom: $sectionSpacer;
    }
}
