@import '../../style/styles.scss';
@import './variables.scss';

$width: $cardWidth;
$widthTablet: $cardWidthTablet;
$widthDesktop: $cardWidthDesktop;

$height: $width;
$heightTablet: $widthTablet;
$heightDesktop: $widthDesktop;

$background: $primaryColor;

.themeCard {
    align-items: center;
    background: $background;
    border-radius: 50%;
    border: none;
    color: $white;
    cursor: pointer;
    display: flex;
    font-weight: 700;
    justify-content: center;
    padding: 20px;
    text-align: center;
    font-size: inherit;

    width: 140px !important;
    height: 140px !important;
    margin-right: 5px;
    margin-left: 5px;

    @media (min-width: $tablet) {
        height: $heightTablet;
        width: $widthTablet;
    }
    @media (min-width: desktop) {
        height: $heightDesktop;
        width: $widthDesktop;
    }
}
