@import '../../../style/styles.scss';

.text {
  margin-top: $gutter;
  @media (min-width: $tablet) {
    text-align: center;
  }
  &:first-of-type {
    margin-top: 0;
  }
}

.cta {
  margin: $gutter * 2.5 0;
}

.iapStyling {
  @media (max-width: $tablet) {
    min-height: 56px;
  }
}

.IAPHeader {
  margin-top: $gutter;
  @media (min-width: $tablet) {
    text-align: center;
  }
  color: red;
  font-weight: bold;
}
.IAPText1 {
  @media (min-width: $tablet) {
    text-align: center;
  }
  color: red;
}
.IAPText2 {
  color: red;
}
.IAPText3 {
  color: red;
}
.IAPMobileText {
  color: red;
}
