@import '../../../../style/styles.scss';

.closeButton {
    left: 4px;
    padding: 8px;
    position: absolute !important;
    top: calc(env(safe-area-inset-top) + 4px);
    z-index: 20;
    
    @media (min-width: $tablet) {
        top: 8px;
        left: 8px;
    }
}