@import '../../../style/styles.scss';

.PaymentOptionSelect {
    --color-form-label: #1b1c1d;
    --color-form-input-bg: #ffffff;
    --color-form-input-border: #dee7ec;
    --color-form-input-border-error: #f48e75;
    --color-form-input-border-active: #2b4a64;
    --color-form-hint: #2F2F37;
    --color-form-error: #c43f17;
    --color-link: #2b4a64;
    --color-link-bg: #eef5f5;
    --color-summary-border: #dee7ec;
    --radius-form-input: 8px;
    --radius-border: 60px;
    --radius-mobile-border: 32px 32px 0 0;
}

.PaymentOptionSelect {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;

    .links {
        display: flex;
        justify-content: center;
        white-space: nowrap;
        flex-wrap: wrap;
        position: absolute;
        bottom: calc(env(safe-area-inset-bottom) + 36px);
        align-self: center;

        .link {
            text-align: center;
            margin-top: $gutter;
            padding: 0 16px;

            @media (min-width: $tablet) {
                flex-basis: auto;
                margin-right: $gutter * 2;

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
    }

    h1 {
        text-align: center;
        font-size: 2rem;
        line-height: 2rem;
        font-weight: 600;
        margin-bottom: 40px;
    }

    a {
        text-decoration: underline;
        &:hover,
        &:focus,
        &:active,
        &:visited {
            color: var(--color-link);
        }
    }

    .ButtonLink {
        cursor: pointer;
        border: none;
        background-color: transparent;
        color: var(--color-link);
        text-decoration: underline;
        margin: 0;
        padding: 0;
        font-size: 16px;
    }
}

.PayWithBamboraButton {
    font-weight: var(--weightMedium) !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-width: 350px;
    border: 2px solid var(--color-mystic);
    border-radius: 16px;
    margin-bottom: 16px;
    padding: 20px;
    align-items: center;
    cursor: pointer;
    &:hover {
        background: #2b4a640d;
        @include solidBorder(var(--color-default), 16px, 4);
    }

    @media (max-width: $tablet) {
        padding: 10px 20px 10px 20px;
        min-width: 311px;
        width: 311px;
    }
}
.PayWithBamboraButtonLabel {
    width: 100%;
}
.CardOptions {
    width: 170px;
    display: flex;
    flex-direction: row;
}
.CreditCardIcon {
    padding-left: 2px;
    min-width: 40px;
    max-height: 50px;
}

.PlanSummary {
    flex: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--color-summary-border);
    width: 420px;

    .PlanSummary__Content {
        & > span::before,
        span::after {
            content: '\00a0\00a0\00a0';
        }
    }
}

.Options {
    margin: 16px;
}

.Options__Item {
    opacity: 1;
}

.PaymentCard {
    width: inherit;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.125rem;
    font-weight: bold;

    .Icons {
        flex: 1;
        text-align: right;
        & > * {
            margin: 0 2px;
        }
    }
}

.CancelProcess {
    width: 100%;
    flex: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    // margin-bottom: $gutter;
    // padding-top: 16px !important;
}

.ChangePaymentOption {
    width: 100%;
    margin-top: 18px;
    padding: 0 25px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    button {
        outline: none;
        border: none;
        padding: 10px;
        background-color: transparent;
        span {
            margin-left: 8px;
            font-size: 0.875rem;
        }
        &:hover,
        &:focus {
            background: var(--color-link-bg);
            border-radius: var(--radius-border);
        }
    }
}

.Content {
    width: 100%;
    flex-grow: 1;
    display: flex;
    // margin-bottom: 32px;
    padding-bottom: 32px;
    flex-direction: column;
}

.CCForm {
    padding: 32px 16px 16px 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    align-items: center;

    & > :not(:first-child) {
        margin-top: 16px;
    }
}

.Form__Bambora {
    width: 100%;
    height: 400px;
    margin: 16px 0;
}

.Form__Actions {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-top: 57px;
}

.Form__Row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    input {
        min-width: 100px;
        max-width: 147px;
    }
}

// Form controls
.Form__Field {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
    position: relative;

    input {
        background: var(--color-form-input-bg) 0% 0% no-repeat padding-box;
        padding: 12px;
        margin-bottom: 2px;
    }

    & > span {
        height: 15px;
        margin-bottom: 4px;
        letter-spacing: 0;
        font-size: 0.688rem;
        opacity: 0.7;
    }
}

.Form__Label {
    text-align: left;
    letter-spacing: 0.34px;
    color: var(--color-form-label);
    font-size: 0.875rem;
    font-weight: bold;
    margin-bottom: 2px;
}

.Form__Input {
    border: 2px solid var(--color-form-input-border);
    border-radius: var(--radius-form-input);
    min-width: 311px;
    min-height: 48px;

    &:hover,
    &:active,
    &:focus {
        outline: none;
        border: 2px dashed var(--color-form-input-border-active);
    }
}

.Form__Input__Error {
    border: 2px solid var(--color-form-input-border-error);
    border-radius: var(--radius-form-input);
    min-width: 311px;
    min-height: 48px;

    &:hover,
    &:active,
    &:focus {
        outline: none;
        border: 2px dashed var(--color-form-input-border-error);
    }
}

.Form__Placeholder {
    color: var(--color-form-hint);
}

// Error styles
.Form__Error__Show {
    color: var(--color-form-error);
}

.Form__Error__Hidden {
    visibility: hidden;
}

@media (max-width: $tablet) {
    .Content {
        z-index: 100;
        background-color: var(--color-form-input-bg);
        border-radius: 40px 40px 0 0;
    }

    .Steps {
        display: none;
    }

    .PlanSummary {
        border: 0;
    }

    .Options {
        padding: 32px;
        margin: 12px 0 0 0;
        background-color: var(--color-form-input-bg);
        text-align: center;
        border-radius: var(--radius-mobile-border);
    }

    .ChangePaymentOption {
        background-color: var(--color-form-input-bg);
        padding: 0 39px;
        margin: 0;
    }

    .CCForm {
        padding: 32px 0px 0px 0px;
    }
}
