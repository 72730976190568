@import '../../../style/styles.scss';

$placeholderDark: $primaryColor;
$placeholderLight: #dee7ec;

.featuredDeck {
    background: none;
    border: none;
    height: auto;
    padding: 0;
    width: 100%;
    cursor: pointer;

    &:focus-visible {
        outline: none;

        .imageContainer img {
            outline: 2px solid;
            outline-offset: -2px;
        }
    }

    .imageContainer {
        picture {
            display: block;
            position: relative;
            height: 0;
            width: 100%;
            border-radius: 40px;
            background: $placeholderLight;

            &.dark {
                background: $placeholderDark;
            }

            // mobile image format - 343x417
            padding-top: 121.5743440233236%;

            // desktop image format - 888x340
            @media (min-width: $tablet) {
                padding-top: 38.288288288288286%;
            }

            @media (min-width: $desktop) {
                width: 980px;
                height: 340px;
                padding-top: 0;
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }

        .overlayText {
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            padding-top: 56px;
            text-align: center;
            max-width: 210px;
            white-space: normal;

            @media (min-width: $tablet) {
                right: unset;
                text-align: left;
                margin-left: $gutter * 4;
                max-width: 270px;
            }

            @media (min-width: $desktop) {
                margin-left: $gutter * 5;
                padding-top: $gutter * 5;
                max-width: 500px;
            }

            .title {
                margin-bottom: $gutter * 1.5;
                font-weight: 600;

                @media (min-width: $tablet) {
                    font-weight: $weightMedium;
                    font-size: 2rem;
                    line-height: 1.25;
                    margin-bottom: $gutter * 2;
                }

                @media (min-width: $desktop) {
                    margin-bottom: 4px;
                }
            }

            .description {
                display: none;
                margin-bottom: 40px;
                font-weight: 400;
                font-size: 1.125rem;
                line-height: 1.25;

                @media (min-width: $tablet) {
                    display: block;
                }
            }
        }
    }
}
