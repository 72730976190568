@import '../../style/styles.scss';

.container {
    h3,
    h4 {
        margin-top: 2.5rem;
        margin-bottom: 1rem;
        line-height: 1.25;
    }

    p {
        line-height: 1.5rem;
        margin-bottom: 1rem;
    }

    ul {
        list-style-type: disc;
        margin: 0;
        padding-left: 1rem;
    }

    li {
        line-height: 1.5rem;
    }
}

@media (min-width: $tablet) {
    .container {
        h1,
        h2,
        h3,
        h4 {
            font-weight: bold;
        }
    }
}