@import '../../../style/styles.scss';

.text {
    margin-top: 0;
    margin-bottom: $gutter * 1.5;
    font-size: 16px;
    line-height: 24px;
    @media (min-width: $desktop) {    
        font-size: 18px;
        line-height: 26px;   
        font-weight: 300;     
        text-align: center;
        max-width: 480px;
    }
}
.iOSSteps{
    // font-weight: bold;
    text-align: center;
    font-weight: 400;
    font-size: 18px;
}

.ConfirmButton {
    margin-top: $gutter;
    margin-bottom: $gutter * 2.5;    
    align-self: center;
}

.iOSSubscriptionsButton{
    margin-top: $gutter ;
}

.PointerCursor{
    cursor: pointer;
}

.infoBox{
    margin-bottom: $gutter * 1.5;
}
