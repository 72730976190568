@import '../../../style/styles.scss';
@import '../../../components/conversation-card/variables.scss';
@import './variables.scss';

$borderColor: #dee7ec;
$navigationWidth: $navButtonWidth + $gutter;
.container {
    @media (min-width: $tablet) {
        width: $cardWidthTablet * 4 + $navButtonWidth * 2 + $gutter * 7 !important;
    }
    @media (min-width: $desktop) {
        width: $cardWidthDesktop * 6 + $navButtonWidth * 2 + $gutter * 9 !important;
    }
}

.header {
    padding-top: 0px;
    padding-left: calc($gutter/2);
    padding-right: calc($gutter/2);
    padding-bottom: calc($gutter/2);
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (min-width: $tablet) {
        padding-left: $gutter * 3;
        padding-right: $gutter * 3;
    }

    @media (min-width: $desktop) {
        padding-left: $gutter * 5;
        padding-right: $gutter * 5;
    }

    .title {
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
    }
}

.showAllSlide {
    align-items: center;
    background: none !important;
    border: 1px solid $borderColor;
    display: flex;
    flex-direction: row !important;
    font-weight: normal;
    justify-content: center !important;
    text-decoration: none;

    svg {
        margin-left: 8px;
    }
    
}
.showAllButton {
    background: none !important;
    border: none;
    padding: 0 !important;
    font-family: $fontFamily;
    text-decoration: underline;
    color: #2b4a64;
    font-size: 16px;
    cursor: pointer;
    font-weight: normal;
    text-decoration: none;
    @media (min-width: $tablet) {
        text-decoration: underline;
    }

    svg {
        fill: $primaryColor;
        margin-left: 8px;
    }
}
