.checkboxStyle {
    --checkbox-color: var(--color-mystic);
    --checkbox-hover-color: var(--hoverBg);
    --checkbox-disabled-color: var(--color-alabaster);
    --checkbox-size: 24px;
    --checkbox-margin-right: 35px;

    --checkbox-border-radius: 4px;
    --checkbox-border-width: 2px;
    --checkbox-border-style: solid;

    --checkmark-color: var(--primaryColor);
    --checkmark-size: 0.6;
    --checkmark-width: 3px;

    --font-family: var(--fontFamily);
    --font-size: var(--fontSizeSm);
    --font-color: var(--textColorGray);
}

.checkboxStyle {
    display: flex;
    text-align: left;

    font-size: var(--font-size);
    font-family: var(--font-family);
    color: var(--font-color);
}

.checkbox {
    display: block;
    position: relative;
    margin-right: var(--checkbox-margin-right);
}

// Hide the browser's default checkbox
.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    width: var(--checkbox-size);
    height: var(--checkbox-size);

    border-color: var(--checkbox-color);
    border-style: var(--checkbox-border-style);

    border-style: var(--checkbox-border-style);
    border-width: var(--checkbox-border-width);
    border-radius: var(--checkbox-border-radius);

    cursor: pointer;
    user-select: none;
}

.checkbox:hover input ~ .checkmark {
    background-color: var(--checkbox-hover-color);
}

.checkbox input:disabled ~ .checkmark {
    background-color: var(--checkbox-disabled-color);
}

.checkbox input:checked ~ .checkmark {
    background-color: var(--checkbox-color);
}

.checkmark:after {
    content: '';
    position: absolute;
    display: none;
}

.checkbox input:checked ~ .checkmark:after {
    display: block;
}

.checkbox .checkmark:after {
    --width-factor: 60%;
    --height-factor: 100%;
    --width: calc(var(--width-factor) * var(--checkmark-size));
    --height: calc(var(--height-factor) * var(--checkmark-size));

    // Offset height by sin(45) - the line is rotated to 45 degrees.
    top: calc(50% - var(--height) / (2 * 0.707));
    left: calc(50% - var(--width) / 2);

    width: var(--width);
    height: var(--height);
    border: solid var(--checkmark-color);
    border-width: 0 var(--checkmark-width) var(--checkmark-width) 0;
    transform: rotate(45deg);
}
